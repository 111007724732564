import React, { useState } from "react";
import { ProductModel } from "../../../Models/Product";
import { useSelector } from "react-redux";
import { selectProductsChoices } from "../../../Store/Slices/Products/Products";
import Parameter from "./Parameter/Parameter";

type Props = {
  product: ProductModel;
};

function ProductParameters({ product }: Props) {
  const productParameters = useSelector(selectProductsChoices);

  const [parameterId, setParameterId] = useState<number | null>(null);

  return (
    <div className="relative z-30 space-y-2">
      <h1 className="text-xl lg:text-2xl font-bold">
        Produkty z serii o innych parametrach:
      </h1>
      {productParameters &&
        productParameters.map((element) => (
          <Parameter
            element={element}
            product={product}
            parameterId={parameterId}
            setParameterId={setParameterId}
          />
        ))}
    </div>
  );
}

export default ProductParameters;
