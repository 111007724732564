import React from "react";
import ImageSlider from "./Product/Gallery/ImageSlider/ImageSlider";
import Gallery from "./Product/Gallery/Gallery";

export default function Kontener() {
  const images = [
    {
      Image_url:
        "https://pressbarimages.b-cdn.net/artykuly/kontener/1000008272.png",
    },
    {
      Image_url:
        "https://pressbarimages.b-cdn.net/artykuly/kontener/20231116_104555.png",
    },
    {
      Image_url:
        "https://pressbarimages.b-cdn.net/artykuly/kontener/20240130_103750(1).png",
    },
    {
      Image_url:
        "https://pressbarimages.b-cdn.net/artykuly/kontener/20240130_103923.png",
    },
    {
      Image_url:
        "https://pressbarimages.b-cdn.net/artykuly/kontener/20240130_104000.png",
    },
    {
      Image_url:
        "https://pressbarimages.b-cdn.net/artykuly/kontener/20240130_104010.png",
    },
    {
      Image_url:
        "https://pressbarimages.b-cdn.net/artykuly/kontener/20240130_104023.png",
    },
    {
      Image_url:
        "https://pressbarimages.b-cdn.net/artykuly/kontener/20240130_104050.png",
    },
  ];
  return (
    <div>
      <h1>Kontenerowe stacje sprężonego powietrza i azotu</h1>
      <p>
        Kontenerowe stacje sprężarkowe znajdują zastosowanie wszędzie tam, gdzie
        niezbędne jest źródło sprężonego powietrza lub azotu, a jednocześnie nie
        ma możliwości budowy pomieszczenia sprężarkowni w budynku
        przedsiębiorstwa. Umożliwiają one dużą mobilność i wykorzystanie układu
        pneumatycznego w dowolnym miejscu zapewniając jednocześnie optymalne
        warunki pracy i ochronę znajdujących się wewnątrz urządzeń przed
        czynnikami zewnętrznymi.
      </p>
      <div className="w-full flex flex-col xl:flex-row items-start space-y-2 xl:space-y-0 xl:space-x-2">
        <div className="xl:sticky top-32 w-full flex justify-center items-center h-64 sm:h-72 xl:h-80 2xl:h-96 xl:w-1/2">
          <Gallery images={images} />
        </div>
      </div>
      <p>
        Całe wyposażenie stacji konfigurowane jest w oparciu o indywidualne
        potrzeby klienta. Wielkość kontenera uzależniona jest od typu i ilości
        zainstalowanych wewnątrz urządzeń takich jak: sprężarki, wytwornice
        azotu, doprężacze, zbiorniki, separatory, filtry i osuszacze sprężonego
        powietrza.
      </p>

      <p>
        Przedstaw nam swoje plany inwestycyjne w zakresie systemu sprężonego
        powietrza czy innych gazów, a my pomożemy je zrealizować przy zachowaniu
        optymalnego połączenia najwyższej jakości wykonania i konkurencyjnej
        ceny.
      </p>
    </div>
  );
}
