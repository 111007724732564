import React, { useState } from "react";
import { ImagTemp } from "../../../Models/Product";
import { AnimatePresence, motion } from "framer-motion";
import ImageSlider from "./ImageSlider/ImageSlider";
import Backdrop from "../../../Common/Backdrop/Backdrop";
import ReactDOM from "react-dom";

type Props = {
  images: ImagTemp[];
  customClasses?: string;
};

function Gallery({ images, customClasses }: Props) {
  const [fullScreen, setFullScreen] = useState(false);

  return (
    <div
      className={
        `group flex justify-center h-full w-full overflow-hidden bg-gray-100 relative transition-all ` +
        customClasses
      }
    >
      <ImageSlider images={images} setFullScreen={setFullScreen} />
      {ReactDOM.createPortal(
        <AnimatePresence mode="wait">
          {fullScreen && (
            <Backdrop onClick={() => setFullScreen(false)}>
              <motion.button
                className="absolute top-8 left-8 flex items-center justify-center rounded-full h-14 w-14 bg-neutral-100 shadow-md"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                onClick={() => setFullScreen(false)}
              >
                <span className="material-symbols-outlined text-3xl">
                  close
                </span>
              </motion.button>
              <ImageSlider
                images={images}
                setFullScreen={setFullScreen}
                inFullScreen={true}
              />
            </Backdrop>
          )}
        </AnimatePresence>,
        document.body
      )}
    </div>
  );
}

export default Gallery;
