import { motion } from "framer-motion";
import React, { useEffect, useState } from 'react'
import SearchResults from "../../../Search/SearchResults/SearchResults";
import SearchOptionsBar from "../../../Search/SearchOptionsBar/SearchOptionsBar";
import WishlistControls from "../../../Wishlist/WishlistControls/WishlistControls";
import WishlistInfo from "../../../Wishlist/WishlistInfo/WishlistInfo";
import { UserIdRoleAccepted } from "../../../../Models/User";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import { selectIsLoggedIn, selectUser } from "../../../../Store/Slices/User/User";
import { changeProductDiscount, changeProductQuantity, changeProductSpecialPrice, getLists, getProductsToList, removeProductFromList, selectListId, selectListName, selectLists, selectProducts } from "../../../../Store/Slices/wishlist/wishlistSlice";
import ButtonPrimaryToLighter from "../../../../Common/Buttons/ButtonPrimaryToLighter";
import ProductList from "../Products/ProductList";
import { addNewExtraProductsWithImage, getUsers, selectChosenUser, selectClientAddress, selectClientCity, selectClientDataWaznosci, selectClientDataZlecenia, selectClientNIP, selectClientName, selectClientPlatnosc, selectClientTworca, selectClientZamawiajacy, selectExtraProducts, selectOfferState, selectSearchUsers, setChosenUser, setClientAddress, setClientCity, setClientNIP, setClientName, setClientZamawiajacy, setDataWaznosci, setDataZlecenia, setPlatnosc, setTworca } from "../../../../Store/Slices/admin/Offers/Offers";

export default function Offers() {

    const products = useSelector(selectProducts);
    const lists = useSelector(selectLists);
    const selectedList = useSelector(selectListId);
    const selectedName = useSelector(selectListName);
    const isLoggedIn = useSelector(selectIsLoggedIn);
    const dispatch = useDispatch<AppDispatch>();
    const user: UserIdRoleAccepted | null = useSelector(selectUser);
    const [userNameToSearch, setUserNameToSearch] = useState('');
    const searchUsers = useSelector(selectSearchUsers);
    const chosenUser = useSelector(selectChosenUser);
    const stateUsers = useSelector(selectOfferState);

    const [productName, setProductName] = useState('');
    const [productCatalogName, setProductCatalogName] = useState('');
    const [productQuantity, setProductQuantity] = useState(0);
    const [productPrice, setProductPrice] = useState(0);
    const [productDiscount, setProductDiscount] = useState(0);

    const [addNewExtraProduct, setAddNewExtraProduct] = useState(false);

    const extraProducts = useSelector(selectExtraProducts);

    const clientName= useSelector(selectClientName);
    const clientNIP= useSelector(selectClientNIP);
    const clientAddress= useSelector(selectClientAddress);
    const clientCity= useSelector(selectClientCity);
    const clientZamawiajacy= useSelector(selectClientZamawiajacy);
    const dataZlecenia= useSelector(selectClientDataZlecenia);
    const dataWaznosci= useSelector(selectClientDataWaznosci);
    const platnosc = useSelector(selectClientPlatnosc);
    const tworca= useSelector(selectClientTworca);

    useEffect(()=>{
        dispatch(getLists());
    }, [isLoggedIn])

    useEffect(()=>{
        if(selectedList!=-1){
        dispatch(getProductsToList(selectedList));
        }
    }, [selectedList])

    const [file, setFile] = useState<File | null>(null);


/*  const handleUpload = async () => {
    if (file) {
      const formData = new FormData();
      formData.append('file', file);

      const response = await fetch('http://localhost:7010/api/upload', {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      console.log(data);
    }
  };*/

  return (
    <motion.div
      className="w-full flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
        <div className="flex flex-col xl:flex-row space-y-4 xl:space-x-4 xl:space-y-0 w-full max-w-[1536px]">
        <div className="flex flex-col w-full xl:w-3/4 min-h-96 items-center bg-neutral-100 rounded-lg shadow-md p-2 space-y-2">
          <WishlistInfo name={selectedName}/>
          <div className="h-96 overflow-scroll xl:h-fit xl:max-h-full space-y-2 w-full">
            {products.length === 0 && (
              <div className="flex flex-col items-center justify-center w-full h-full space-y-4">
                <span className="text-9xl text-neutral-600 material-symbols-outlined">
                  heart_broken
                </span>
                <h1 className="text-4xl font-bold text-center">
                  Ta lista jest pusta
                </h1>
                <ButtonPrimaryToLighter
                  link={""}
                  text="Strona główna"
                  size="large"
                  icon={"home"}
                />
              </div>
            )}
            {products.map((product) => (
              <p>
                <ProductList key={product.Id} product={product} productQuantity={product.Quantity} showCartControls={true} />
                <input placeholder="rabat" value={(product.Discount??0.0).toFixed(2)} onChange={(e)=>{
                  dispatch(changeProductDiscount({id: product.Id, discount: parseFloat(e.target.value)}));
                }}/>
                <input placeholder="cena" value={(product.SpecialPrice??product.Price).toFixed(2)} 
                onChange={(e)=>{
                  dispatch(changeProductSpecialPrice({id: product.Id, price: parseFloat(e.target.value)}))
                }}/>
                <input placeholder="Ilość" value={(product.Quantity??0).toFixed(0)} 
                onChange={(e)=>{
                  dispatch(changeProductQuantity({id: product.Id, quantity: parseFloat(e.target.value)}))
                }}/>
                <button onClick={()=>{
                  dispatch(removeProductFromList({productId: product.Id, listId: selectedList}))
                }}>Usun z listy</button>
              </p>
            ))}
            {user?.Role=='admin'&&<div>
                <p><input placeholder="nazwa klienta" value={userNameToSearch} onChange={(e)=>{
                    setUserNameToSearch(e.target.value);
                }}/></p>
                <p><button onClick={()=>{
                    dispatch(getUsers({name: userNameToSearch}))
                }}>Szukaj</button></p>
                <p>{searchUsers&&searchUsers.map(element=>{
                    return(
                        <p>
                            <button style={{background: (element.Id==chosenUser?'red':'')}} onClick={()=>{
                                dispatch(setChosenUser(element.Id));
                                dispatch(setClientName(element.Name));
                            }}>{element.Name}</button>
                        </p>
                    );
                })}</p>
                <p><button>Podaj dane innego klienta</button></p>
                <div>
                  <p><input placeholder="Nazwa" value={clientName} onChange={(e)=>{
                    dispatch(setClientName(e.target.value));
                  }}/></p>
                  <p><input placeholder="NIP" value={clientNIP} onChange={(e)=>{
                    dispatch(setClientNIP(e.target.value));
                  }}/></p>
                  <p><input placeholder="Ulica i numer" value={clientAddress} onChange={(e)=>{
                    dispatch(setClientAddress(e.target.value));
                  }}/></p>
                  <p><input placeholder="Kod i miasto" value={clientCity} onChange={(e)=>{
                    dispatch(setClientCity(e.target.value));
                  }}/></p>
                </div>
                <br/>
                <p><input placeholder="Osoba zamawiająca" value={clientZamawiajacy} onChange={(e)=>{
                    dispatch(setClientZamawiajacy(e.target.value));
                }}/></p>
                <p><input placeholder="Termin realizacji" type="date" value={dataZlecenia} onChange={(e)=>{
                    dispatch(setDataZlecenia(e.target.value));
                }}/></p>
                <p><input placeholder="Termin wazności" type="date" value={dataWaznosci} onChange={(e)=>{
                    dispatch(setDataWaznosci(e.target.value));
                }}/></p>
                <p><input placeholder="Forma płatności" value={platnosc} onChange={(e)=>{
                    dispatch(setPlatnosc(e.target.value));
                }}/></p>
                <p><input placeholder="Twórca" value={tworca} onChange={(e)=>{
                    dispatch(setTworca(e.target.value));
                }}/></p>

                <p><button onClick={()=>{
                  setAddNewExtraProduct(true);
                }}>Dodaj inny produkt</button></p>

                {addNewExtraProduct&&<p>
                  <input type="file" onChange={(event) => {
                    if (event.target.files && event.target.files.length > 0) {
                      setFile(event.target.files[0]);
                    }
                  }} />
                  <input placeholder="Nazwa" value={productName} onChange={(e)=>{
                    setProductName(e.target.value);
                  }}/>
                  <input placeholder="Numer katalogowy" value={productCatalogName} onChange={(e)=>{
                    setProductCatalogName(e.target.value);
                  }}/>
                  <input placeholder="Ilość" value={productQuantity} onChange={(e)=>{
                    setProductQuantity(parseFloat(e.target.value));
                  }}/>
                  <input placeholder="Rabat" value={productDiscount} onChange={(e)=>{
                    setProductDiscount(parseFloat(e.target.value));
                  }}/>
                  <input placeholder="Cena" value={productPrice} onChange={(e)=>{
                    setProductPrice(parseFloat(e.target.value));
                  }}/>
                  <p><button onClick={()=>{
                    if(file){
                      dispatch(addNewExtraProductsWithImage({name: productName, catalogName: productCatalogName, 
                        quantity: productQuantity, price: productPrice, discount: productDiscount, image: file}))
                    }
                  }}>Zatwierdź</button></p>
                </p>}
                <p>{extraProducts&&extraProducts.map(element=>{
                  return(
                    <p>
                      <p>{element.Name}</p>
                      <p>{element.Catalog_name}</p>
                    </p>
                  )
                })}</p>
                
              </div>}
          </div>
        </div>
        <WishlistControls lists={lists}/>
      </div>
    </motion.div>
  )
}
