import { motion } from "framer-motion";
import React from "react";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../../Store/Slices/User/User";
import Input from "../../../../Common/Input/Input";

function NotifyWhenAvailable() {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <div className="flex flex-col space-y-2 w-full rounded-lg border-2 border-primary border-spacing-2 border-dashed p-2">
      {!isLoggedIn && (
        <form>
          <Input
            label="Email"
            type="email"
            name=""
            placeholder="your@email.com"
            size="sm"
          />
        </form>
      )}
      <div className="w-full h-full flex items-center justify-center space-x-2">
        <span className="text-base">
          Zostaw email, skontaktujemy sie z tobą lub wyślij wiadomość na <a href="mailto:biuro@pressbar.pl" 
          style={{fontWeight: 'bold'}}>biuro@pressbar.pl</a>
        </span>
        <motion.button
          className="flex items-center justify-center p-2 shadow-md rounded-lg bg-neutral-50"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <span className="text-base font-bold text-neutral-700">Powiadom</span>
          <span className="material-symbols-outlined text-2xl text-primary">
            notifications
          </span>
        </motion.button>
      </div>
    </div>
  );
}

export default NotifyWhenAvailable;
