import React from "react";

type Props = {
  icon: string;
  message: string;
  size?: "small" | "medium" | "large";
};

function InfoMessage({ icon, message, size = "medium" }: Props) {
  return (
    <div className="h-full w-full flex flex-col justify-center items-center space-y-2 p-2">
      <span
        className={`${size === "small" ? "text-5xl" : ""} ${
          size === "medium" ? "text-7xl" : ""
        } ${size === "large" ? "text-9xl" : ""} 
        } material-symbols-outlined`}
      >
        {icon}
      </span>
      <h1
        className={`${size === "small" ? "text-2xl" : ""} ${
          size === "medium" ? "text-3xl" : ""
        } ${size === "large" ? "text-4xl" : ""} font-bold text-neutral-800`}
      >
        {message}
      </h1>
    </div>
  );
}

export default InfoMessage;
