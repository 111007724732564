
import { motion } from "framer-motion";
import React from 'react'
import { Link, Route, Routes } from 'react-router-dom'
import CategoryDiscount from "./CategoryDiscount";
import ProductDiscount from "./ProductDiscount";
import ProductIndPrice from "./ProductIndPrice";

export default function Prices() {
  return (
    <motion.div
      className="w-full flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
        <h2>
            Ceny indywidualne
        </h2>
        <Link to="category-discount"><button>Rabaty dla klienta na kategorie</button></Link>
        <Link to="product-discount"><button>Rabaty dla klienta na produkt</button></Link>
        <Link to="product-price"><button>Cena indywidualna</button></Link>
        <Routes>
          <Route path="category-discount" element={<CategoryDiscount/>}/>
          <Route path="product-discount" element={<ProductDiscount/>}/>
          <Route path="product-price" element={<ProductIndPrice/>}/>
        </Routes>
    </motion.div>
  )
}
