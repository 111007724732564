import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../../../../Store/store";
import {
  getAdminOrderDetails,
  selectAdminOrder,
  setOrderAsAccepted,
  setOrderAsFinished,
  setOrderAsReadyToPickUp,
} from "../../../../../Store/Slices/admin/Orders/Orders";
import ProductList from "../../../../../Common/ProductList/ProductList";
import Confirm from "../../../../../Common/Modals/Confirm/Confirm";
import DatePicker from "../../../../../Common/Date/DatePicker";

function OrderDetailsPage() {
  const { id } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const order = useSelector(selectAdminOrder);

  const [showSetAsAccepted, setShowAsAccepted] = useState(false);
  const [showSetAsReady, setShowAsReady] = useState(false);
  const [showAsFinished, setShowAsFinished] = useState(false);
  const [dpdCode, setDpdCode] = useState("");
  const [sendData, setSendData] = useState(
    new Date().toISOString().substring(0, 10)
  );
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSendData(event.target.value);
  };

  useEffect(() => {
    if (id) {
      dispatch(getAdminOrderDetails(id));
    }
  }, [id]);

  return (
    <motion.div
      className="w-full flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {showSetAsAccepted && (
        <Confirm
          handleClose={() => {
            setShowAsAccepted(false);
          }}
          handleConfirm={() => {
            dispatch(
              setOrderAsAccepted({ id: id ?? "-1", sendData: sendData })
            );
            setShowAsAccepted(false);
          }}
          text={"Czy na pewno akceptujesz zamówienie?"}
        />
      )}
      {showSetAsReady && (
        <Confirm
          handleClose={() => {
            setShowAsReady(false);
          }}
          handleConfirm={() => {
            dispatch(
              setOrderAsReadyToPickUp({ id: id ?? "-1", dpdCode: dpdCode })
            );
            setShowAsReady(false);
          }}
          text={"Czy na pewno zamówienie jest spakowane/gotowe do odbioru?"}
        />
      )}
      {showAsFinished && (
        <Confirm
          handleClose={() => {
            setShowAsFinished(false);
          }}
          handleConfirm={() => {
            dispatch(setOrderAsFinished(id ?? "-1"));
            setShowAsFinished(false);
          }}
          text={"Czy na pewno zamówienie jest zakończone?"}
        />
      )}
      {order.order && (
        <div>
          <p>Cena: {order.order.Price}</p>
          <p>
            Adres: ul.{" "}
            {order.order.Address +
              ", " +
              order.order.Zip_code +
              " " +
              order.order.City}
          </p>
          <p>Komentarze: {order.order.Comments}</p>
          <p>Utworzone: {order.order.Creation_date}</p>
          <p>Rodzaj płatności: {order.order.PaymentName}</p>
          <p>Dostawa: {order.order.DeliveryName}</p>
          <p>
            Status:{" "}
            {order.order.Status_id === 1
              ? "nowe"
              : order.order.Status_id === 2
              ? "Oczekiwanie na płatność"
              : order.order.Status_id === 3
              ? "Płatność na termin"
              : order.order.Status_id === 4
              ? "Zaakceptowane"
              : order.order.Status_id === 5
              ? "Gotowe do obioru"
              : order.order.Status_id === 6
              ? "Gotowe do wysyłki"
              : "Zakończone"}
          </p>
          {(order.order.Status_id == 1 ||
            order.order.Status_id == 2 ||
            order.order.Status_id == 3) && (
            <p>
              Wybierz date dostawy:{" "}
              <DatePicker date={sendData} handleChange={handleChange} />
            </p>
          )}
          {(order.order.Status_id == 1 ||
            order.order.Status_id == 2 ||
            order.order.Status_id == 3) && (
            <p>
              <button
                onClick={() => {
                  setShowAsAccepted(true);
                }}
              >
                Oznacz zamówienie jako w realizacji
              </button>
            </p>
          )}
          {order.order.Status_id == 4 && order.order.Delivery_type != 4 && (
            <p>
              <input
                value={dpdCode}
                onChange={(e) => {
                  setDpdCode(e.target.value);
                }}
                placeholder="Numer listu przewozowego"
              />
            </p>
          )}
          {order.order.Status_id == 4 && (
            <p>
              <button
                onClick={() => {
                  if (dpdCode != "" || order.order.Delivery_type == 4) {
                    setShowAsReady(true);
                  }
                }}
              >
                Oznacz zamówienie jako gotowe/spakowane
              </button>
            </p>
          )}
          {(order.order.Status_id == 5 || order.order.Status_id == 6) && (
            <p>
              <button
                onClick={() => {
                  setShowAsFinished(true);
                }}
              >
                Oznacz zamówienie jako zakończone
              </button>
            </p>
          )}
        </div>
      )}
      <p>
        {order.products &&
          order.products.map((element) => {
            return (
              <p>
                <ProductList product={element} />
              </p>
            );
          })}
      </p>
    </motion.div>
  );
}

export default OrderDetailsPage;
