import React from "react";
import { Link } from "react-router-dom";
import ButtonPrimaryToLighter from "../../../Common/Buttons/ButtonPrimaryToLighter";

export default function Offer() {
  return (
    <div className="overflow-hidden grid grid-rows-2 w-full rounded-lg bg-neutral-100 shadow-md relative">
      <div className="relative">
        <div
          className="absolute top-0 left-0 w-full h-full"
          style={{
            backgroundImage: "url(/Images/tlo1.jpeg)",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        ></div>
        <div className="h-full relative bg-primary bg-opacity-60 backdrop-blur-sm p-2 text-neutral-50">
          <h1 className="text-3xl font-black">Sprawdź naszą ofertę:</h1>
          <div className="flex flex-col p-2">
            <ol className="list-inside list-disc">
              <li>
                <Link to="/audyty_instalacji" className="underline">
                  Audyty instalacji
                </Link>
              </li>
              <li>
                <Link to="/wykonawstwo_instalacjii" className="underline">
                  Wykonawstwo instalacji
                </Link>
              </li>
              <li>
                <Link to="/projektowanie_instalacjii" className="underline">
                  Projektowanie instalacji
                </Link>
              </li>
              <li>
                <Link to="/stacje_sprezarkowe" className="underline">
                  Stacje sprężarkowe
                </Link>
              </li>
            </ol>
          </div>
        </div>
      </div>
      <div className="p-2">
        <h1 className="text-3xl font-black">Zarejestruj się</h1>
        <p className="p-2">
          Zarejestruj się i korzystaj z własnych warunków handolwych.
        </p>
        <ButtonPrimaryToLighter
          text="Zarejestruj się"
          link={"register"}
          icon={"person_add"}
          size="large"
        />
      </div>
    </div>
  );
}
