import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import {
  fetchUserOrders,
  selectFetchUserOrdersState,
  selectOrders,
} from "../../../../Store/Slices/User/User";
import LoadingAnimation from "../../../../Common/LoadingAnimation/LoadingAnimation";
import Order from "./Order/Order";
import { Route, Routes } from "react-router-dom";
import OrderPage from "../../../../Common/OrderPage/OrderPage";
import InfoMessage from "../../../../Common/Messages/InfoMessage/InfoMessage";
import ErrorMessage from "../../../../Common/Messages/ErrorMessage/ErrorMessage";

function Orders() {
  const dispatch = useDispatch<AppDispatch>();

  const orders = useSelector(selectOrders);
  const state = useSelector(selectFetchUserOrdersState);

  useEffect(() => {
    dispatch(fetchUserOrders());
  }, [dispatch]);

  return (
    <div className="relative">
      <Routes>
        <Route
          path="/"
          element={
            <div>
              <h1 className="text-2xl font-bold mb-4">Twoje zamówienia</h1>
              {state.isLoading && (
                <LoadingAnimation type="local" color="primary" />
              )}
              {state.isLoading && <div className="w-full h-32"></div>}
              {state.hasLoaded &&
                orders.length > 0 &&
                orders.map((order) => (
                  <Order key={order.order.Id} orderData={order} />
                ))}
              {state.hasLoaded && orders.length === 0 && (
                <InfoMessage
                  icon="info"
                  message="Nie masz jeszcze żadnych zamówień."
                  size="small"
                />
              )}
              {state.failedLoading && (
                <ErrorMessage error={state.error} message="Błąd ładowania zamówień." />
              )}
            </div>
          }
        />
        <Route path="/:id" element={<OrderPage />} />
      </Routes>
    </div>
  );
}

export default Orders;
