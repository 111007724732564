import React, { useRef, useState } from "react";
import { ProductDisplay } from "../../../Models/Product";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../Store/Slices/User/User";

type Props = {
  product: ProductDisplay;
  showMoreInfo: boolean;
  showFullNameOnHover: boolean;
  showFullName: boolean;
};

function ProductInfo({
  product,
  showMoreInfo,
  showFullNameOnHover,
  showFullName,
}: Props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  const [showFullNameS, setShowFullNameS] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const MAXLEN: number = 25;

  const handleMouseEnter = () => {
    setShowFullNameS(true);
  };

  const handleMouseLeave = () => {
    setShowFullNameS(false);
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (tooltipRef.current) {
      const { clientX, clientY } = event;
      const tooltipWidth = tooltipRef.current.clientWidth;
      const tooltipHeight = tooltipRef.current.clientHeight;

      setTooltipPosition({
        x: clientX - tooltipWidth / 2,
        y: clientY - tooltipHeight - 10,
      });
    }
  };
  return (
    <div
      className="relative flex flex-col sm:flex-row items-center justify-between w-full overflow-hidden  p-2"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <h1 className="block text-xs sm:text-sm lg:text-base font-bold">
        {product.Name}
      </h1>
      {false &&
        showFullNameOnHover &&
        showFullNameS &&
        product.Name.length > MAXLEN && (
          <div
            ref={tooltipRef}
            style={{
              position: "fixed",
              left: `${tooltipPosition.x}px`,
              top: `${tooltipPosition.y}px`,
              zIndex: 9999,
            }}
            className="max-w-40 text-start whitespace-break-spaces rounded-lg shadow-md bg-neutral-100 bg-opacity-40 backdrop-blur-md p-2"
          >
            {product.Name}
          </div>
        )}
      {showMoreInfo && (
        <div className="flex flex-col items-center justify-center">
          <div className="flex items-center justify-center w-full">
            {/* <span className="material-symbols-outlined">sell</span> */}
            <h1 className="flex items-center space-x-1 whitespace-nowrap text-xs sm:text-sm lg:text-base font-bold text-neutral-950">
              <span
                className={`${
                  ((product.Price_Discount !== null &&
                    product.Price_Discount !== undefined) ||
                    (product.Price_Individual !== null &&
                      product.Price_Individual !== undefined) ||
                    (product.Category_Discount !== null &&
                      product.Category_Discount !== undefined)) &&
                  isLoggedIn
                    ? "line-through text-neutral-500"
                    : ""
                }`}
              >
                {product.Price !== undefined
                  ? parseFloat(product?.Price.toString()).toFixed(2)
                  : product.Amount !== undefined
                  ? parseFloat(product?.Amount.toString())?.toFixed(2)
                  : 0}
              </span>
              {isLoggedIn &&
                product.Price_Individual !== null &&
                product.Price_Individual !== undefined && (
                  <span className="underline decoration-primary decoration-2">
                    {product.Price_Individual?.toFixed(2)}
                  </span>
                )}
              {isLoggedIn &&
                (product.Price_Individual === null ||
                  product.Price_Individual === undefined) &&
                product.Price_Discount !== null &&
                product.Price_Discount !== undefined && (
                  <span className="underline decoration-green-600 decoration-2">
                    {(
                      (product.Price !== undefined
                        ? product.Price
                        : product.Amount !== undefined
                        ? product.Amount
                        : 0) *
                      (1 - product.Price_Discount / 100)
                    )?.toFixed(2)}
                  </span>
                )}
              {isLoggedIn &&
                (product.Price_Individual === null ||
                  product.Price_Individual === undefined) &&
                (product.Price_Discount === null ||
                  product.Price_Discount === undefined) &&
                product.Category_Discount !== null &&
                product.Category_Discount !== undefined && (
                  <span className="underline decoration-orange-600 decoration-2">
                    {(
                      (product.Price !== undefined
                        ? product.Price
                        : product.Amount !== undefined
                        ? product.Amount
                        : 0) *
                      (1 - product.Category_Discount / 100)
                    )?.toFixed(2)}
                  </span>
                )}
              <span>PLN</span>
            </h1>
          </div>
          {/*<div className="flex items-center justify-between w-full space-x-2">
            <span className="material-symbols-outlined">apartment</span>
            <h1 className="block whitespace-nowrap text-sm sm:text-base font-bold text-neutral-700">
              {product.Producer}
            </h1>
          </div>*/}
          <div className="flex items-center justify-end w-full space-x-2">
            {/* <span className="material-symbols-outlined">barcode</span> */}
            <h1 className="block whitespace-nowrap text-xs sm:text-sm lg:text-base font-bold text-neutral-600">
              {product.Catalog_name}
            </h1>
          </div>
          <div className="flex items-center space-x-1 rounded-full bg-opacity-40 backdrop-blur-sm">
            <h1 className="block whitespace-nowrap text-sm sm:text-base font-bold select-none cursor-default">
              {(product.Stock_quantity ?? 0) > 0 ? "Dostępny" : "Na zamówienie"}
            </h1>
            <div
              className={`w-4 h-4 rounded-full animate-pulse bg-${
                product.Stock_quantity !== undefined
                  ? (product.Stock_quantity ?? 0) > 0
                    ? "green"
                    : "orange"
                  : "transparent"
              }-600`}
            ></div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ProductInfo;
