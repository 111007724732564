import { motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonProps } from "../../Models/Button";

function ButtonNeutral50({
  text,
  icon = null,
  onClick,
  size,
  isSubmit = false,
  customClasses = "",
  link = null,
}: ButtonProps) {
  const navigate = useNavigate();

  return (
    <motion.button
      className={
        `${size === "small" && "p-1"}
        ${size === "medium" && "p-2"}
        ${
          size === "large" && "p-3"
        }  flex items-center justify-center shadow-md rounded-lg bg-neutral-50 space-x-1 whitespace-nowrap ` +
        customClasses
      }
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (link != null) {
          navigate(link);
        } else {
          onClick && onClick();
        }
      }}
      type={isSubmit ? "submit" : "button"}
    >
      <span
        className={`${size === "small" && "text-lg"}
        ${size === "medium" && "text-xl"}
        ${size === "large" && "text-2xl"} font-bold text-neutral-700`}
      >
        {text}
      </span>
      {icon != null && (
        <span
          className={`
        ${size === "small" && "text-xl"}
        ${size === "medium" && "text-2xl"}
        ${size === "large" && "text-3xl"}
        material-symbols-outlined text-primary`}
        >
          {icon}
        </span>
      )}
    </motion.button>
  );
}

export default ButtonNeutral50;
