import React, { useState } from "react";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";

function PullingForceCalculator() {
  const [srednicaTloka, setSrednicaTloka] = useState("");
  const [srednicaTloczyska, setSrednicaTloczyska] = useState("");
  const [cisnienie, setCisnienie] = useState("");
  const [result, setResult] = useState("");

  const calculatePullingForce = () => {
    const silaCiagnaca =
      ((1 / 4) *
        Math.PI *
        (parseFloat(srednicaTloka) * parseFloat(srednicaTloka) -
          parseFloat(srednicaTloczyska) * parseFloat(srednicaTloczyska)) *
        parseFloat(cisnienie)) /
      10000; // Adjusting the calculation to correctly handle the units
    setResult(`Wynik: ${silaCiagnaca.toFixed(4).toString()} kN`);
  };

  return (
    <div className="w-full flex flex-col items-start justify-center space-y-2">
      <h2
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: "25px",
          fontFamily: "system-ui, sans-serif",
        }}
      >
        Siła ciągnąca
      </h2>
      <div className="mainDivCalculator flex flex-wrap items-center ">
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Średnica tłoka(mm)</span>
          <input
            type="number"
            value={srednicaTloka}
            onChange={(e) => setSrednicaTloka(e.target.value)}
            placeholder="Średnica tłoka(mm)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Średnica tłoczyska(mm)</span>
          <input
            type="number"
            value={srednicaTloczyska}
            onChange={(e) => setSrednicaTloczyska(e.target.value)}
            placeholder="Średnica tłoczyska(mm)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Ciśnienie (bar)</span>
          <input
            type="number"
            value={cisnienie}
            onChange={(e) => setCisnienie(e.target.value)}
            placeholder="Ciśnienie (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <ButtonNeutral50
          text={"Zatwierdź"}
          size={"medium"}
          onClick={calculatePullingForce}
          icon={"check"}
        />
        <h2 className="text-2xl font-bold text-center whitespace-nowrap">
          {result}
        </h2>
      </div>
    </div>
  );
}

export default PullingForceCalculator;
