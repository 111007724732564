import React, { useState } from "react";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";

function DiameterCalculatorSecond() {
  const [silaPchajaca, setSilaPchajaca] = useState("");
  const [cisnienie, setCisnienie] = useState("");
  const [result, setResult] = useState("");

  const calculateDiameter = () => {
    // Adjusting the formula to ensure units are correct:
    // Force in Newtons (N) and pressure in bar (converting to Pascals by multiplying by 10^5 for the calculation),
    // Diameter will be in meters (m), converting to mm by multiplying by 1000 for the result.
    // Formula adjusted as: sqrt((4 * Force) / (pi * Pressure (Pa))) * 1000
    const srednica =
      Math.sqrt(
        (4 * parseFloat(silaPchajaca)) /
          (Math.PI * (parseFloat(cisnienie) * 1e5))
      ) * 1000;
    setResult(`Wynik: ${srednica.toFixed(4).toString()} mm`);
  };

  return (
    <div className="w-full flex flex-col items-start justify-center space-y-2">
      <h2
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: "25px",
          fontFamily: "system-ui, sans-serif",
        }}
      >
        Średnica
      </h2>
      <div className="mainDivCalculator flex flex-wrap items-center">
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Siła pchająca (N)</span>
          <input
            type="number"
            value={silaPchajaca}
            onChange={(e) => setSilaPchajaca(e.target.value)}
            placeholder="Siła pchająca (N)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Ciśnienie (bar)</span>
          <input
            type="number"
            value={cisnienie}
            onChange={(e) => setCisnienie(e.target.value)}
            placeholder="Ciśnienie (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <ButtonNeutral50
          text={"Zatwierdź"}
          size={"medium"}
          onClick={calculateDiameter}
          icon={"check"}
        />
        <h2 className="text-2xl font-bold text-center whitespace-nowrap">
          {result}
        </h2>
      </div>
    </div>
  );
}

export default DiameterCalculatorSecond;
