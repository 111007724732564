import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function AdminNavBar() {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div
      className={`px-5 transition-all w-full flex items-center justify-center h-24 z-50 bg-neutral-50 ${
        isScrolled ? "shadow-md" : ""
      }`}
    >
      <div className="w-full max-w-[1536px] h-full flex items-center justify-between space-x-10 z-50">
        <Link to="/" className="h-full flex items-center justify-center">
          <img
            src="/Images/PRESS_BAR_LOGO.png"
            alt="logo"
            className="h-full max-h-16"
          />
        </Link>
        <div className="flex text-3xl justify-between items-center space-x-1">
          <button className="hover:bg-neutral-100 hover:text-primary p-3 cursor-pointer rounded-lg relative">
            <span className="material-symbols-outlined text-3xl">refresh</span>
          </button>
          <button className="hover:bg-neutral-100 hover:text-primary p-3 cursor-pointer rounded-lg relative">
            <span className="material-symbols-outlined text-3xl">
              more_vert
            </span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default AdminNavBar;
