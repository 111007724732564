import { ProductCart } from "./Product";

export type CartAddProduct = {
  Product_id: number;
  Quantity: number;
};

export const productCartToCartAddProduct = (
  products: ProductCart[]
): CartAddProduct[] => {
  const result = [] as CartAddProduct[];
  for (const product of products) {
    result.push({
      Product_id: product.product.Id,
      Quantity: product.quantity,
    });
  }
  return result;
};

export const getLowestPrice = (product: ProductCart): number => {
  if (
    product.product.Price_Individual !== null &&
    product.product.Price_Individual !== undefined
  ) {
    return product.product.Price_Individual;
  } else if (
    (product.product.Price_Discount !== null &&
      product.product.Price_Discount !== undefined) ||
    (product.product.Category_Discount !== null &&
      product.product.Category_Discount !== undefined)
  ) {
    return Math.min(
      product.product.Price_Discount || Infinity,
      product.product.Price || Infinity
    );
  } else {
    return product.product.Price;
  }
};
