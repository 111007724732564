import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Parameter } from "../../Models/Parameter";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { AppDispatch } from "../../Store/store";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import Filter from "./Filter/Filter";
import {
  fetchParameters,
  getCountsForFilters,
  removeAllParameterChoices,
  selectChosenCategoryId,
  selectParameterChoices,
  selectParameters,
  selectParametersCounts,
  selectParametersState,
} from "../../Store/Slices/Parameters/Parameters";
import PriceFilter from "./Filter/PriceFilter/PriceFilter";
import { selectCurrentCategoryId } from "../../Store/Slices/Categories/Categories";
import { selectChosenCategory } from "../../Store/Slices/admin/Products/AddProduct";

function Filters() {
  const dispatch = useDispatch<AppDispatch>();

  const { isLoading, hasLoaded, failedLoading, error } = useSelector(
    selectParametersState
  );

  const chosenParameters = useSelector(selectParameterChoices);

  const filters = useSelector(selectParameters);

  const filtersCounts = useSelector(selectParametersCounts);

  const chosenCategoryId = useSelector(selectChosenCategoryId);

  const [, setSearchParams] = useSearchParams({
    filters: [],
  });

  const currentCategoryId = useSelector(selectCurrentCategoryId);

  useEffect(() => {
    if (currentCategoryId !== chosenCategoryId) {
      dispatch(fetchParameters(currentCategoryId));
    }
  }, [currentCategoryId]);

  useEffect(() => {
    var parameters = [];
    var choices = [];
    for (const element of chosenParameters) {
      for (const element2 of element.choicesIds) {
        choices.push(element2);
      }
      if (element.choicesIds.length !== 0) {
        parameters.push(element.parameterId);
      }
    }
    dispatch(
      getCountsForFilters({
        Category_id: parseInt(currentCategoryId.toString()),
        parameters: parameters,
        choices: choices,
      })
    );
  }, [chosenParameters, currentCategoryId]);

  return (
    <div className="relative px-1 py-2 overflow-x-hidden h-full overflow-y-scroll w-full">
      {isLoading && <LoadingAnimation color="primary" />}
      {isLoading && <div className="w-full h-40"></div>}
      {failedLoading && (
        <div className="text-xl font-bold text-neutral-700">
          {error?.message}
        </div>
      )}
      {hasLoaded && filters.length > 0 && (
        <div className="flex justify-end">
          <button
            className="text-sm font-bold text-neutral-700 hover:text-neutral-800"
            onClick={() => {
              setSearchParams((prev) => {
                dispatch(removeAllParameterChoices());
                prev.delete("filters");
                prev.delete("price_min");
                prev.delete("price_max");
                return prev;
              });
            }}
          >
            Wyczyść filtry
          </button>
        </div>
      )}
      <motion.div
        className="flex flex-col"
        initial={{ opacity: 0, x: 100 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -100 }}
        transition={{ duration: 0.3 }}
        key={"filters"}
      >
        {hasLoaded && <PriceFilter key={"price_filter"} />}
        {hasLoaded &&
          filters.length > 0 &&
          filters.map((filter: Parameter, id: number) => {
            return (
              <Filter key={id} filter={filter} FiltersCounts={filtersCounts} />
            );
          })}
      </motion.div>
      {hasLoaded && filters.length === 0 && (
        <div className="text-xl font-bold text-neutral-700">
          Dla tej kategorii nie ma żadnych dodatkowych filtrów.
        </div>
      )}
    </div>
  );
}

export default Filters;
