import React from "react";
import { Breadcrumb } from "../../../Models/Product";
import { Link } from "react-router-dom";

type Props = {
  breadcrumbs: Breadcrumb[];
  admin?: boolean
};

function Breadcrumbs({ breadcrumbs, admin }: Props) {
  return (
    <div className="w-full max-w-[1536px] flex items-center py-2 px-2 sm:px-5 overflow-x-scroll pr-4 no-scrollbar">
      {breadcrumbs.map((breadcrumb, index) => {
        return (
          <div key={index} className="flex items-center space-x-2">
            <span className="material-symbols-outlined text-xl lg:text-2xl text-primary">
              chevron_right
            </span>
            <Link
              to={admin?`/admin/products?category=${breadcrumb.ID}`:`/search?category=${breadcrumb.ID}`}
              className="whitespace-nowrap transition-all block border-b-2 border-neutral-50 cursor-pointer text-base lg:text-xl text-neutral-600 hover:text-neutral-950 hover:border-primary"
            >
              {breadcrumb.NAME}
            </Link>
          </div>
        );
      })}
    </div>
  );
}

export default Breadcrumbs;
