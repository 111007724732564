import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import PageIcon from "./PageIcon/PageIcon";
import { useSelector } from "react-redux";
import { selectNumberOfProducts } from "../../../../Store/Slices/Products/Products";

function PageControls(): JSX.Element {
  const [searchParams, setSearchParams] = useSearchParams();

  const totalElements = useSelector(selectNumberOfProducts);

  const page = parseInt(searchParams.get("page") || "1");
  const maxPage =
    Math.ceil(totalElements / parseInt(searchParams.get("limit") || "20")) || 1;

  useEffect(() => {
    setSearchParams((prev) => {
      if (parseInt(prev.get("page") || "1") > maxPage) {
        prev.set("page", maxPage.toString());
      }
      return prev;
    });
  }, [maxPage, searchParams.get("page")]);

  const pagesAround = [];
  for (let i = page - 1; i <= page + 1; i++) {
    if (i > 0 && i <= maxPage) {
      pagesAround.push(i);
    }
  }

  return (
    <div className="w-full p-2">
      <div className="flex items-center justify-end space-x-2">
        <button
          className="p-2 w-8 h-8 sm:w-12 sm:h-12 rounded-full hover:bg-primary group flex items-center justify-center transition-all"
          onClick={() => {
            setSearchParams((prev) => {
              if (parseInt(prev.get("page") || "1") === 1) {
                return prev;
              }
              prev.set(
                "page",
                (parseInt(prev.get("page") || "1") - 1).toString()
              );
              return prev;
            });
          }}
        >
          <span className="material-symbols-outlined text-2xl group-hover:text-neutral-50 transition-all">
            chevron_left
          </span>
        </button>
        <div className="flex items-center justify-center space-x-1">
          {pagesAround[0] !== 1 && (
            <PageIcon
              page={1}
              setPage={(page) => {
                setSearchParams((prev) => {
                  prev.set("page", page.toString());
                  return prev;
                });
              }}
            />
          )}
          {pagesAround[0] !== 1 && (
            <div className="text-xl text-neutral-600">...</div>
          )}
          {pagesAround.map((page) => (
            <PageIcon
              key={page}
              page={page}
              setPage={(page) => {
                setSearchParams((prev) => {
                  prev.set("page", page.toString());
                  return prev;
                });
              }}
            />
          ))}
          {pagesAround[pagesAround.length - 1] !== maxPage && (
            <div className="text-xl text-neutral-600">...</div>
          )}
          {pagesAround[pagesAround.length - 1] !== maxPage && (
            <PageIcon
              page={maxPage}
              setPage={(page) => {
                setSearchParams((prev) => {
                  prev.set("page", page.toString());
                  return prev;
                });
              }}
            />
          )}
        </div>
        <button
          className="p-2 w-8 h-8 sm:w-12 sm:h-12 rounded-full hover:bg-primary group flex items-center justify-center transition-all"
          onClick={() => {
            setSearchParams((prev) => {
              if (parseInt(prev.get("page") || "1") === maxPage) {
                return prev;
              }
              prev.set(
                "page",
                (parseInt(prev.get("page") || "1") + 1).toString()
              );
              return prev;
            });
          }}
        >
          <span className="material-symbols-outlined text-2xl group-hover:text-neutral-50 transition-all">
            chevron_right
          </span>
        </button>
      </div>
    </div>
  );
}

export default PageControls;
