import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CategoryModel } from "../../../../Models/Category";
import { StateType } from "../../../../Models/State";
import axios from "axios";
import { server } from "../../../../server";
import { UserAdminDisplay } from "../../../../Models/User";


type adminCategoriesSliceState = {
    currentCategoryId: string;
    parentCategory: CategoryModel[];
    categories: CategoryModel[];
    categoriesAllMap: Map<number, CategoryModel[]>;
    categoriesAll: CategoryModel[];
    chosenCategories: CategoryModel[];
    users: UserAdminDisplay[];
    usersSearchDiscounts: UserAdminDisplay[];
    usersState: StateType;
    state: StateType;
    chosenUserId: number;
    chosenUserIdSearchDiscounts: number;
    userDiscounts: {Category_id: number, Discount: number, Name: string}[];
};


export const getCategories=createAsyncThunk(
    'adminCategories/fetchAll',
    async ({id, allCategoriesMap, currentCategory}:{id: string, allCategoriesMap: Map<number, CategoryModel[]>, currentCategory: CategoryModel}) => {
        try {
          if(allCategoriesMap.has(parseInt(id))){
            return {categories: [currentCategory].concat(allCategoriesMap.get(parseInt(id))??[]), concat: false}
          }
          const response = await axios.get<CategoryModel[]>(
            `${server}/api/Categories/${id}/children`
          );
          
          return {categories: response.data, concat: true};
        } catch (error) {
          throw error;
        }
    }
);

export const getUsers=createAsyncThunk(
  'adminCategories/fetchUsers',
  async({name}:{name:string})=>{
    try{
      const result = await axios.get<UserAdminDisplay[]>(`${server}/api/Users/admin/find/${name}`, 
      {withCredentials: true});
      return result.data;
    }
    catch(error){
      throw error;
    }
  }
);

export const getUsersSearchDiscounts=createAsyncThunk(
  'adminCategories/fetchUsersSearchDiscounts',
  async({name}:{name:string})=>{
    try{
      const result = await axios.get<UserAdminDisplay[]>(`${server}/api/Users/admin/find/${name}`, 
      {withCredentials: true});
      return result.data;
    }
    catch(error){
      throw error;
    }
  }
);

export const getUserDiscounts=createAsyncThunk(
  'adminCategories/fetchUserDiscounts',
  async({id}:{id:number})=>{
    try{
      const result = await axios.get<UserAdminDisplay[]>(`${server}/api/IndPrices/categories/user/get/${id}`, 
      {withCredentials: true});
      return result.data;
    }
    catch(error){
      throw error;
    }
  }
);

export const addIndividualDiscountCategory = createAsyncThunk(
  'adminCategories/addIndDiscount',
  async({discount, userId, categories}:{discount: number, userId:number, categories: number[]})=>{
  try{
    const result = await axios.post(`${server}/api/IndPrices/category/user/discount/add`,
    {discount: discount, userId: userId, categories: categories},
    {withCredentials: true});
    return result.data;
  }
  catch(e){
    throw e;
  }
});

export const deleteCategoryDiscount = createAsyncThunk(
  'adminCategories/deleteIndDiscount',
  async({userId, categoryId, discount}:{userId:number, categoryId: number, discount: number})=>{
    try{
      const response = await axios.post(`${server}/api/IndPrices/categories/delete`,
      {userId, categoryId, discount},
      {withCredentials: true});
      return {categoryId, discount};
    }
    catch(e){
      throw e;
    }
  }
)

const categoryAdminSlice = createSlice({
    name: 'categoriesAdmin',
    initialState:{
      currentCategoryId: "1",
      parentCategory: [],
      categories: [],
      categoriesAllMap: new Map(),
      categoriesAll: [],
      chosenCategories: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
      usersState: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
      users: [],
      usersSearchDiscounts: [],
      chosenUserId: -1,
      chosenUserIdSearchDiscounts: -1,
      userDiscounts: [],
        
    } as unknown as adminCategoriesSliceState,
    reducers: {
        addToChosenCategories: (state, action)=>{
            state.chosenCategories=[action.payload].concat(state.chosenCategories)??[];
        },
        clearChosenCategories: (state)=>{
          state.chosenCategories=[];
        },
        setCurrentCategoryId: (state, action) => {
            state.currentCategoryId = action.payload;
        },
        setChosenUser: (state, action)=>{
          state.chosenUserId = action.payload;
        },
        setChosenUserSearchDiscounts: (state, action)=>{
          state.chosenUserIdSearchDiscounts = action.payload;
        }
    },
    extraReducers: {
        [getCategories.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.parentCategory=[action.payload.categories[0]];
            var category = -1;
            var categories:CategoryModel[] = [];
      
            if(action.payload.concat){
              state.categoriesAll=state.categoriesAll.concat(action.payload.categories);
              ((action.payload.categories as CategoryModel[]).slice(1)).forEach(element=>{
                if(element.Parent_Category!=category){
                  state.categoriesAllMap.set(category, categories);
                  categories=[];
                  category=element.Parent_Category;
                }
                categories.push(element);
              })
              if(((action.payload.categories as CategoryModel[]).slice(1)).length==0){
                category=state.parentCategory[0].Id;
              }
              state.categoriesAllMap.set(category, categories);
            }
            
            state.categories=state.categoriesAllMap.get((action.payload.categories as CategoryModel[])[0].Id)??[];
            state.currentCategoryId = (action.payload.categories as CategoryModel[])[0].Id.toString();
          },
          [getCategories.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getCategories.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [getUsers.fulfilled.type]: (state, action) => {
            state.usersState.hasLoaded = true;
            state.usersState.isLoading = false;
            state.usersState.failedLoading = false;
            state.users =  action.payload;
          },
          [getUsers.pending.type]: (state) => {
            state.usersState.isLoading = true;
            state.usersState.failedLoading = false;
            state.usersState.hasLoaded = false;
          },
          [getUsers.rejected.type]: (state, action) => {
            state.usersState.isLoading = false;
            state.usersState.failedLoading = true;
            state.usersState.hasLoaded = false;
            state.usersState.error = action.error;
          },
          [addIndividualDiscountCategory.fulfilled.type]: (state, action) => {
            state.usersState.hasLoaded = true;
            state.usersState.isLoading = false;
            state.usersState.failedLoading = false;
            state.users =  [];
            state.currentCategoryId='1';
            state.chosenUserId=-1;
            state.chosenCategories=[];
          },
          [addIndividualDiscountCategory.pending.type]: (state) => {
            state.usersState.isLoading = true;
            state.usersState.failedLoading = false;
            state.usersState.hasLoaded = false;
          },
          [addIndividualDiscountCategory.rejected.type]: (state, action) => {
            state.usersState.isLoading = false;
            state.usersState.failedLoading = true;
            state.usersState.hasLoaded = false;
            state.usersState.error = action.error;
          },
          [getUsersSearchDiscounts.fulfilled.type]: (state, action) => {
            state.usersState.hasLoaded = true;
            state.usersState.isLoading = false;
            state.usersState.failedLoading = false;
            state.usersSearchDiscounts =  action.payload;
          },
          [getUsersSearchDiscounts.pending.type]: (state) => {
            state.usersState.isLoading = true;
            state.usersState.failedLoading = false;
            state.usersState.hasLoaded = false;
          },
          [getUsersSearchDiscounts.rejected.type]: (state, action) => {
            state.usersState.isLoading = false;
            state.usersState.failedLoading = true;
            state.usersState.hasLoaded = false;
            state.usersState.error = action.error;
          },
          [getUserDiscounts.fulfilled.type]: (state, action) => {
            state.usersState.hasLoaded = true;
            state.usersState.isLoading = false;
            state.usersState.failedLoading = false;
            state.userDiscounts = action.payload;
          },
          [getUserDiscounts.pending.type]: (state) => {
            state.usersState.isLoading = true;
            state.usersState.failedLoading = false;
            state.usersState.hasLoaded = false;
          },
          [getUserDiscounts.rejected.type]: (state, action) => {
            state.usersState.isLoading = false;
            state.usersState.failedLoading = true;
            state.usersState.hasLoaded = false;
            state.usersState.error = action.error;
          },
          [deleteCategoryDiscount.fulfilled.type]: (state, action) => {
            state.usersState.hasLoaded = true;
            state.usersState.isLoading = false;
            state.usersState.failedLoading = false;
            state.userDiscounts = state.userDiscounts.filter(element=>{
              if(element.Discount!=action.payload.discount || element.Category_id!=action.payload.categoryId){
                return true;
              }
              return false;
            });
          },
          [deleteCategoryDiscount.pending.type]: (state) => {
            state.usersState.isLoading = true;
            state.usersState.failedLoading = false;
            state.usersState.hasLoaded = false;
          },
          [deleteCategoryDiscount.rejected.type]: (state, action) => {
            state.usersState.isLoading = false;
            state.usersState.failedLoading = true;
            state.usersState.hasLoaded = false;
            state.usersState.error = action.error;
          },
    }
});

export const selectParentCategory = (state: {
    adminCategories: adminCategoriesSliceState;
  }): CategoryModel => {
    return state.adminCategories.parentCategory[0];
  };
  
  export const selectCurrentCategoryId = (state: {
    adminCategories: adminCategoriesSliceState;
  }): string => {
    return state.adminCategories.currentCategoryId;
  };
  
  export const selectAllCategoriesMap = (state: {
    adminCategories: adminCategoriesSliceState;
  }): Map<number, CategoryModel[]> => {
    return state.adminCategories.categoriesAllMap;
  };
  
  export const selectAllCategories = (state: {
    adminCategories: adminCategoriesSliceState;
  }): CategoryModel[] => {
    return state.adminCategories.categoriesAll;
  };
  export const selectCategories = (state: {
    adminCategories: adminCategoriesSliceState;
  }): CategoryModel[] => {
    return state.adminCategories.categories;
  };
  export const selectChosenCategories = (state: {
    adminCategories: adminCategoriesSliceState;
  }): CategoryModel[] => {
    return state.adminCategories.chosenCategories;
  };

  export const selectUsers = (state: {
    adminCategories: adminCategoriesSliceState;
  }): UserAdminDisplay[] => {
    return state.adminCategories.users;
  };

  export const selectChosenUser = (state: {
    adminCategories: adminCategoriesSliceState;
  }): number => {
    return state.adminCategories.chosenUserId;
  };

  export const selectUsersSearchDiscounts = (state: {
    adminCategories: adminCategoriesSliceState;
  }): UserAdminDisplay[] => {
    return state.adminCategories.usersSearchDiscounts;
  };

  export const selectChosenUserSearchDiscounts = (state: {
    adminCategories: adminCategoriesSliceState;
  }): number => {
    return state.adminCategories.chosenUserIdSearchDiscounts;
  };

  export const selectChosenUserDiscounts = (state: {
    adminCategories: adminCategoriesSliceState;
  }): {Category_id: number, Discount: number, Name: string}[] => {
    return state.adminCategories.userDiscounts;
  };

  export const { setCurrentCategoryId, addToChosenCategories,
    setChosenUser, clearChosenCategories, setChosenUserSearchDiscounts } = categoryAdminSlice.actions;

export default categoryAdminSlice.reducer;
