import React from "react";

function CompanyInfo() {
  return (
    <div className="w-full flex flex-col lg:flex-row justify-evenly items-stretch space-y-3 lg:space-y-0">
      <div className="w-auto h-1 bg-primary rounded-lg"></div>
      <div className="flex flex-col space-y-4 items-center">
        <h1 className="text-xl -xl:text-3xl font-semibold">
          Press Bar Sp. z o. o.
        </h1>
        <p className="text-sm -xl:text-base flex space-x-2">
          <span className="material-symbols-outlined">location_on</span>
          <span>ul. Główna 14, 42-280 Częstochowa</span>
        </p>
        <p className="text-sm -xl:text-base flex space-x-2">
          <span className="material-symbols-outlined">schedule</span>
          <span>Godziny otwarcia: Pn. – Pt.: 8:00 – 16:00</span>
        </p>
      </div>
      <div className="w-1 h-auto bg-primary rounded-lg"></div>
      <div className="w-auto h-1 bg-primary rounded-lg"></div>
      <div className=" flex flex-col space-y-4 items-center">
        <h1 className="text-xl -xl:text-3xl font-semibold"> Kontakt</h1>
        <p className="text-sm -xl:text-base flex items-center space-x-2">
          <span className="material-symbols-outlined">mail</span>
          <a href="mailto:biuro@pressbar.pl">biuro@pressbar.pl</a>
        </p>
        <p className="text-sm -xl:text-base flex items-center space-x-2">
          <span className="material-symbols-outlined">phone</span>
          <a href="tel:+48506162825">+48 506 162 825</a>
        </p>
      </div>
      <div className="w-1 h-auto bg-primary rounded-lg"></div>
      <div className="w-auto h-1 bg-primary rounded-lg"></div>
      <div className=" flex flex-col space-y-4 items-center">
        <h1 className="text-xl -xl:text-3xl font-semibold">
          {" "}
          Dane do przelewu
        </h1>
        <p className="text-sm -xl:text-base flex items-center space-x-2">
          Bank PKO BP
        </p>
        <p className="text-sm -xl:text-base flex items-center space-x-2">
          44 1020 1664 0000 3502 0690 1260
        </p>
      </div>
      <div className="w-1 h-auto bg-primary rounded-lg"></div>
      <div className="w-auto h-1 bg-primary rounded-lg"></div>
      <div className=" flex flex-col space-y-4 items-center">
        <h1 className="text-xl -xl:text-3xl font-semibold">
          {" "}
          Media społecznościowe
        </h1>
        <a
          href="https://www.linkedin.com/company/press-bar-sp-z-o-o/?originalSubdomain=pl"
          target="blank"
          className="text-sm underline -xl:text-base flex items-center space-x-2"
        >
          Linkedin
        </a>
      </div>
    </div>
  );
}

export default CompanyInfo;
