import React from "react";
import Input from "../../Common/Input/Input";
import ButtonPrimaryToLighter from "../../Common/Buttons/ButtonPrimaryToLighter";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../Store/store";
import { resetPasswordGetToken } from "../../Store/Slices/User/User";
import Reset from "./Reset/Reset";

function ResetPassword() {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  return (
    <div className="w-full">
      <Routes>
        <Route
          path="/"
          element={
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
              className="w-full max-w-[1536px] px-2 sm:px-5 my-20 flex flex-col items-center justify-center space-y-6"
            >
              <div className="flex space-x-2 items-center w-full group cursor-pointer">
                <span className="material-symbols-outlined text-2xl">
                  chevron_left
                </span>
                <Link to="/login" className="group-hover:underline">
                  Powrót do logowania
                </Link>
              </div>
              <h1 className="text-xl sm:text-5xl text-center font-semibold flex items-center space-x-3">
                <span>Resetowanie hasła</span>
              </h1>
              <p className="text-sm sm:text-base font-black my-1 text-center">
                Wprowadź adres email, na który wyślemy link do resetowania hasła
              </p>
              <form
                id="reset-password-form"
                className="max-w-[600px] w-full rounded-lg space-y-2"
                onSubmit={(e) => {
                  e.preventDefault();
                  const email = e.currentTarget.Email.value;
                  console.log('alalalal');
                  dispatch(resetPasswordGetToken(email));
                  navigate("sent");
                }}
              >
                <div className="relative rounded-lg p-3 w-full flex flex-col space-y-2 items-center justify-center">
                  <Input
                    name="Email"
                    label="Email"
                    type={"email"}
                    size="sm"
                    placeholder="your@mail.com"
                    required={true}
                  />
                  <ButtonPrimaryToLighter
                    text={"Przypomnij hasło"}
                    size={"medium"}
                    customClasses="w-full"
                    icon={"lock"}
                    isSubmit={true}
                    form="reset-password-form"
                  />
                </div>
              </form>
              <h1 className="text-lg font-black my-1 font-mono text-center">
                Nie masz jeszcze konta?{" "}
                <Link to="/register" className="underline hover:no-underline">
                  Zarejestruj się
                </Link>
              </h1>
            </motion.div>
          }
        />
        <Route
          path="/sent"
          element={
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -100 }}
              transition={{ duration: 0.5 }}
              className="w-full max-w-[1536px] px-2 sm:px-5 my-20 flex flex-col items-center justify-center space-y-10"
            >
              <div className="flex space-x-2 items-center w-full group cursor-pointer">
                <span className="material-symbols-outlined text-2xl">
                  chevron_left
                </span>
                <Link to="/login" className="group-hover:underline">
                  Powrót do logowania
                </Link>
              </div>
              <h1 className="text-3xl sm:text-5xl font-semibold text-center flex items-center space-x-3">
                <span> Wysłano link do resetowania hasła</span>
              </h1>
              <div className="max-w-[600px] w-full rounded-lg space-y-2">
                <div className="relative rounded-lg p-3 w-full flex flex-col space-y-2 items-center justify-center">
                  <ButtonPrimaryToLighter
                    text={"Powrót do logowania"}
                    size={"medium"}
                    customClasses="w-full"
                    icon={"login"}
                    link={"/login"}
                  />
                </div>
              </div>
              <h1 className="text-lg font-black my-1 font-mono text-center">
                Nie masz jeszcze konta?{" "}
                <Link to="/register" className="underline hover:no-underline">
                  Zarejestruj się
                </Link>
              </h1>
            </motion.div>
          }
        />
        <Route path="reset/:token" element={<Reset />} />
      </Routes>
    </div>
  );
}

export default ResetPassword;
