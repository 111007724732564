import React, { useState } from "react";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";

function DiameterCalculator() {
  const [przeplyw, setPrzeplyw] = useState("");
  const [dlEfektywna, setDlEfektywna] = useState("");
  const [spadekCisn, setSpadekCisn] = useState("");
  const [cisnienie, setCisnienie] = useState("");
  const [result, setResult] = useState("");

  const calculateDiameter = () => {
    const cisnAtm = 1.013;
    const srednica =
      Math.pow(
        (((Math.pow(parseFloat(przeplyw) / 60 / 60, 1.85) *
          parseFloat(dlEfektywna)) /
          parseFloat(spadekCisn) /
          (parseFloat(cisnienie) + cisnAtm)) *
          1.6) /
          Math.pow(10, 6),
        0.2
      ) * 1000;
    setResult(`Wynik: ${srednica.toFixed(1).toString()} mm`);
  };

  return (
    <div className="w-full flex flex-col items-start justify-center space-y-2">
      <h2
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: "25px",
          fontFamily: "system-ui, sans-serif",
        }}
      >
        Średnica rurociągu
      </h2>
      <div className="mainDivCalculator flex flex-wrap items-center ">
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>
            Przepływ pow. (m<sup>3</sup>/h)
          </span>
          <input
            type="number"
            value={przeplyw}
            onChange={(e) => setPrzeplyw(e.target.value)}
            placeholder="Przepływ pow. (m^3/h)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Długość efektywana (m)</span>
          <input
            type="number"
            value={dlEfektywna}
            onChange={(e) => setDlEfektywna(e.target.value)}
            placeholder="Długość efektywna (m)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Spadek ciśnienia (bar)</span>
          <input
            type="number"
            value={spadekCisn}
            onChange={(e) => setSpadekCisn(e.target.value)}
            placeholder="Spadek ciśnienia (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Ciśnienie sprężarki (bar)</span>
          <input
            type="number"
            value={cisnienie}
            onChange={(e) => setCisnienie(e.target.value)}
            placeholder="Ciśnienie sprężarki (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <ButtonNeutral50
          text={"Zatwierdź"}
          size={"medium"}
          onClick={calculateDiameter}
          icon={"check"}
        />
        <h2 className="text-2xl font-bold text-center whitespace-nowrap">
          {result}
        </h2>
      </div>
    </div>
  );
}

export default DiameterCalculator;
