import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProductDisplay } from "../../../../Models/Product";
import { StateType } from "../../../../Models/State";
import { UserAdminDisplay } from "../../../../Models/User";
import axios from "axios";
import { server } from "../../../../server";



type adminProductsSliceState = {
    searchProducts: ProductDisplay[],
    searchUsers: UserAdminDisplay[],
    chosenUser: number,
    chosenUserSearchDiscount: number;
    chosenProducts: ProductDisplay[],
    searchUsersSearchDiscounts: UserAdminDisplay[],
    searchProductsSearchDiscounts: {Id: number, Name: string, Discount: number}[],
    selectChosenUserDiscounts: {Id: number, Name: string, Discount: number}[];
    chosenProductToDelete: number;
    state: StateType
};

export const getUsers=createAsyncThunk(
    'indDiscountsProducts/getUsers',
    async({name}:{name:string})=>{
      try{
        const result = await axios.get<UserAdminDisplay[]>(`${server}/api/Users/admin/find/${name}`, 
        {withCredentials: true});
        return result.data;
      }
      catch(error){
        throw error;
      }
    }
);

export const getProductsByName = createAsyncThunk(
    'indDiscountsProducts/getProductsByName',
    async({name}:{name:string})=>{
        try{
            const response = await axios.get(`${server}/api/IndPrices/products/search/${name.replaceAll('/', '*1*')}`,
            {withCredentials: true});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
) 

export const getProductsByNameForUser = createAsyncThunk(
    'indDiscountsProducts/getProductsByNameForUser',
    async({name, userId}:{name:string, userId: number})=>{
        try{
            const response = await axios.get(`${server}/api/IndPrices/products/discounts?userId=${userId}&name=${name.replaceAll('/', '*1*')}`,
            {withCredentials: true});
            return response.data;
        } catch (error) {
            throw error;
        }
    }
) 

export const getUsersSearchDiscounts=createAsyncThunk(
    'indDiscountsProducts/getUsersSearchDiscounts',
    async({name}:{name:string})=>{
      try{
        const result = await axios.get<UserAdminDisplay[]>(`${server}/api/Users/admin/find/${name}`, 
        {withCredentials: true});
        return result.data;
      }
      catch(error){
        throw error;
      }
    }
);

export const addIndividualDiscountToProduct = createAsyncThunk(
    'indDiscountsProducts/addIndDiscProduct',
    async({products, userId, discount}:{products: number[], userId: number, discount: number})=>{
        try{
            const result = await axios.post(`${server}/api/IndPrices/product/user/discount/add`,
            {products: products, userId, discount},
            {withCredentials: true});
            return true
        }
        catch(e){
            throw e;
        }
    }
)


export const deleteProductDiscount = createAsyncThunk(
    'indDiscountsProducts/deleteProductDiscount',
    async({id, userId, discount}:{id: number, userId:number, discount:number})=>{
        try{
            const response = await axios.post(`${server}/api/IndPrices/products/delete`,
            {userId, id, discount},
            {withCredentials: true});
            return {id, discount};
        }
        catch(e){
            throw e;
        }
    }
)

export const getUserDiscounts = createAsyncThunk(
    'indDiscountsProducts/getUserDiscounts',
    async({userId}:{userId:number})=>{
        try{
            const result = await axios.get<UserAdminDisplay[]>(`${server}/api/IndPrices/products/user/get/${userId}`, 
            {withCredentials: true});
            return result.data;
        }
        catch(e){
            throw e;
        }
    }
)

const indDiscountsProductsSlice = createSlice({
    name: 'indDiscountsProducts',
    initialState:{
        searchProducts: [],
        searchUsers: [],
        chosenUser: -1,
        chosenUserSearchDiscount:-1, 
        chosenProducts: [],
        searchUsersSearchDiscounts: [],
        searchProductsSearchDiscounts: [],
        chosenProductToDelete: -1,
        state: {
            isLoading: false,
            failedLoading: false,
            hasLoaded: false,
            error: null,
        }
    } as unknown as adminProductsSliceState,
    reducers: {
        addToChosenProducts: (state, action)=>{
            state.chosenProducts=[action.payload].concat(state.chosenProducts)??[];
        },
        clearChosenProducts: (state)=>{
          state.chosenProducts=[];
        },
        setChosenUser: (state, action)=>{
            state.chosenUser = action.payload;
        },
        setChosenProductToDelete: (state, action)=>{
            state.chosenProductToDelete = action.payload;
        },
        setChosenUserSearchDiscounts: (state, action)=>{
            state.chosenUserSearchDiscount = action.payload;
        }
    },
    extraReducers: {
        [getUsers.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchUsers =  action.payload;
            console.log(state.searchUsers)
          },
          [getUsers.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getUsers.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [getProductsByName.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchProducts =  action.payload;
          },
          [getProductsByName.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getProductsByName.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [getUsersSearchDiscounts.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchUsersSearchDiscounts =  action.payload;
          },
          [getUsersSearchDiscounts.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getUsersSearchDiscounts.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [deleteProductDiscount.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchProductsSearchDiscounts = state.searchProductsSearchDiscounts.filter(element=>{
              if(element.Discount!=action.payload.discount || element.Id!=action.payload.id){
                return true;
              }
              return false;
            });
          },
          [deleteProductDiscount.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [deleteProductDiscount.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [getUserDiscounts.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchProductsSearchDiscounts = action.payload;
          },
          [getUserDiscounts.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getUserDiscounts.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
    }
})

export const selectProducts = (state: {
    indDiscountsProducts: adminProductsSliceState;
  }): ProductDisplay[] => {
    return state.indDiscountsProducts.searchProducts;
  };

  export const selectChosenProducts = (state: {
    indDiscountsProducts: adminProductsSliceState;
  }): ProductDisplay[] => {
    return state.indDiscountsProducts.chosenProducts;
  };

  export const selectUsers = (state: {
    indDiscountsProducts: adminProductsSliceState;
  }): UserAdminDisplay[] => {
    return state.indDiscountsProducts.searchUsers;
  };

  export const selectChosenUser = (state: {
    indDiscountsProducts: adminProductsSliceState;
  }): number => {
    return state.indDiscountsProducts.chosenUser;
  };

  export const selectChosenUserSearchDiscounts = (state: {
    indDiscountsProducts: adminProductsSliceState;
  }): number => {
    return state.indDiscountsProducts.chosenUserSearchDiscount;
  };

  export const selectUsersSearchDiscounts = (state: {
    indDiscountsProducts: adminProductsSliceState;
  }): UserAdminDisplay[] => {
    return state.indDiscountsProducts.searchUsersSearchDiscounts;
  };

  export const selectUserDiscounts = (state: {
    indDiscountsProducts: adminProductsSliceState;
  }): {Id: number, Name: string, Discount: number}[] => {
    return state.indDiscountsProducts.searchProductsSearchDiscounts;
  };

export const {addToChosenProducts, clearChosenProducts, setChosenUserSearchDiscounts,
    setChosenProductToDelete, setChosenUser} = indDiscountsProductsSlice.actions


export default indDiscountsProductsSlice.reducer;
