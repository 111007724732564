import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import Confirm from "../../../../Common/Modals/Confirm/Confirm";
import EditNameForm from "../../WishlistControls/ListItem/EditNameForm";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import {
  OfferData,
  copyListToCart,
  createOffer,
  deleteList,
  offerProduct,
  selectListId,
  selectListName,
  selectProducts,
} from "../../../../Store/Slices/wishlist/wishlistSlice";
import { ProductWishlist } from "../../../../Models/Wishlist";
import {
  ExtraProductOffer,
  selectClientAddress,
  selectClientCity,
  selectClientDataWaznosci,
  selectClientDataZlecenia,
  selectClientNIP,
  selectClientName,
  selectClientPlatnosc,
  selectClientTworca,
  selectClientZamawiajacy,
  selectExtraProducts,
} from "../../../../Store/Slices/admin/Offers/Offers";

function WishlistInfoButtons() {
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const name = useSelector(selectListName);
  const id = useSelector(selectListId);
  const products: ProductWishlist[] = useSelector(selectProducts);
  const extraProducts: ExtraProductOffer[] = useSelector(selectExtraProducts);
  const dispatch = useDispatch<AppDispatch>();

  const clientName = useSelector(selectClientName);
  const clientNIP = useSelector(selectClientNIP);
  const clientAddress = useSelector(selectClientAddress);
  const clientCity = useSelector(selectClientCity);
  const clientZamawiajacy = useSelector(selectClientZamawiajacy);
  const dataZlecenia = useSelector(selectClientDataZlecenia);
  const dataWaznosci = useSelector(selectClientDataWaznosci);
  const platnosc = useSelector(selectClientPlatnosc);
  const tworca = useSelector(selectClientTworca);

  return (
    <div
      className={`flex items-center justify-between flex-wrap text-neutral-50`}
    >
      <div
        className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-red-700"
        onClick={() => {
          dispatch(copyListToCart(id));
        }}
      >
        <span className="text-sm md:text-base whitespace-nowrap">
          Dodaj do koszyka
        </span>
        <span className="material-symbols-outlined">add_shopping_cart</span>
      </div>
      {/* <div className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-red-700" onClick={()=>{
        var offerProducts:offerProduct[] = [];
        for(const element of products){
          offerProducts.push({
            Id: element.Id,
            Name: element.Name,
            Catalog_name: element.Catalog_name??"",
            Quantity: element.Quantity??1,
            Price: element.SpecialPrice??element.Price,
            Discount: element.Discount??0,
            DisplayDiscount: true,
            Image_url: element.Image_url??'',
          })
        }
        for(const element of extraProducts){
          offerProducts.push({
            Id: -1,
            Name: element.Name,
            Catalog_name: element.Catalog_name??"",
            Quantity: element.Quantity??1,
            Price: element.Price,
            Discount: element.Discount??0,
            DisplayDiscount: true,
            Image_url: element.Image_url??'',
          })
        }
        dispatch(createOffer({products: offerProducts, data: {
          Name: clientName,
          NIP: clientNIP,
          Address: clientAddress,
          City: clientCity,
          Zamawiajacy: clientZamawiajacy,
          DataZlecenia: dataZlecenia,
          DataWaznosci: dataWaznosci,
          Tworca: tworca,
          Platnosc: platnosc,
        }}));
      }}>
        <span className="text-sm md:text-base">Zrób oferte</span>
        <span className="material-symbols-outlined">add_shopping_cart</span>
      </div> */}
      <div
        className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-red-700"
        onClick={() => {
          setShowEdit(true);
        }}
      >
        <span className="text-sm md:text-base whitespace-nowrap">
          Zmień nazwę
        </span>
        <span className="material-symbols-outlined">edit</span>
      </div>
      <div
        className="flex items-center space-x-1 rounded-lg cursor-pointer p-1 hover:bg-red-700"
        onClick={() => {
          setShowDeleteConfirm(true);
        }}
      >
        <span className="text-sm md:text-base whitespace-nowrap">
          Usuń listę
        </span>
        <span className="material-symbols-outlined">delete</span>
      </div>
      <AnimatePresence mode="wait">
        {showDeleteConfirm && (
          <Confirm
            handleClose={() => {
              setShowDeleteConfirm(false);
            }}
            handleConfirm={() => {
              dispatch(deleteList(id));
              setShowDeleteConfirm(false);
            }}
            text={"Czy napewno chcesz usunąć listę?"}
          />
        )}
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {showEdit && (
          <EditNameForm
            setShowEdit={setShowEdit}
            name={name ?? ""}
            id={id ?? -1}
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export default WishlistInfoButtons;
