import React from "react";
import { Link, useNavigate } from "react-router-dom";
import StarRating from "../StarRating/StarRating";
import { ProductDisplay } from "../../Models/Product";
import ProductInfo from "./ProductInfo/ProductInfo";
import ProductControls from "./ProductControls/ProductControls";

type Props = {
  product: ProductDisplay;
  showAddToCart?: boolean;
  showRemoveFromCart?: boolean;
  showCartControls?: boolean;
  isFirstChild?: boolean;
  showRating?: boolean;
  flag?: "none" | "new" | "sale";
  shrink?: boolean;
};

function ProductCard({
  product,
  showAddToCart = true,
  showRemoveFromCart = false,
  showCartControls = false,
  isFirstChild = false,
  showRating = false,
  flag = "none",
  shrink = true,
}: Props) {
  return (
    <div
      className={`${isFirstChild ? "ml-2 mt-2" : ""} ${
        shrink ? "shrink" : "shrink-0"
      } shadow-md hover:shadow-lg flex flex-col justify-end snap-center relative z-10 overflow-hidden rounded-lg h-80 w-60 flex-grow transition-all`}
    >
      {flag !== "none" && (
        <div
          className={`${
            flag === "new" ? "bg-green-500" : "bg-red-500"
          } drop-shadow-lg flex items-center space-x-1 absolute top-2 left-2 z-10 text-white text-lg font-bold px-2 rounded-md`}
        >
          <span>{flag === "new" ? "Nowy" : "Promocja"}</span>
          <span className="material-symbols-outlined text-xl">
            {flag === "new" ? "fiber_new" : "local_fire_department"}
          </span>
        </div>
      )}

      <div
        className="w-full h-1/2 z-0 bg-white"
        style={{
          position: "relative",
          backgroundImage: `url(${
            product.Image_url !== ""
              ? product.Image_url
              : "/Images/no_photography_FILL0_wght400_GRAD0_opsz24.png"
          })`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      ></div>
      <Link
        className="w-full h-1/2 p-4 flex flex-col justify-between "
        to={`/product/${product.Catalog_name?.replaceAll("/", "*1*")}`}
      >
        <ProductInfo product={product} />
      </Link>
      <div className="absolute top-0 p-2 justify-between flex items-start w-full z-10">
        {showRating && <StarRating rating={product.Rating} />}
        <div className="flex items-center space-x-1 rounded-full bg-white bg-opacity-40 backdrop-blur-sm">
          <h1 className="block whitespace-nowrap text-sm sm:text-base font-bold select-none cursor-default">
            {(product.Stock_quantity ?? 0) > 0 ? "Dostępny" : "Na zamówienie"}
          </h1>
          <div
            className={`w-4 h-4 rounded-full animate-pulse bg-${
              product.Stock_quantity !== undefined
                ? (product.Stock_quantity ?? 0) > 0
                  ? "green"
                  : "orange"
                : "transparent"
            }-600`}
          ></div>
        </div>
        <ProductControls product={product} />
      </div>
    </div>
  );
}

export default ProductCard;
