import React, { useState } from "react";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";

function AirflowCalculator() {
  const [srednica, setSrednica] = useState("");
  const [dlEfektywna, setDlEfektywna] = useState("");
  const [spadekCisn, setSpadekCisn] = useState("");
  const [cisnienie, setCisnienie] = useState("");
  const [result, setResult] = useState("");

  const calculateFlow = () => {
    const cisnAtm = 1.013;
    const przeplyw =
      Math.pow(
        ((Math.pow(parseFloat(srednica) / 1000, 5) *
          parseFloat(spadekCisn) *
          (parseFloat(cisnienie) + cisnAtm)) /
          1.6 /
          parseFloat(dlEfektywna)) *
          Math.pow(10, 6),
        0.5411
      ) *
      60 *
      60;
    setResult(`Wynik: ${przeplyw.toFixed(1).toString()} m3/h`);
  };

  return (
    <div className="w-full flex flex-col items-start justify-center space-y-2">
      <h2 className="text-2xl font-bold text-center whitespace-nowrap">
        Przepływ powietrza
      </h2>

      <div className="mainDivCalculator flex items-center flex-wrap">
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Średnica rurociągu(mm)</span>
          <input
            type="number"
            value={srednica}
            onChange={(e) => setSrednica(e.target.value)}
            placeholder="Średnica rury (mm)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Długość efektywana (m)</span>
          <input
            type="number"
            value={dlEfektywna}
            onChange={(e) => setDlEfektywna(e.target.value)}
            placeholder="Długość efektywna (m)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Spadek ciśnienia (bar)</span>
          <input
            type="number"
            value={spadekCisn}
            onChange={(e) => setSpadekCisn(e.target.value)}
            placeholder="Spadek ciśnienia (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Ciśnienie sprężarki</span>
          <input
            type="number"
            value={cisnienie}
            onChange={(e) => setCisnienie(e.target.value)}
            placeholder="Ciśnienie sprężarki (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <ButtonNeutral50
          text={"Zatwierdź"}
          size={"medium"}
          onClick={calculateFlow}
          icon={"check"}
        />
        <h2 className="text-2xl font-bold text-center whitespace-nowrap">
          {result}
        </h2>
      </div>
    </div>
  );
}

export default AirflowCalculator;
