import React from "react";
import Cookies from "js-cookie";
import ButtonGrey from "../../../../Common/Buttons/ButtonGrey";
import { Route, Routes, useNavigate } from "react-router-dom";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";
import EditPersonalData from "./EditPersonalData/EditPersonalData";
import EditCompanyData from "./EditCompanyData/EditCompanyData";

function PersonalData() {
  const user = JSON.parse(Cookies.get("user") ?? "{}") as {
    Name: string;
    Surname: string;
    Email: string;
    Phone_number: string;
  };

  const company = JSON.parse(Cookies.get("company") ?? "{}") as {
    Name: string;
    Address: string;
    PostalCode: string;
    City: string;
    NIP: string;
  };

  const navigate = useNavigate();

  return (
    <Routes>
      <Route
        path="/"
        element={
          <div className="flex flex-col space-y-3">
            <h1 className="text-2xl font-semibold">Twoje dane</h1>
            <div className="w-full rounded-lg p-3 border-2 space-y-2 items-center justify-center">
              <div className="relative rounded-lg p-3 w-full flex flex-col">
                <div className="w-full flex items-center justify-between mb-2">
                  <h1 className="text-xl font-semibold flex items-center space-x-1">
                    <span>Dane personalne</span>
                  </h1>
                  <div className="flex space-x-1 items-center">
                    <ButtonGrey
                      text={""}
                      size={"small"}
                      icon={"edit"}
                      onClick={() => {
                        navigate(`/profile/personal-data/edit`);
                      }}
                    />
                  </div>
                </div>

                <div className="text-base flex items-center space-x-2">
                  <div className="flex items-center space-x-1 font-semibold">
                    <span>Imię: </span>
                  </div>
                  <span>{user.Name.split(" ")[0]}</span>
                </div>
                <div className="text-base flex items-center space-x-2">
                  <div className="flex items-center space-x-1 font-semibold">
                    <span>Nazwisko: </span>
                  </div>
                  <span>{user.Name.split(" ")[1]}</span>
                </div>
                <div className="text-base flex items-center space-x-2">
                  <div className="flex items-center space-x-1 font-semibold">
                    <span>Email: </span>
                  </div>
                  <span>{user.Email}</span>
                </div>
                <div className="text-base flex items-center space-x-2">
                  <div className="flex items-center space-x-1 font-semibold">
                    <span>Telefon: </span>
                  </div>
                  <span>{user.Phone_number}</span>
                </div>
              </div>
            </div>
            <div className="w-full rounded-lg p-3 border-2 space-y-2 items-center justify-center">
              <div className="relative rounded-lg p-3 w-full flex flex-col">
                <div className="w-full flex items-center justify-between mb-2">
                  <h1 className="text-xl font-semibold flex items-center space-x-1">
                    <span>Dane firmy</span>
                  </h1>
                  <div className="flex space-x-1 items-center">
                    <ButtonGrey
                      text={""}
                      size={"small"}
                      icon={"edit"}
                      onClick={() => {
                        navigate(`/profile/personal-data/edit-company`);
                      }}
                    />
                  </div>
                </div>

                <div className="text-base flex items-center space-x-2">
                  <div className="flex items-center space-x-1 font-semibold">
                    <span>Nazwa firmy: </span>
                  </div>
                  <span>{company.Name}</span>
                </div>
                <div className="text-base flex items-center space-x-2">
                  <div className="flex items-center space-x-1 font-semibold">
                    <span>Adres Firmy: </span>
                  </div>
                  <span>{company.Address}</span>
                </div>
                <div className="text-base flex items-center space-x-2">
                  <div className="flex items-center space-x-1 font-semibold">
                    <span>Kod pocztowy: </span>
                  </div>
                  <span>{company.PostalCode}</span>
                </div>
                <div className="text-base flex items-center space-x-2">
                  <div className="flex items-center space-x-1 font-semibold">
                    <span>NIP: </span>
                  </div>
                  <span>{company.NIP}</span>
                </div>
              </div>
            </div>
            <ButtonNeutral50
              text={"Zresetuj hasło"}
              size={"medium"}
              customClasses="w-full"
              icon={"lock"}
              onClick={() => {
                navigate(`/reset-password`);
              }}
            />
          </div>
        }
      />
      <Route path="/edit-company" element={<EditCompanyData  />} />
      <Route path="/edit" element={<EditPersonalData />} />
    </Routes>
  );
}

export default PersonalData;
