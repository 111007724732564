import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getUnAcceptedUsers, getUsers, selectAdminUsersState, selectUsers } from '../../../../Store/Slices/admin/Users/Users';
import { AppDispatch } from '../../../../Store/store';
import { UserAdminDisplay } from '../../../../Models/User';

type Props = {}

function Users({}: Props) {

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getUsers({}));
  }, [dispatch]);

  const users: UserAdminDisplay[] = useSelector(selectUsers);
  const { isLoading, failedLoading, hasLoaded, error } =
    useSelector(selectAdminUsersState);

  return (
    <div>
      <p>Users</p>
      <p>{JSON.stringify(isLoading)}</p>
      <p>
        {
          users.map(element=>{
            return <p><Link to={"/admin/users/"+element.Id}><button>{element.Name}</button></Link></p>
          })
        }
      </p>
    </div>
    
  )
}

export default Users