import { motion } from "framer-motion";
import React, { useEffect } from "react";
import ButtonNeutral50 from "../../Common/Buttons/ButtonNeutral50";
import Links from "../../Common/Links/Links";

export default function About() {

  useEffect(() => {
    document.title = "Press Bar Pneumatyka - O nas";
  }, []);

  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full space-y-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]">
        <Links />
      </div>
      <div
        className="w-full my-10"
        style={{
          backgroundImage: `url(https://pressbarimages.b-cdn.net/strona-glowna/2dbcebc7-2ad5-4362-b7e3-bf1dcb560126-2-1-scaled.webp)`,
          backgroundSize: "cover",
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
        >
          <h1 className="max-w-[1024px] mt-10 sm:text-6xl text-5xl font-display text-center text-white mb-2">
            Leave the pneumatic to us
          </h1>
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="text-md sm:text-lg max-w-[1024px] space-y-5">
          <p>Jesteśmy dostawcą produktów czołowych światowych marek.</p>
          <p>
            Większość asortymentu oferowanego przez naszą firmę dostępna jest w
            ciągu 24h. Chcemy zaproponować Państwu nowoczesne produkty i szeroki
            wachlarz usług związanych z naprawą, modyfikacją oraz budową nowych
            maszyn i urządzeń z dziedziny automatyki przemysłowej. Przede
            wszystkim pneumatyki, ale również elektryki i elektroniki.
          </p>
          <p>
            Celem naszej działalności jest kompleksowe zaopatrzenie przemysłu w
            elementy i układy pneumatyki siłowej.
          </p>
        </div>
      </motion.div>
      <div
        className="w-full my-10"
        style={{
          backgroundImage: `url(https://pressbarimages.b-cdn.net/strona-glowna/4da95ba8-48e4-4bc3-a9a0-8568cb3d0ab1-2-scaled.webp)`,
          backgroundSize: "cover",
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
        >
          <h1 className="max-w-[1024px] mt-10 sm:text-6xl text-5xl font-display text-center text-white mb-2">
            Sprawdź oferowane przez nas Usługi
          </h1>
          <ButtonNeutral50
            text={"Usługi"}
            size={"large"}
            icon={"build"}
            link={"/services"}
          />
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="text-md sm:text-lg max-w-[1024px] space-y-5">
          <p>
            Oprócz kompleksowych remontów linii i maszyn zbudowanych w oparciu o
            pneumatykę siłową zajmujemy się również prowadzeniem procesów
            restrukturyzacji technicznej gospodarki sprężonym powietrzem w celu
            uzyskania maksymalnych oszczędności energetycznych, zgodnie z
            filozofią przedsiębiorstwa ESCO – Energy Saving Company (audyty
            energetyczne, szkolenie załogi, zarządzanie stacjami sprężarek –
            outsorcing, serwis całodobowy, e-monitoring instalacji oraz całych
            sprężarkowni.
          </p>
          <p>
            Możemy zaproponować Państwu nowoczesne rozwiązania nie tylko w
            zakresie pneumatyki siłowej ale również w wielu dziedzinach
            technicznych związanych z utrzymaniem ruchu.
          </p>
          <p>
            Przez ponad 20 lat zdobyliśmy zaufanie wielu klientów, którzy cenią
            nas za profesjonalizm i dobrą jakość oferowanych produktów.
            Proponujemy najlepiej dopasowane do Klienta rozwiązania techniczne,
            które wpływają na obniżenie kosztów produkcji oraz zwiększenie jej
            wydajności.
          </p>
        </div>
      </motion.div>
      <div
        className="w-full my-10"
        style={{
          backgroundImage: `url(https://pressbarimages.b-cdn.net/strona-glowna/8be64e9a-17df-477e-bfc9-2a7aad90aee9-2-scaled.webp)`,
          backgroundSize: "cover",
        }}
      >
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          className="flex flex-col items-center backdrop-blur-sm backdrop-brightness-50 w-full h-full p-5 lg:py-12"
        >
          <h1 className="max-w-[1024px] mt-10 sm:text-6xl text-5xl font-display text-center text-white mb-2">
            Skontaktuj się z nami
          </h1>
          <ButtonNeutral50
            text={"Kontakt"}
            size={"large"}
            icon={"contact_support"}
            link={"/contact"}
          />
        </motion.div>
      </div>
      <motion.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
        className="w-full px-2 lg:px-5 flex justify-center items-center text-justify max-w-[1536px]"
      >
        <div className="text-md sm:text-lg max-w-[1024px] space-y-5">
          <p>
            Zapraszamy serdecznie do odwiedzenia naszej częstochowskiej placówki
            lub kontakt telefoniczny w celu omówienia spotkania z naszym doradcą
            technicznym, posiadającym ponad dwudziestoletnie doświadczenie w
            branży pneumatyki przemysłowej.
          </p>
          <p>
            Do Państwa dyspozycji będzie zarówno pełna oferta naszych produktów,
            jak i fachowe doradztwo i bezpłatny parking.
          </p>
        </div>
      </motion.div>
    </motion.div>
  );
}
