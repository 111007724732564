import React, { useEffect } from "react";
import DropDownList from "../../../../Common/DropDownList/DropDownList";
import { useSearchParams } from "react-router-dom";

function DisplayButton() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showDropDown, setShowDropDown] = React.useState(false);
  const [clickOutside, setClickOutside] = React.useState(false);

  useEffect(() => {
    const closeDropDown = () => {
      if (showDropDown) {
        setShowDropDown(false);
      }
    };

    if (clickOutside) {
      document.addEventListener("click", closeDropDown);
    } else {
      document.removeEventListener("click", closeDropDown);
    }
    return () => {
      document.removeEventListener("click", closeDropDown);
    };
  }, [clickOutside, showDropDown]);

  return (
    <div
      className={`${
        showDropDown ? "bg-neutral-100" : ""
      } w-full relative flex flex-col items-center justify-center cursor-pointer p-2 rounded-lg hover:bg-neutral-100`}
      onClick={(e) => {
        e.stopPropagation();
        setShowDropDown(!showDropDown);
        setClickOutside(true);
      }}
    >
      <span className="material-symbols-outlined">view_list</span>
      <span>Układ</span>
      {showDropDown && (
        <DropDownList
          options={[
            { name: "Grid", value: "grid", icon: "view_module" },
            { name: "List", value: "list", icon: "view_list" },
          ]}
          selectedOption={searchParams.get("display") || "list"}
          onClick={(option) => {
            setSearchParams((prev) => {
              prev.set("display", option);
              return prev;
            });
            setShowDropDown(false);
          }}
        />
      )}
    </div>
  );
}

export default DisplayButton;
