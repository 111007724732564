import ProductCard from "../../../Common/ProductCard/ProductCard";
import { SimiliarProductsType } from "../../../Models/Product";

export default function LandingPageProducts({landingPageProducts}: {landingPageProducts: SimiliarProductsType[]}){
    return (
        <div className="w-full h-fit">
          {true && (
            <div className="">
              <div className="w-full h-fit rounded-lg shadow-md bg-neutral-100 p-2 mb-8 overflow-x-hidden">
                {Array.isArray(landingPageProducts) &&
                  landingPageProducts.length > 0 &&
                  landingPageProducts.map(
                    (type) =>
                      type.products.length > 0 && (
                        <div key={type.label} className="w-full">
                          <span className="text-lg font-bold text-neutral-950 border-b-2 border-primary">
                            {type.label}
                          </span>
                          <div className="w-auto flex shrink-0 space-x-2 overflow-x-scroll flex-nowrap py-3 snap-start snap-mandatory snap-always">
                            {Array.isArray(type.products) &&
                              type.products.length > 0 &&
                              type.products.map((product) => (
                                <ProductCard
                                  key={product.Id}
                                  product={product}
                                  shrink={false}
                                />
                              ))}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
          )}
        </div>
    );

}