import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { server } from "../../../../server";
import { StateType } from "../../../../Models/State";
import { ProductDisplay } from "../../../../Models/Product";
import {
  OrderType,
  OrderFromDb,
  OrderWithProducts,
  OrderModel,
} from "../../../../Models/Order";

type adminOrdersSliceState = {
  orders: {
    ordersList: OrderModel[];
    numberOfOrders: number;
    state: StateType;
  };
  order: {
    order: OrderWithProducts;
    state: StateType;
  };
};

type ProductOperation = {
  name: string;
  product_id: string;
  newValueQuantity?: string;
  newValueAmount?: string;
};

export const getRecentOrders = createAsyncThunk(
  "adminOrders/fetchOrders",
  async () => {
    try {
      const response = await axios.get<OrderModel[]>(
        `${server}/api/Orders/last`,
        { withCredentials: true }
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const getAdminOrderDetails = createAsyncThunk(
  "adminOrders/fetchOrder",
  async (id: string) => {
    try {
      const response = await axios.get<OrderWithProducts>(
        `${server}/api/Orders/get/${id}`
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }
);

export const setOrderAsAccepted = createAsyncThunk(
  "adminOrders/setOrderAsAccepted",
  async ({ id, sendData }: { id: string; sendData: string }) => {
    try {
      const response = await axios.post(
        `${server}/api/Orders/accept/${id}`,
        { sendData: sendData },
        { withCredentials: true }
      );
      return id;
    } catch (e) {
      throw e;
    }
  }
);

export const setOrderAsFinished = createAsyncThunk(
  "adminOrders/setOrderAsFinished",
  async (id: string) => {
    try {
      const response = await axios.post(
        `${server}/api/Orders/finish/${id}`,
        {},
        { withCredentials: true }
      );
      return id;
    } catch (e) {
      throw e;
    }
  }
);

export const setOrderAsReadyToPickUp = createAsyncThunk(
  "adminOrders/setOrderAsReadyToPickUp",
  async ({ id, dpdCode }: { id: string; dpdCode?: string }) => {
    try {
      const response = await axios.post(
        `${server}/api/Orders/pickup/ready/${id}`,
        { dpdCode: dpdCode == "" ? undefined : dpdCode },
        { withCredentials: true }
      );
      return id;
    } catch (e) {
      throw e;
    }
  }
);

const adminOrdersSlice = createSlice({
  name: "adminOrders",
  initialState: {
    orders: {
      ordersList: [],
      numberOfOrders: 0,
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    order: {
      order: {},
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
  } as unknown as adminOrdersSliceState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getRecentOrders.pending, (state, action) => {
      state.orders.state.isLoading = true;
      state.orders.state.failedLoading = false;
      state.orders.state.hasLoaded = false;
      state.orders.state.error = null;
    });
    builder.addCase(getRecentOrders.fulfilled, (state, action) => {
      state.orders.state.isLoading = false;
      state.orders.state.failedLoading = false;
      state.orders.state.hasLoaded = true;
      state.orders.ordersList = action.payload;
      state.orders.numberOfOrders = action.payload.length;
    });
    builder.addCase(getRecentOrders.rejected, (state, action) => {
      state.orders.state.isLoading = false;
      state.orders.state.failedLoading = true;
      state.orders.state.hasLoaded = false;
      state.orders.state.error = action.error;
    });
    builder.addCase(getAdminOrderDetails.pending, (state, action) => {
      state.order.state.isLoading = true;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = false;
      state.order.state.error = null;
    });
    builder.addCase(getAdminOrderDetails.fulfilled, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = true;
      state.order.order = action.payload;
    });
    builder.addCase(getAdminOrderDetails.rejected, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = true;
      state.order.state.hasLoaded = false;
      state.order.state.error = action.error;
    });
    builder.addCase(setOrderAsAccepted.pending, (state, action) => {
      state.order.state.isLoading = true;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = false;
      state.order.state.error = null;
    });
    builder.addCase(setOrderAsAccepted.fulfilled, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = true;
      state.order.order.order.Status_id = 4;
    });
    builder.addCase(setOrderAsAccepted.rejected, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = true;
      state.order.state.hasLoaded = false;
      state.order.state.error = action.error;
    });
    builder.addCase(setOrderAsReadyToPickUp.pending, (state, action) => {
      state.order.state.isLoading = true;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = false;
      state.order.state.error = null;
    });
    builder.addCase(setOrderAsReadyToPickUp.fulfilled, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = true;
      if (parseInt(state.order.order.order.Delivery_type?.toString()) === 4) {
        state.order.order.order.Status_id = 5;
      } else {
        state.order.order.order.Status_id = 6;
      }
    });
    builder.addCase(setOrderAsReadyToPickUp.rejected, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = true;
      state.order.state.hasLoaded = false;
      state.order.state.error = action.error;
    });
    builder.addCase(setOrderAsFinished.pending, (state, action) => {
      state.order.state.isLoading = true;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = false;
      state.order.state.error = null;
    });
    builder.addCase(setOrderAsFinished.fulfilled, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = false;
      state.order.state.hasLoaded = true;
      state.order.order.order.Status_id = 7;
    });
    builder.addCase(setOrderAsFinished.rejected, (state, action) => {
      state.order.state.isLoading = false;
      state.order.state.failedLoading = true;
      state.order.state.hasLoaded = false;
      state.order.state.error = action.error;
    });
  },
});

export const selectAdminOrders = (state: {
  adminOrders: adminOrdersSliceState;
}): OrderModel[] => {
  return state.adminOrders.orders.ordersList;
};

export const selectAdminNumberOfOrders = (state: {
  adminOrders: adminOrdersSliceState;
}): number => state.adminOrders.orders.numberOfOrders;

export const selectAdminOrder = (state: {
  adminOrders: adminOrdersSliceState;
}): OrderWithProducts => state.adminOrders.order.order;

export const selectRecentOrdersState = (state: {
  adminOrders: adminOrdersSliceState;
}): StateType => state.adminOrders.orders.state;

export const selectAdminOrderState = (state: {
  adminOrders: adminOrdersSliceState;
}): StateType => state.adminOrders.order.state;

export default adminOrdersSlice.reducer;
