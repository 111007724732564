import { useDispatch } from "react-redux";
import {motion} from 'framer-motion';
import { useState } from "react";
import { AppDispatch } from "../../../../Store/store";
import { changeListName } from "../../../../Store/Slices/wishlist/wishlistSlice";

function EditNameForm({setShowEdit, name, id}: {setShowEdit:React.Dispatch<React.SetStateAction<boolean>>, name: string, id: number} ) {

    const [newName, setNewName] = useState('');
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div>
            {(
                <motion.div
                    className="backdrop-blur-md fixed top-0 left-0 w-full h-full flex justify-center items-center z-50"
                    onClick={() => setShowEdit(false)}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div
                    className="space-x-2 flex justify-center rounded-lg items-center p-2 bg-neutral-200"
                    onClick={(e) => {
                        e.stopPropagation();
                    }}
                    >
                    {/*<Input label={""} type={"text"} name={""} placeholder={name} />*/}
                    <div className="w-full">
                        <label className="text-xl text-neutral-950 whitespace-nowrap" htmlFor={""}>
                        {name}
                        </label>
                        <input
                        id={""}
                        type={"text"}      
                        placeholder={name}
                        className={
                            "p-3 appearance-none block w-full text-base text-neutral-950 border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 transition-all"
                        }
                        onChange={e=>{
                            setNewName(e.target.value);
                        }}
                        />
                    </div>
                    <div className="flex justify-end space-x-1">
                        <button
                        className="rounded-lg p-2 hover:bg-neutral-300"
                        onClick={() => setShowEdit(false)}
                        >
                        <span className="material-symbols-outlined text-xl">
                            close
                        </span>
                        </button>
                        <button className="rounded-lg p-2 bg-red-500 hover:bg-red-600" onClick={()=>{
                        dispatch(changeListName({id: id??-1, name: newName??''} ))
                        }}>
                        <span className="material-symbols-outlined text-xl">
                            save
                        </span>
                        </button>
                    </div>
                    </div>
                </motion.div>
            )}
        </div>
    )
}

export default EditNameForm;
