import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";

function NotFound() {
  useEffect(() => {
    document.title = "Nie znaleziono strony - Press Bar Pneumatyka";
  }, []);
  return (
    <motion.div
      className="flex flex-col items-center justify-center px-2 sm:px-5 space-y-3 min-h-[60vh]"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <span className="material-symbols-outlined text-6xl sm:text-9xl">error</span>
      <h2 className="font-semibold text-xl sm:text-3xl">Nie znaleziono strony</h2>
      <motion.button
        className="relative group flex items-center justify-center p-2 sm:p-4 font-semibold border-2 border-neutral-950"
        onClick={() => {
          window.location.href = `/`;
        }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.95 }}
      >
        <span>Powrót do strony głownej</span>
      </motion.button>
    </motion.div>
  );
}

export default NotFound;
