import { motion } from "framer-motion";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { AppDispatch } from "../../../Store/store";
import Input from "../../../Common/Input/Input";
import ButtonPrimaryToLighter from "../../../Common/Buttons/ButtonPrimaryToLighter";
import {
  resetPassword,
  selectResetPasswordState,
} from "../../../Store/Slices/User/User";
import { checkPasswordStrength } from "../../Register/RegisterForm/RegisterForm";
import LoadingAnimation from "../../../Common/LoadingAnimation/LoadingAnimation";
import ErrorMessage from "../../../Common/Messages/ErrorMessage/ErrorMessage";
import InfoMessage from "../../../Common/Messages/InfoMessage/InfoMessage";

function Reset() {
  const { token } = useParams();

  const dispatch = useDispatch<AppDispatch>();
  const state = useSelector(selectResetPasswordState);

  const navigate = useNavigate();

  return (
    <motion.div
      initial={{ opacity: 0, x: 100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.5 }}
      className="w-full max-w-[1536px] px-2 sm:px-5 my-20 flex flex-col items-center justify-center space-y-6"
    >
      {state.isLoading && <LoadingAnimation type="global" color="primary" />}
      <div className="flex space-x-2 items-center w-full group cursor-pointer">
        <span className="material-symbols-outlined text-2xl">chevron_left</span>
        <Link to="/login" className="group-hover:underline">
          Powrót do logowania
        </Link>
      </div>
      {state.failedLoading && (
        <div className="flex flex-col items-center justify-center">
          <ErrorMessage
            error={state.error}
            message="Błąd podczas zmiany hasła."
          />
        </div>
      )}
      {!state.hasLoaded && (
        <div className="flex flex-col items-center justify-center">
          <h1 className="text-xl sm:text-5xl font-semibold flex items-center space-x-3">
            <span>Podaj nowe hasło</span>
            <span className="material-symbols-outlined text-xl sm:text-4xl">
              key
            </span>
          </h1>
          {/* <p className="text-sm sm:text-base font-black my-1 text-center">
        Wprowadź adres email, na który wyślemy link do resetowania hasła
      </p> */}
          <form
            id="reset-password-form"
            className="max-w-[600px] w-full rounded-lg space-y-2"
            onSubmit={(e) => {
              e.preventDefault();
              const formData = new FormData(e.currentTarget);
              const password = formData.get("password") as string;
              const confirmPassword = formData.get(
                "confirm-password"
              ) as string;
              if (!checkPasswordStrength(password, confirmPassword)) {
                alert("Hasło jest niepoprawne");
                return;
              }
              if (token) {
                dispatch(resetPassword({ password, token }));
              } else {
                alert("Token nie został znaleziony");
                return;
              }
            }}
          >
            <div className="relative rounded-lg p-3 w-full flex flex-col space-y-2 items-center justify-center">
              <Input
                label="password"
                type="password"
                name="Hasło"
                placeholder="********"
                required={true}
              />
              <Input
                label="confirm-password"
                type="password"
                name="Potwierdź hasło"
                placeholder="********"
                required={true}
              />
              <ButtonPrimaryToLighter
                text={"Zresetuj hasło"}
                size={"medium"}
                customClasses="w-full"
                icon={"lock"}
                isSubmit={true}
                form="reset-password-form"
              />
            </div>
          </form>
        </div>
      )}
      {state.hasLoaded && (
        <div className="flex flex-col items-center justify-center">
          <InfoMessage icon={"check_circle"} message={"Hasło zmienione"} />
        </div>
      )}
      {/* <h1 className="text-lg font-black my-1 font-mono text-center">
        Nie masz jeszcze konta?{" "}
        <Link to="/register" className="underline hover:no-underline">
          Zarejestruj się
        </Link>
      </h1> */}
    </motion.div>
  );
}

export default Reset;
