import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AnimatePresence, motion } from "framer-motion";
import { AppDispatch } from "../../Store/store";
import {
  fetchCategories,
  fetchCategoriesHierarchy,
  fetchCategoriesWithChildren,
  selectAllCategories,
  selectAllCategoriesMap,
  selectCategories,
  selectCategoriesState,
  selectCurrentCategoryId,
  selectParentCategory,
  setCurrentCategoryId,
} from "../../Store/Slices/Categories/Categories";
import Category from "./Category/Category";
import { useLocation, useSearchParams } from "react-router-dom";
import { CategoryModel } from "../../Models/Category";
import LoadingAnimation from "../LoadingAnimation/LoadingAnimation";
import { removeAllParameterChoices } from "../../Store/Slices/Parameters/Parameters";

function Categories({admin}:{admin?: boolean}) {
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  const [searchParams, setSearchParams] = useSearchParams({
    category: "1",
  });

  let parentCategory: CategoryModel = useSelector(selectParentCategory);
  const { isLoading, failedLoading, hasLoaded, error } = useSelector(
    selectCategoriesState
  );

  const currentCategoryId = useSelector(selectCurrentCategoryId);
  const allCategoriesMap = useSelector(selectAllCategoriesMap);
  const allCategories = useSelector(selectAllCategories);
  const currentCategory = useSelector(selectParentCategory);
  const categories = useSelector(selectCategories);

  useEffect(() => {
    dispatch(fetchCategoriesWithChildren({id: currentCategoryId, allCategoriesMap, currentCategory: allCategories.filter(element=>{
      if(element.Id.toString()==currentCategoryId){
        return true;
      }
      return false;
    })[0]??currentCategory}));
    if(['1','2','7','11','147','177','216','225','226','227','228','229','230']
      .includes(currentCategoryId)){
        setSearchParams((prev) => {
          dispatch(removeAllParameterChoices());
          prev.delete("filters");
          prev.delete("price_min");
          prev.delete("price_max");
          return prev;
        });
      }
    //dispatch(fetchCategoriesHierarchy(currentCategoryId));
  }, [currentCategoryId]);

  useEffect(() => {
    const newCategoryId = searchParams.get("category");
    if (newCategoryId !== currentCategoryId.toString()) {
      if(newCategoryId===undefined||newCategoryId===null){
        dispatch(setCurrentCategoryId(1));
      }
      else{
        dispatch(setCurrentCategoryId(searchParams.get("category")));
      }
            
    }
  }, [searchParams.get("category")]);

  return (
    <div className="relative px-1 py-2 overflow-x-hidden h-full overflow-y-scroll w-full">
      {isLoading && <LoadingAnimation color="primary" />}
      {isLoading && <div className="w-full h-32"></div>}
      {parentCategory !== undefined && parentCategory?.Id !== 1 && (
        <div
          className="py-1 flex items-center cursor-pointer"
          onClick={() => {
            dispatch(setCurrentCategoryId(parentCategory.Parent_Category));
            if (location.pathname.startsWith("/search")||(admin&&location.pathname.startsWith('/admin/products'))) {
              setSearchParams((prev) => {
                prev.set("category", parentCategory.Parent_Category.toString());
                return prev;
              });
            }
          }}
        >
          <span className="material-symbols-outlined">chevron_left</span>
          <span className="text-lg font-bold">{parentCategory.Name}</span>
        </div>
      )}
      {failedLoading && (
        <div className="text-xl font-bold text-red-500">{error?.message}</div>
      )}
      <AnimatePresence mode="wait" initial={false}>
        {hasLoaded && (
          <motion.div
            className="flex flex-col space-y-1"
            initial={{ opacity: 0, x: 100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ duration: 0.3 }}
          >
            {categories.map((category) => (
              <Category key={category.Id} category={category} setCurrentCategoryId={setCurrentCategoryId} admin={admin}/>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Categories;
