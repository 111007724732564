import React, { useState } from "react";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";

function PushingForceCalculator() {
  const [srednica, setSrednica] = useState("");
  const [cisnienie, setCisnienie] = useState("");
  const [result, setResult] = useState("");

  const calculatePushingForce = () => {
    const sila =
      (((1 / 4) * Math.PI * (parseFloat(srednica) * parseFloat(srednica))) /
        10000) *
      parseFloat(cisnienie); // Adjusted formula to correct units
    setResult(`Wynik: ${sila.toFixed(4).toString()} kN`);
  };

  return (
    <div className="w-full flex flex-col items-start justify-center space-y-2">
      <h2
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: "25px",
          fontFamily: "system-ui, sans-serif",
        }}
      >
        Siła pchająca
      </h2>
      <div className="mainDivCalculator flex flex-wrap items-center ">
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Średnica tłoka(mm)</span>
          <input
            type="number"
            value={srednica}
            onChange={(e) => setSrednica(e.target.value)}
            placeholder="Średnica rury (mm)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Ciśnienie (bar)</span>
          <input
            type="number"
            value={cisnienie}
            onChange={(e) => setCisnienie(e.target.value)}
            placeholder="Ciśnienie (bar)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <ButtonNeutral50
          text={"Zatwierdź"}
          size={"medium"}
          onClick={calculatePushingForce}
          icon={"check"}
        />
        <h2 className="text-2xl font-bold text-center whitespace-nowrap">
          {result}
        </h2>
      </div>
    </div>
  );
}

export default PushingForceCalculator;
