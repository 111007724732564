import React from "react";
import { ButtonProps } from "../../Models/Button";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

function ButtonGrey({
  text,
  icon = null,
  onClick,
  size,
  isSubmit = false,
  customClasses = "",
  link = null,
}: ButtonProps) {
  const navigate = useNavigate();

  return (
    <motion.button
      className={
        `${size === "small" && "text-lg p-1"}
        ${size === "medium" && "text-xl p-2"}
        ${
          size === "large" && "text-2xl p-3"
        } flex items-center justify-center rounded-lg bg-neutral-200 hover:bg-neutral-300 space-x-1 whitespace-nowrap ` +
        customClasses
      }
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
        if (link != null) {
          navigate(link);
        } else {
          onClick && onClick();
        }
      }}
      type={isSubmit ? "submit" : "button"}
    >
      {text !== "" && (
        <span className="text-base font-bold text-neutral-700">{text}</span>
      )}
      {icon != null && (
        <span
          className={`
        ${size === "small" && "text-xl"}
        ${size === "medium" && "text-2xl"}
        ${size === "large" && "text-3xl"}
        material-symbols-outlined`}
        >
          {icon}
        </span>
      )}
    </motion.button>
  );
}

export default ButtonGrey;
