import { motion } from "framer-motion";
import { Route, Routes } from "react-router-dom";
import OrderDetailsPage from "./Order/OrderDetails";
import AdminAllOrders from "./AllOrders";

function AdminOrders() {
  return (
    <motion.div
      className="w-full max-w-[1536px] flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Routes>
        <Route path="/:id" element={<OrderDetailsPage />} />
        <Route path="/" element={<AdminAllOrders />} />
      </Routes>
    </motion.div>
  );
}

export default AdminOrders;
