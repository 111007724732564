import React from "react";
import { Link, useLocation } from "react-router-dom";

const options = [
  { name: "PRODUKTY", path: "products" },
  { name: "UŻYTKOWNICY", path: "users" },
  { name: "ZAMÓWIENIA", path: "orders" },
  { name: "KATEGORIE", path: "categories" },
  { name: "FILTRY", path: "filters" },
  { name: "CENY", path: "prices" },
  { name: "OFERTY", path: "offers" },
];

function AdminOptionsBar() {
  const location = useLocation();

  return (
    <div className="w-full max-w-[1536px] px-5 flex items-center justify-center space-x-10">
      {options.map((option) => (
        <Link
          to={option.path}
          key={option.path}
          className={`${
            location.pathname.includes(option.path)
              ? "border-primary"
              : "border-transparent"
          } cursor-pointer font-bold text-2xl border-b-2 hover:border-primary transition-all`}
        >
          {option.name}
        </Link>
      ))}
    </div>
  );
}

export default AdminOptionsBar;
