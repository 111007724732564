import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ButtonPrimaryToLighter from "../../Common/Buttons/ButtonPrimaryToLighter";
import ProductList from "../../Common/ProductList/ProductList";
import CartControls from "./CartControls/CartControls";
import CartInfo from "./CartInfo/CartInfo";
import {
  fetchCart,
  selectCartState,
  selectProducts,
  selectUpdateQuantitiesState,
} from "../../Store/Slices/Cart/Cart";
import { AppDispatch } from "../../Store/store";
import LoadingAnimation from "../../Common/LoadingAnimation/LoadingAnimation";
import { dropDown } from "../../Animations/Animations";
import { selectIsLoggedIn } from "../../Store/Slices/User/User";
import ReactDOM from "react-dom";
import { useNavigate } from "react-router-dom";

function Cart({
  updateQuantity,
}: {
  updateQuantity: ({
    newQuantity,
    product,
  }: {
    newQuantity: string;
    product: {
      Id: number;
    };
  }) => void;
}) {
  const dispatch = useDispatch<AppDispatch>();
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const navigate = useNavigate();

  const [showCartElements, setShowCartElements] = React.useState(true);

  const products = useSelector(selectProducts);

  const { isLoading, hasLoaded, failedLoading, error } =
    useSelector(selectCartState);

  const updateQuantityState = useSelector(selectUpdateQuantitiesState);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(fetchCart());
    }
  }, [dispatch, isLoggedIn]);

  useEffect(() => {
    document.title = "Koszyk - Press Bar Pneumatyka";
  }, []);

  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full px-2 sm:px-5 z-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="flex flex-col xl:flex-row xl:items-top space-y-4 xl:space-x-4 xl:space-y-0 w-full max-w-[1536px]">
        <AnimatePresence mode="wait">
          {showCartElements && (
            <motion.div
              className="flex flex-col w-full xl:w-3/4 h-fit items-center bg-neutral-100 rounded-lg shadow-md p-2 space-y-2"
              variants={dropDown}
              initial="hidden"
              animate="visible"
              exit="exit"
            >
              {isLoading && isLoggedIn && <LoadingAnimation color="primary" />}
              <CartInfo />
              {updateQuantityState.isLoading &&
                ReactDOM.createPortal(
                  <AnimatePresence mode="wait">
                    {updateQuantityState.isLoading && (
                      <motion.div
                        variants={dropDown}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        className="text-sm sm:text-xl font-semibold fixed z-50 top-10 left-2 bg-neutral-50 p-2 sm:p-4 rounded-lg shadow-lg flex space-x-2"
                      >
                        <span> Ładowanie zmian w koszyku</span>
                        <div className="flex items-center justify-center">
                          <span
                            className={`material-symbols-outlined animate-spin-slow`}
                          >
                            settings
                          </span>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>,
                  document.body
                )}
              <div className="h-96 overflow-scroll xl:h-fit xl:max-h-full space-y-2 w-full">
                {(hasLoaded || !isLoggedIn) && products.length === 0 && (
                  <div className="flex flex-col items-center justify-center w-full h-full space-y-4">
                    <span className="text-9xl text-neutral-600 material-symbols-outlined">
                      production_quantity_limits
                    </span>
                    <h1 className="text-4xl font-bold text-center">
                      Twój koszyk jest pusty
                    </h1>
                    <ButtonPrimaryToLighter
                      link={"/"}
                      text="Strona główna"
                      size="large"
                      icon={"home"}
                    />
                  </div>
                )}
                {(hasLoaded || !isLoggedIn) &&
                  products?.map((product) => (
                    <ProductList
                      key={product.product.Id}
                      product={product.product}
                      productQuantity={product.quantity}
                      showAddToCart={false}
                      showRemoveFromCart={true}
                      showCartControls={true}
                      updateQuantity={updateQuantity}
                    />
                  ))}
                {failedLoading && isLoggedIn && (
                  <div className="flex flex-col items-center justify-center w-full h-full space-y-4">
                    <span className="text-9xl text-neutral-600 material-symbols-outlined">
                      error
                    </span>
                    <h1 className="text-4xl font-bold text-center">
                      Błąd pobierania koszyka
                    </h1>
                    <h2 className="text-2xl font-semibold text-center">
                      {error?.message}
                    </h2>
                    <ButtonPrimaryToLighter
                      link={""}
                      text="Odśwież"
                      size="large"
                      icon={"refresh"}
                      onClick={() => {
                        dispatch(fetchCart());
                      }}
                    />
                  </div>
                )}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
        <AnimatePresence mode="wait">
          {showCartElements && (
            <CartControls
              setShow={() => {
                setShowCartElements(false);
              }}
            />
          )}
        </AnimatePresence>
      </div>
    </motion.div>
  );
}

export default Cart;
