import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../Store/Slices/User/User";
import OrderProcess from "./OrderProcess/OrderProcess";
import Cookies from "js-cookie";
import {
  hasCreatedOrder,
  initializeOrder,
} from "../../Store/Slices/Order/Order";
import { AppDispatch } from "../../Store/store";

function Order() {
  const dispatch = useDispatch<AppDispatch>();

  const orderCreated = useSelector(hasCreatedOrder);

  useEffect(() => {
    document.title = "Zamówienie - Press Bar Pneumatyka";
  }, []);

  return (
    <motion.div
      className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full px-2 sm:px-5 z-0"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <OrderProcess />
    </motion.div>
  );
}

export default Order;
