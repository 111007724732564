export type ImagTemp = {
  Product_group_id?: number;
  Image_url: string;
  Order_number?: number;
};

export type ProductCart = {
  product: ProductDisplay;
  quantity: number;
};

export type ProductIdQuantity = {
  Product_id: number;
  Quantity: number;
};

export const convertProductCartToProductIdQuantity = (
  productCart: ProductCart[]
): ProductIdQuantity[] => {
  let products: ProductIdQuantity[] = [];
  for (let i = 0; i < productCart.length; i++) {
    products.push({
      Product_id: productCart[i].product.Id,
      Quantity: productCart[i].quantity,
    });
  }
  return products;
};

export type Breadcrumb = {
  ID: number;
  NAME: string;
  PARENT_CATEGORY: number;
};

export type ParameterProductModel = {
  Product_id: number;
  Name: string;
  Description: string;
  Type: string;
  Category_id: number;
  Value: string;
};

export type TechnicalDataModel = {
  [key: string]: string;
};

export type ProductModel = {
  details: {
    Id: number;
    Category_id: number;
    Producer_Id: number;
    Product_Id: number;
    Name: string;
    Description: string;
    Price: number;
    Discount: number;
    Availability: number;
    Stock_quantity: number;
    Created_date: string;
    Updated_date: string;
    Rating: number;
    Number_of_reviews: number;
    Image_url: string;
    Catalog_name: string;
    Image_group_id: number;
    Producer: string;
    ProducerLogo: string;
    Price_Individual: number | null;
    Price_Discount: number | null;
    Category_Discount: number | null;
  };
  parameters: ParameterProductModel[];
  breadcrumbs: Breadcrumb[];
  images: ImagTemp[];
  technical_data: TechnicalDataModel[];
  pdfs: {
    Group_id: number;
    Name: string;
    Url: string;
  }[];
  delivery: {
    First_time: string;
    Second_time: string;
    Third_time: string;
    External_magazine: number;
  };
  descriptions: {
    Id: number;
    Description: string;
  };
};

export type ProductDisplay = {
  Availability: number;
  Catalog_name?: string;
  Price_Individual?: number | null;
  Price_Discount: number | null;
  Category_Discount: number | null;
  Category_Id?: number;
  Created_date?: string;
  Delivery_time?: string;
  Discount?: number;
  Id: number;
  Amount?: number;
  Image_url?: string;
  Name: string;
  Price: number;
  Producer?: string;
  Rating: number;
  Stock_quantity?: number;
  min_price?: string;
  max_price?: string;
  Product_price?: number;
};

export type ProductSearchResponse = {
  products: ProductDisplay[];
  count: number;
};

export type SimiliarProductsType = {
  label: string;
  products: ProductDisplay[];
};

export const productToDisplay = (product: ProductModel): ProductDisplay => {
  let productDisplay: ProductDisplay = {
    Id: product.details.Id,
    Name: product.details.Name,
    Producer: product.details.Producer,
    Price: product.details.Price,
    Rating: product.details.Rating,
    Image_url: product.details.Image_url,
    Catalog_name: product.details.Catalog_name,
    Price_Individual: product.details.Price_Individual,
    Price_Discount: product.details.Price_Discount,
    Category_Discount: product.details.Category_Discount,
    Availability: product.details.Availability,
  };

  return productDisplay;
};
