import React, { useEffect } from "react";
import { motion } from "framer-motion";
import Offer from "./Offer/Offer";
import Categories from "./Categories/Categories";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchLandingPageProducts,
  selectLandingPageProducts,
  selectLandingPageProductsState,
} from "../../Store/Slices/Products/Products";
import { AppDispatch } from "../../Store/store";
import ProductCard from "../../Common/ProductCard/ProductCard";
import LandingPageProducts from "./Offer/LandingPageProducts";
import {
  fetchLandingPageArticles,
  selectLandingPageArticles,
  selectLandingPageArticlesState,
} from "../../Store/Slices/Articles/Article";
import LandingPageArticles from "./Offer/LandingPageArticles";
import Links from "../../Common/Links/Links";
import Banner from "./Banner/Banner";

export default function LandingPage() {
  useEffect(() => {
    document.title = "Press Bar Pneumatyka";
  }, []);

  const dispatch = useDispatch<AppDispatch>();
  const landingPageProducts = useSelector(selectLandingPageProducts);
  const landingPageProductsState = useSelector(selectLandingPageProductsState);
  const landingPageArticles = useSelector(selectLandingPageArticles);
  const landingPageArticlesState = useSelector(selectLandingPageArticlesState);

  useEffect(() => {
    dispatch(fetchLandingPageProducts());
    dispatch(fetchLandingPageArticles());
  }, []);

  return (
    <div className="mb-10 relative bg-neutral-50 flex flex-col items-center w-full px-2 sm:px-5">
      <motion.div
        className="flex flex-col justify-center items-center w-full max-w-[1536px] space-y-6"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <Links />
        <Banner />
        <Categories />
        <Offer />
        <LandingPageProducts landingPageProducts={landingPageProducts} />
        <LandingPageArticles landingPageArticles={landingPageArticles} />
      </motion.div>
    </div>
  );
}
