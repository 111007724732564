import React from "react";

type Props = {
  rating: number;
};

function StarRating({ rating }: Props) {
  const starsFull = [];
  const starsEmpty = [];

  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      starsFull.push(
        <span key={i} className="material-symbols-outlined text-yellow-500 text-xl md:text-2xl">
          star
        </span>
      );
    } else {
      starsEmpty.push(
        <span key={i} className="material-symbols-outlined text-neutral-500 text-xl md:text-2xl">
          star
        </span>
      );
    }
  }

  return (
    <div className="block">
      {starsFull}
      {starsEmpty}
    </div>
  );
}

export default StarRating;
