import React from "react";
import { ProductDisplay } from "../../../Models/Product";
import AddToCartButton from "../../Modals/AddToCart/AddToCartButton";
import { addToCart } from "../../../Store/Slices/Cart/Cart";
import { parse } from "path-browserify";

type Props = {
  product: ProductDisplay;
  showCartControls?: boolean;
};

function ProductControls({ product, showCartControls = true }: Props) {
  const [quantity, setQuantity] = React.useState("1");

  return (
    <div className="flex flex-col-reverse justify-end items-end justify-self-end space-x-1 text-2xl">
      {showCartControls && (
        <div className="rounded-full bg-white bg-opacity-40 backdrop-blur-sm flex flex-row items-center justify-center space-x-1 space-y-0">
          <button
            className="rounded-lg p-2 cursor-pointer hover:bg-neutral-100"
            onClick={(e) => {
              e.stopPropagation();
              if (parseInt(quantity) > 1) {
                setQuantity((parseInt(quantity) - 1).toString());
              }
            }}
          >
            <span className="material-symbols-outlined text-sm">remove</span>
          </button>
          <input
            type="text"
            className="text-center w-6 text-base bg-transparent"
            value={quantity}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              const value = parseInt(e.target.value);
              setQuantity(value.toString());
            }}
            onBlur={(e) => {
              e.stopPropagation();
              if (quantity.toString() === "") {
                setQuantity("1");
              } else {
              }
            }}
          />
          <button
            className="rounded-lg p-2 cursor-pointer hover:bg-neutral-100"
            onClick={(e) => {
              e.stopPropagation();
              setQuantity((parseInt(quantity) + 1).toString());
            }}
          >
            <span className="material-symbols-outlined text-sm">add</span>
          </button>
        </div>
      )}
      <AddToCartButton
        width="w-auto"
        addToCart={addToCart}
        children={
          <div className="align-self-end h-10 w-10 rounded-full bg-white bg-opacity-40 backdrop-blur-sm flex items-center justify-center">
            <span className="drop-shadow-lg hover:text-green-500 text-3xl material-symbols-outlined">
              add_shopping_cart
            </span>
          </div>
        }
        products={[
          {
            product: product,
            quantity: parseInt(quantity),
          },
        ]}
      />
    </div>
  );
}

export default ProductControls;
