import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import { selectStage, setStage } from "../../../../Store/Slices/Order/Order";
import { motion } from "framer-motion";
import { dropIn } from "../../../../Animations/Animations";
import { useNavigate } from "react-router-dom";

function TopInfo() {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();

  const stage = useSelector(selectStage);
  const styleDone = "border-b-2 border-primary";
  const styleCurrent = "border-b-2 border-neutral-300 font-semibold";
  const styleNotDone = "border-b-2 border-transparent";

  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="w-full flex flex-col-reverse items-start space-y-4"
    >
      <div className="w-full flex justify-start">
        <button
          className={`flex items-center`}
          onClick={() => {
            if (stage === 1) {
              navigate("/cart");
            }
            dispatch(setStage(stage - 1));
          }}
        >
          <span className="material-symbols-outlined text-2xl">
            chevron_left
          </span>
          <span className="text-xl font-semibold hover:underline decoration-primary">
            Powrót
          </span>
        </button>
      </div>
      <div className="flex items-center justify-center w-full space-x-4">
        <div
          className={`flex items-center space-x-1 ${
            stage === 1 ? styleCurrent : styleDone
          }`}
        >
          Logowanie / rejestracja
        </div>
        <div className="material-symbols-outlined">chevron_right</div>
        <div
          className={`flex items-center space-x-1 ${
            stage === 2 ? styleCurrent : stage > 2 ? styleDone : styleNotDone
          }`}
        >
          Dostawa
        </div>
        <div className="material-symbols-outlined">chevron_right</div>
        <div
          className={`flex items-center space-x-1 ${
            stage === 3 ? styleCurrent : stage > 3 ? styleDone : styleNotDone
          }`}
        >
          Płatność
        </div>
      </div>
    </motion.div>
  );
}

export default TopInfo;
