import React from "react";
import LoginForm from "../../../../../../Common/Forms/LoginForm/LoginForm";
import ButtonTransparentToColor from "../../../../../../Common/Buttons/ButtonTransparentToColor";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../../../Store/store";
import { setStage } from "../../../../../../Store/Slices/Order/Order";

function LoginRegister() {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="flex flex-col lg:flex-row lg:items-top space-y-4 lg:space-x-4 lg:space-y-0 w-full max-w-[1536px]">
      <div className="w-full lg:w-1/2 flex flex-col items-center justify-center rounded-lg bg-neutral-100 p-2 shadow-md">
        <div className="w-full text-start p-2 flex items-center justify-center space-x-2">
          <h1 className="text-4xl font-bold">Zaloguj się</h1>
          <span className="material-symbols-outlined text-5xl">login</span>
        </div>
        <LoginForm />
      </div>
      <div className="w-full lg:w-1/2 flex flex-col items-center justify-center rounded-lg space-y-4">
        <div className="w-full flex flex-col items-center justify-center space-y-2 p-2">
          <h1 className="text-4xl font-bold text-center">
            Nie masz konta? Zarejestruj się!
          </h1>
          <ButtonTransparentToColor
            text={"Zarejestruj się"}
            size={"large"}
            icon={"person_add"}
            color="primary"
            customClasses="w-full"
            link={"register?redirect=/order/"}
          />
        </div>
        <div className="w-full flex flex-col items-center justify-center space-y-2 p-2">
          <h1 className="text-4xl font-bold text-center">
            Lub kontynuuj jako gość
          </h1>
          <ButtonTransparentToColor
            text={"Kontynuuj jako gość"}
            size={"large"}
            customClasses="w-full"
            onClick={() => {
              dispatch(setStage(2));
            }}
          />
        </div>
      </div>
    </div>
  );
}

export default LoginRegister;
