import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import {
  ProductModel,
  ProductDisplay,
  ProductSearchResponse,
  SimiliarProductsType,
} from "../../../Models/Product";
import "path-browserify";
import { server } from "../../../server";
import { StateType } from "../../../Models/State";
import { create } from "domain";

export type ParameterChoice = {
  ParameterId: number;
  ParameterName: string;
  ChoiceId: number;
  ChoiceName: string;
  On_product: boolean;
  ShowListDropDown?: boolean;
};

type choiceModel = {
  Id: number;
  Name: string;
  Catalog_name: string;
  Price: number;
};

type productsSliceState = {
  products: {
    productsList: ProductDisplay[];
    numberOfProducts: number;
    state: StateType;
  };
  product: {
    product: ProductModel;
    state: StateType;
  };
  similiarProducts: {
    productsList: SimiliarProductsType[];
    state: StateType;
  };
  mostSuitableProducts: {
    productsList: ProductDisplay[];
    state: StateType;
  };
  productAvailability: {
    add_cart_quantity: number;
    availability: boolean;
    delivery_time: string;
    state: StateType;
  };
  landingPageProducts: {
    productsList: SimiliarProductsType[];
    state: StateType;
  };
  productParameters: {
    parameters: ParameterChoice[];
    state: StateType;
  };
  chosenParameter: number;
  chosenParameterChoices: choiceModel[];
};

export type fetchProductsParams = {
  searchQuery?: string | null;
  category?: string | null;
  sort?: string | null;
  limit?: string | null;
  filters?: { parameterId: string; choicesIds: string[] }[];
  page?: string | null;
  price_min?: string | null;
  price_max?: string | null;
};

type deliveryTime = {
  deliveryTime: string;
  availability: boolean;
};

export const fetchMostSuitableProducts = createAsyncThunk(
  "products/fetchMostSuitableProducts",
  async (searchQuery: string) => {
    try {
      const response = await axios.get(
        `${server}/api/Products/mostsuitableProducts/${searchQuery.replaceAll(
          "/",
          "*1*"
        )}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchSimilarProducts = createAsyncThunk(
  "products/fetchSimilarProducts",
  async (id: string) => {
    try {
      const response = await axios.get(
        `${server}/api/Products/similarProducts/${id}`
      );
      return response.data.products;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchProduct = createAsyncThunk(
  "products/fetchProduct",
  async (id: string) => {
    try {
      const response = await axios.get<ProductModel>(
        `${server}/api/Products/product/${id}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchAvailability = createAsyncThunk(
  "product/fetchAvailability",
  async function ({
    id,
    add_cart_quantity,
    stock_quantity,
  }: {
    id: string;
    add_cart_quantity: number;
    stock_quantity: number;
  }) {
    try {
      if (add_cart_quantity > stock_quantity) {
        const response = await axios.get<deliveryTime>(
          `${server}/api/Products/deliveries-time?catalogName=${id}&quantity=${add_cart_quantity}`
        );
        return response.data;
      } else {
        return {
          availability: true,
          deliveryTime: "1-2 dni robocze",
        };
      }
    } catch (error) {
      throw error;
    }
  }
);

export const fetchLandingPageProducts = createAsyncThunk(
  "products/fetchLandingPageProducts",
  async () => {
    try {
      const response = await axios.get(`${server}/api/Products/main-page`);
      return response.data.products;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchProductsCount = createAsyncThunk(
  "products/fetchProductsCount",
  async ({
    searchQuery = "",
    category = "1",
    sort = "default",
    limit = "20",
    filters = [],
    page = "1",
    price_min = "0",
    price_max = "100000",
  }: fetchProductsParams) => {
    try {
      const response = await axios.get<ProductSearchResponse>(
        `${server}/api/Products/count`,
        {
          params: {
            filters: filters,
            category: category,
            sorting: sort,
            searchQuery: searchQuery?.replaceAll("/", "*1*"),
            limit: limit,
            page: page,
            price_min: price_min,
            price_max: price_max,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async ({
    searchQuery = "",
    category = "1",
    sort = "default",
    limit = "20",
    filters = [],
    page = "1",
    price_min = "0",
    price_max = "100000",
  }: fetchProductsParams) => {
    try {
      const response = await axios.get<ProductSearchResponse>(
        `${server}/api/Products`,
        {
          params: {
            filters: filters,
            category: category,
            sorting: sort,
            searchQuery: searchQuery?.replaceAll("/", "*1*"),
            limit: limit,
            page: page,
            price_min: price_min,
            price_max: price_max,
          },
          withCredentials: true,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getProductsParametersChoices = createAsyncThunk(
  "products/fetchProductParameters",
  async (productCatalogName: string) => {
    try {
      const result = await axios.get<ParameterChoice[]>(
        `${server}/api/Products/parameters-choices/${productCatalogName}`
      );
      return result.data;
    } catch (e) {
      throw e;
    }
  }
);

export const getChoiceForParameter = createAsyncThunk(
  "products/getChoiceForParameter",
  async ({
    Category_id,
    parameters,
  }: {
    Category_id: number;
    parameters: { Parameter_id: number; Choice_id: number }[];
  }) => {
    try {
      const result = await axios.post<
        { Id: number; Name: string; Catalog_name: string; Price: number }[]
      >(`${server}/api/Products/parameters-products`, {
        parameters: parameters,
        Category_id: Category_id,
      });

      return result.data;
    } catch (e) {
      throw e;
    }
  }
);

const productsSlice = createSlice({
  name: "products",
  initialState: {
    products: {
      productsList: [],
      numberOfProducts: 0,
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    product: {
      product: {},
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    similiarProducts: {
      productsList: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    mostSuitableProducts: {
      productsList: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    productAvailability: {
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
      add_cart_quantity: 1,
      availability: true,
      delivery_time: "1-2 dni robocze",
    },
    landingPageProducts: {
      productsList: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    productParameters: {
      parameters: [],
      state: {
        isLoading: false,
        failedLoading: false,
        hasLoaded: false,
        error: null,
      },
    },
    chosenParameter: -1,
    chosenParameterChoices: [],
  } as unknown as productsSliceState,
  reducers: {
    changeCartAddQuantity(state, action: { payload: number }) {
      state.productAvailability.add_cart_quantity = action.payload;
      if (state.product.product.details.Stock_quantity === undefined) {
        state.productAvailability.availability = true;
        state.productAvailability.delivery_time = "1-2 dni robocze";
      } else if (
        action.payload <= state.product.product.details.Stock_quantity
      ) {
        state.productAvailability.availability = true;
        state.productAvailability.delivery_time =
          state.product.product.delivery.First_time;
      } else if (
        action.payload <=
        state.product.product.details.Stock_quantity +
          state.product.product.delivery.External_magazine
      ) {
        state.productAvailability.availability = false;
        state.productAvailability.delivery_time =
          state.product.product.delivery.Second_time;
      } else {
        state.productAvailability.availability = false;
        state.productAvailability.delivery_time =
          state.product.product.delivery.Third_time;
      }
    },
  },
  extraReducers(builder) {
    builder.addCase(fetchProducts.pending, (state, action) => {
      state.products.state.isLoading = true;
      state.products.state.failedLoading = false;
      state.products.state.hasLoaded = false;
      state.products.state.error = null;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.products.state.isLoading = false;
      state.products.state.failedLoading = false;
      state.products.state.hasLoaded = true;
      state.products.productsList = action.payload.products;
      //state.products.numberOfProducts = action.payload.count;
    });
    builder.addCase(fetchProducts.rejected, (state, action) => {
      state.products.state.isLoading = false;
      state.products.state.failedLoading = true;
      state.products.state.hasLoaded = false;
      state.products.state.error = action.error;
    });
    builder.addCase(fetchProduct.pending, (state, action) => {
      state.product.state.isLoading = true;
      state.product.state.failedLoading = false;
      state.product.state.hasLoaded = false;
      state.product.state.error = null;
    });
    builder.addCase(fetchProduct.fulfilled, (state, action) => {
      state.product.state.isLoading = false;
      state.product.state.failedLoading = false;
      state.product.state.hasLoaded = true;
      state.product.product = action.payload;
      state.productAvailability.availability =
        action.payload.details?.Stock_quantity > 0 ? true : false;
      state.productAvailability.delivery_time =
        action.payload.details?.Stock_quantity > 0
          ? action.payload.delivery.First_time
          : action.payload.delivery.External_magazine > 0
          ? action.payload.delivery.Second_time
          : action.payload.delivery.Third_time;
    });
    builder.addCase(fetchProduct.rejected, (state, action) => {
      state.product.state.isLoading = false;
      state.product.state.failedLoading = true;
      state.product.state.hasLoaded = false;
      state.product.state.error = action.error;
    });
    builder.addCase(fetchSimilarProducts.pending, (state, action) => {
      state.similiarProducts.state.isLoading = true;
      state.similiarProducts.state.failedLoading = false;
      state.similiarProducts.state.hasLoaded = false;
      state.similiarProducts.state.error = null;
    });
    builder.addCase(fetchSimilarProducts.fulfilled, (state, action) => {
      state.similiarProducts.state.isLoading = false;
      state.similiarProducts.state.failedLoading = false;
      state.similiarProducts.state.hasLoaded = true;
      state.similiarProducts.productsList = action.payload;
    });
    builder.addCase(fetchSimilarProducts.rejected, (state, action) => {
      state.similiarProducts.state.isLoading = false;
      state.similiarProducts.state.failedLoading = true;
      state.similiarProducts.state.hasLoaded = false;
      state.similiarProducts.state.error = action.error;
    });
    builder.addCase(fetchMostSuitableProducts.pending, (state, action) => {
      state.mostSuitableProducts.state.isLoading = true;
      state.mostSuitableProducts.state.failedLoading = false;
      state.mostSuitableProducts.state.hasLoaded = false;
      state.mostSuitableProducts.state.error = null;
    });
    builder.addCase(fetchMostSuitableProducts.fulfilled, (state, action) => {
      state.mostSuitableProducts.state.isLoading = false;
      state.mostSuitableProducts.state.failedLoading = false;
      state.mostSuitableProducts.state.hasLoaded = true;
      state.mostSuitableProducts.productsList = action.payload;
    });
    builder.addCase(fetchMostSuitableProducts.rejected, (state, action) => {
      state.mostSuitableProducts.state.isLoading = false;
      state.mostSuitableProducts.state.failedLoading = true;
      state.mostSuitableProducts.state.hasLoaded = false;
      state.mostSuitableProducts.state.error = action.error;
    });
    builder.addCase(fetchAvailability.pending, (state, action) => {
      state.productAvailability.state.isLoading = true;
      state.productAvailability.state.failedLoading = false;
      state.productAvailability.state.hasLoaded = false;
      state.productAvailability.state.error = null;
    });
    builder.addCase(fetchAvailability.fulfilled, (state, action) => {
      state.productAvailability.state.isLoading = false;
      state.productAvailability.state.failedLoading = false;
      state.productAvailability.state.hasLoaded = true;
      state.productAvailability.availability = action.payload.availability;
      state.productAvailability.delivery_time = action.payload.deliveryTime;
    });
    builder.addCase(fetchAvailability.rejected, (state, action) => {
      state.productAvailability.state.isLoading = false;
      state.productAvailability.state.failedLoading = true;
      state.productAvailability.state.hasLoaded = false;
      state.productAvailability.state.error = action.error;
    });
    builder.addCase(fetchLandingPageProducts.pending, (state, action) => {
      state.landingPageProducts.state.isLoading = true;
      state.landingPageProducts.state.failedLoading = false;
      state.landingPageProducts.state.hasLoaded = false;
      state.landingPageProducts.state.error = null;
    });
    builder.addCase(fetchLandingPageProducts.fulfilled, (state, action) => {
      state.landingPageProducts.state.isLoading = false;
      state.landingPageProducts.state.failedLoading = false;
      state.landingPageProducts.state.hasLoaded = true;
      state.landingPageProducts.productsList = action.payload;
    });
    builder.addCase(fetchLandingPageProducts.rejected, (state, action) => {
      state.landingPageProducts.state.isLoading = false;
      state.landingPageProducts.state.failedLoading = true;
      state.landingPageProducts.state.hasLoaded = false;
      state.landingPageProducts.state.error = action.error;
    });
    builder.addCase(fetchProductsCount.pending, (state, action) => {
      //state.products.state.isLoading = true;
      //state.products.state.failedLoading = false;
      //state.products.state.hasLoaded = false;
      //state.products.state.error = null;
    });
    builder.addCase(fetchProductsCount.fulfilled, (state, action) => {
      //state.products.state.isLoading = false;
      //state.products.state.failedLoading = false;
      //state.products.state.hasLoaded = true;
      //state.products.productsList = action.payload.products;
      state.products.numberOfProducts = action.payload.count;
    });
    builder.addCase(fetchProductsCount.rejected, (state, action) => {
      //state.products.state.isLoading = false;
      //state.products.state.failedLoading = true;
      //state.products.state.hasLoaded = false;
      //state.products.state.error = action.error;
    });
    builder.addCase(getProductsParametersChoices.pending, (state, action) => {
      state.productParameters.state.isLoading = true;
      state.productParameters.state.failedLoading = false;
      state.productParameters.state.hasLoaded = false;
      state.productParameters.state.error = null;
    });
    builder.addCase(getProductsParametersChoices.fulfilled, (state, action) => {
      state.productParameters.state.isLoading = false;
      state.productParameters.state.failedLoading = false;
      state.productParameters.state.hasLoaded = true;
      let paramers = action.payload;
      for (const element of paramers) {
        element.ShowListDropDown = false;
      }
      state.productParameters.parameters = paramers;
    });
    builder.addCase(getProductsParametersChoices.rejected, (state, action) => {
      state.productParameters.state.isLoading = false;
      state.productParameters.state.failedLoading = true;
      state.productParameters.state.hasLoaded = false;
      state.productParameters.state.error = action.error;
    });
    builder.addCase(getChoiceForParameter.pending, (state, action) => {
      state.productParameters.state.isLoading = true;
      state.productParameters.state.failedLoading = false;
      state.productParameters.state.hasLoaded = false;
      state.productParameters.state.error = null;
    });
    builder.addCase(getChoiceForParameter.fulfilled, (state, action) => {
      state.productParameters.state.isLoading = false;
      state.productParameters.state.failedLoading = false;
      state.productParameters.state.hasLoaded = true;
      state.chosenParameterChoices = action.payload;
    });
    builder.addCase(getChoiceForParameter.rejected, (state, action) => {
      state.productParameters.state.isLoading = false;
      state.productParameters.state.failedLoading = true;
      state.productParameters.state.hasLoaded = false;
      state.productParameters.state.error = action.error;
    });
  },
});

export const selectProducts = (state: {
  products: productsSliceState;
}): ProductDisplay[] => state.products.products.productsList;

export const selectNumberOfProducts = (state: {
  products: productsSliceState;
}): number => state.products.products.numberOfProducts;

export const selectProductsState = (state: {
  products: productsSliceState;
}): StateType => state.products.products.state;

export const selectMostSuitableProductsState = (state: {
  products: productsSliceState;
}): StateType => state.products.mostSuitableProducts.state;

export const selectProduct = (state: {
  products: productsSliceState;
}): ProductModel => state.products.product.product;

export const selectProductState = (state: {
  products: productsSliceState;
}): StateType => state.products.product.state;

export const selectSimilarProducts = (state: {
  products: productsSliceState;
}): SimiliarProductsType[] => state.products.similiarProducts.productsList;

export const selectSimilarProductsState = (state: {
  products: productsSliceState;
}): StateType => state.products.similiarProducts.state;

export const selectMostSuitableProducts = (state: {
  products: productsSliceState;
}): ProductDisplay[] => state.products.mostSuitableProducts.productsList;

export const selectAddCartQuantity = (state: {
  products: productsSliceState;
}): number => state.products.productAvailability.add_cart_quantity;

export const selectAvailability = (state: {
  products: productsSliceState;
}): boolean => state.products.productAvailability.availability;

export const selectDeliveryTime = (state: {
  products: productsSliceState;
}): string => state.products.productAvailability.delivery_time;

export const selectAvailabilityState = (state: {
  products: productsSliceState;
}): StateType => state.products.productAvailability.state;

export const selectLandingPageProducts = (state: {
  products: productsSliceState;
}): SimiliarProductsType[] => state.products.landingPageProducts.productsList;

export const selectLandingPageProductsState = (state: {
  products: productsSliceState;
}): StateType => state.products.landingPageProducts.state;

export const selectProductDescriptionState = (state: {
  products: productsSliceState;
}): string => state.products.product.product.descriptions?.Description;

export const selectProductsChoices = (state: {
  products: productsSliceState;
}): ParameterChoice[] => state.products.productParameters.parameters;

export const selectChosenParameter = (state: {
  products: productsSliceState;
}): number => state.products.chosenParameter;

export const selectParameterChoices = (state: {
  products: productsSliceState;
}): choiceModel[] => state.products.chosenParameterChoices;

export const { changeCartAddQuantity } = productsSlice.actions;

export default productsSlice.reducer;
