import { useEffect } from "react";
import AdminNavBar from "./AdminNavBar/AdminNavBar";
import { motion } from "framer-motion";
import {
  Route,
  Routes,
} from "react-router-dom";
import AdminOptionsBar from "./AdminOptionsBar/AdminOptionsBar";
import Products from "./AdminPages/Products/Products";
import Users from "./AdminPages/Users/Users";
import Orders from "./AdminPages/Orders/Orders";
import Categories from "./AdminPages/Categories/Categories";
import Filters from "./AdminPages/Filters/Filters";
import EditUser from "./AdminPages/Users/EditUser";
import Prices from "./AdminPages/Prices/Prices";
import AddProduct from "./AdminPages/Products/AddProduct";
import Offers from "./AdminPages/Offers/Offers";
import Product from "./AdminPages/Products/Product";

function Admin() {
  useEffect(() => {
    document.title = "StockShop | Admin";
  }, []);

  return (
    <motion.div
      className="w-full flex flex-col items-center justify-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <AdminNavBar />
      <AdminOptionsBar />
      <Routes>
        <Route path="product/:id" element={<Product/>}/>
        <Route path="products/add" element={<AddProduct/>}/>
        <Route path="products" element={<Products />} />
        <Route path="users/:id" element={<EditUser />} />
        <Route path="users" element={<Users />} />
        <Route path="orders/*" element={<Orders />} />
        <Route path="categories" element={<Categories />} />
        <Route path="filters" element={<Filters />} />
        <Route path="prices/*" element={<Prices />} />
        <Route path="offers/*" element={<Offers />} />
      </Routes>
    </motion.div>
  );
}

export default Admin;
