import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { getProducers, selectCategories, selectChosenProducer, selectParentCategory, selectProducers, setChosenCategory, setChosenProducer, setCurrentCategoryId, selectCurrentCategoryId, selectAllCategoriesMap,
selectAllCategories, getCategories, selectChosenCategory, selectImages, addImageToImages, selectChosenImage, setChosenImage, addProduct, addToTechnicalData, selectTechnicalData } from "../../../../Store/Slices/admin/Products/AddProduct";
import { AppDispatch } from "../../../../Store/store";
import Category from "../../../../Common/Categories/Category/Category";
import { CategoryModel } from "../../../../Models/Category";

export default function AddProduct() {

    const [name, setName] = useState('');
    const [catalogName, setCatalogName] = useState('');
    const [price, setPrice] = useState(0);
    const [selectingProducer, setSelectingProducer] = useState(false);
    const [selectingCategories, setSelectingCategories] = useState(false);
    let parentCategory: CategoryModel = useSelector(selectParentCategory);
    const categories = useSelector(selectCategories);
    const images = useSelector(selectImages);
    const chosenImage = useSelector(selectChosenImage);
    const [availableToBuy, setAvailableToBuy] = useState(true);
    const [parameterName, setParameterName] = useState('')
    const [parameterValue, setParameterValue] = useState('')

    const technicalData = useSelector(selectTechnicalData);

    const dispatch = useDispatch<AppDispatch>();
    const producers = useSelector(selectProducers);
    const chosenProducer = useSelector(selectChosenProducer)
    const chosenCategory = useSelector(selectChosenCategory);
    const currentCategoryId = useSelector(selectCurrentCategoryId);
    const allCategoriesMap = useSelector(selectAllCategoriesMap);
    const allCategories = useSelector(selectAllCategories);
    const currentCategory = useSelector(selectParentCategory);
    
    const [imageUrl, setImageUrl] = useState('');

    useEffect(()=>{
        dispatch(getCategories({id: currentCategoryId, allCategoriesMap, currentCategory: allCategories.filter(element=>{
          if(element.Id.toString()==currentCategoryId){
            return true;
          }
          return false;
        })[0]??currentCategory}));
      }, [currentCategoryId])

    return (
        <motion.div
        className="relative w-full min-h-96 flex flex-col items-center items-top justify-center max-w-[1536px] space-y-2"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        >
            <div>
                <p>
                    <span>Nazwa: </span>
                    <input placeholder="nazwa produktu" value={name} onChange={(e)=>{
                        setName(e.target.value);
                    }}/>
                </p>
                <p>
                    <span>Numer katalogowy: </span>
                    <input placeholder="numer katalogowy" value={catalogName} onChange={(e)=>{
                        setCatalogName(e.target.value);
                    }}/>
                </p>
                <p>
                    <span>Cena: </span>
                    <input placeholder="Cena" value={price} onChange={(e)=>{
                        setPrice(parseInt(e.target.value));
                    }}/>
                </p>
                <p>
                    Producent: 
                    {producers.length==0&&<button onClick={()=>{
                        setSelectingProducer(true);
                        if(producers.length==0){
                            dispatch(getProducers());
                        }
                    }}>Wybierz producenta</button>}
                    <button onClick={()=>{
                        setSelectingProducer(!selectingProducer);
                    }}>{producers.length==0?'':(selectingProducer?'Ukryj':"Pokaz")}</button>
                </p>
                <p>
                    {
                        producers&&selectingProducer&&producers.map(element=>{
                            return(
                                <p style={{background: (chosenProducer===element.Id?'blue': '')}}>
                                    <button onClick={()=>{
                                        dispatch(setChosenProducer(element.Id))
                                    }}>
                                        {element.Name}
                                    </button>
                                </p>
                            )
                        })
                    }
                </p>
                <button onClick={()=>{
                    setSelectingCategories(!selectingCategories)
                }}>{selectingCategories?'Ukryj kategorie':'Pokaz kategorie'}</button>
                {selectingCategories&&<p>
                    {parentCategory !== undefined && parentCategory?.Id !== 1 && (
                        <div
                        className="py-1 flex items-center cursor-pointer"
                        onClick={() => {
                            dispatch(setCurrentCategoryId(parentCategory.Parent_Category));
                        }}
                        >
                        <span className="material-symbols-outlined">chevron_left</span>
                        <span className="text-lg font-bold">{parentCategory.Name}</span>
                        </div>
                    )}
                    <AnimatePresence mode="wait" initial={false}>
                        {categories && (
                        <motion.div
                            className="flex flex-col space-y-1"
                            initial={{ opacity: 0, x: 100 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -100 }}
                            transition={{ duration: 0.3 }}
                        >
                            {categories.map((category) => (
                            <div style={{display:'flex'}}>
                                <Category key={category.Id} category={category} setCurrentCategoryId={setCurrentCategoryId} />
                                <button onClick={()=>{
                                dispatch(setChosenCategory({Id: category.Id, Name: category.Name}))
                                }}>Wybierz</button>
                            </div>
                            ))}
                        </motion.div>
                        )}
                    </AnimatePresence>
                </p>}
                <p>Wybrana kategoria: {chosenCategory.Name}</p>
                <p><input value={imageUrl} onChange={(e)=>{
                    setImageUrl(e.target.value)
                }} placeholder="Adres url zdjecia"/>
                <button onClick={()=>{
                    dispatch(addImageToImages(imageUrl));
                    setImageUrl('');
                }}>Dodaj</button></p>
                <p>{images&&images.map(element=>{
                    return(
                        <p style={{background: (chosenImage===element?'blue':'')}}>
                            <button onClick={()=>{
                                dispatch(setChosenImage(element));
                            }}>{element}</button>
                        </p>
                    )
                })}</p>
                <p>Mozliwy do kupienia <input type='checkbox' checked={availableToBuy} onChange={(e)=>{
                    setAvailableToBuy(e.target.checked)
                }}/></p>
                <p>Nazwa parametru:<input value={parameterName} onChange={(e)=>{
                    setParameterName(e.target.value);
                }} placeholder="nazwa"/> wartosc:<input value={parameterValue} onChange={(e)=>{
                    setParameterValue(e.target.value);
                }} placeholder="wartość"/><button onClick={()=>{
                    dispatch(addToTechnicalData({name: parameterName, value: parameterValue}))
                    setParameterName('');
                    setParameterValue('');
                }}>Dodaj</button></p>
                <p>{technicalData&&technicalData.map(element=>{
                    return(
                        <p>
                            {element.name}-{element.value}
                        </p>
                    )
                })}</p>
                <p><button onClick={()=>{
                    dispatch(addProduct({name: name, catalogName: catalogName, 
                        price:price, producerId: chosenProducer, categoryId: chosenCategory.Id, 
                        images: images, imageUrl: imageUrl, availableToBuy: availableToBuy, 
                        technicalData: technicalData}))
                }}>Dodaj produkt</button></p>
            </div>
        </motion.div>
    )
}
