import React from "react";
import { motion } from "framer-motion";
import Backdrop from "../../Backdrop/Backdrop";
import ProductList from "../../ProductList/ProductList";
import { ProductCart, ProductDisplay } from "../../../Models/Product";
import { dropIn } from "../../../Animations/Animations";
import { useSelector } from "react-redux";
import { selectAddCartState } from "../../../Store/Slices/Cart/Cart";
import LoadingAnimation from "../../LoadingAnimation/LoadingAnimation";
import ButtonNeutral50 from "../../Buttons/ButtonNeutral50";

type Props = {
  handleClose: () => void;
  products: ProductCart[];
  type?: "local" | "server";
};

function AddToCart({ handleClose, products, type = "server" }: Props) {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);

  React.useEffect(() => {
    window.addEventListener("resize", () => setWindowWidth(window.innerWidth));
  }, []);

  const { isLoading, hasLoaded, failedLoading, error } =
    useSelector(selectAddCartState);

  return (
    <Backdrop onClick={handleClose}>
      <motion.div
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="bg-neutral-50 rounded-lg shadow-lg p-1 flex flex-col space-y-2 items-center w-[90%] max-w-[640px]"
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {isLoading && type === "server" && <LoadingAnimation color="primary" />}
        {(hasLoaded || type === "local") && (
          <div className="flex flex-col w-full space-y-4">
            <div className="w-full flex justify-between items-center flex-wrap">
              <div className="items-center flex">
                <h1 className="text-lg lg:text-4xl font-bold">
                  Dodano do koszyka!
                </h1>
                <span className="text-xl lg:text-5xl material-symbols-outlined">
                  check_circle
                </span>
              </div>
            </div>
            <div className="flex flex-col h-fit max-h-40 overflow-y-scroll p-2 space-y-2">
              {products.map((product) => (
                <ProductList
                  key={product.product.Id}
                  product={product.product}
                  productQuantity={product.quantity}
                  showAddToCart={false}
                  showAddToList={false}
                  showFullNameOnHover={false}
                  showMoreInfo={false}
                  showFullName={true}
                  showPriceQuantity={true}
                />
              ))}
            </div>
          </div>
        )}
        {failedLoading && type === "server" && (
          <div className="flex flex-col w-full space-y-4">
            <div className="flex items-center">
              <h1 className="text-4xl font-bold">Bład.</h1>
              <span className="text-5xl material-symbols-outlined">error</span>
            </div>
            <div className="flex flex-col h-40 overflow-y-scroll p-2 space-y-2 text-xl">
              <p>Produkt nie został dodany do koszyka. Spróbuj ponownie.</p>
              <span className="font-bold">{error?.message}</span>
            </div>
          </div>
        )}
        <div className="w-full p-2">
          <ButtonNeutral50
            text={"Idź do koszyka"}
            link={"/cart"}
            icon={"shopping_cart"}
            size={windowWidth < 640 ? "small" : "small"}
            customClasses={windowWidth < 640 ? "w-full" : "w-full"}
          />
        </div>
        {false && type === "local" && (
          <div className="w-full border-t-2 flex flex-col-reverse space-y-1 sm:space-y-0 sm:flex-row sm:justify-between items-center py-1">
            <p className="text-base whitespace-break-spaces">
              Zaloguj się, aby nie utracić produktów w koszyku.
            </p>
            <ButtonNeutral50
              text={windowWidth < 570 ? "Zaloguj się" : "Zaloguj się"}
              size={windowWidth < 640 ? "small" : "medium"}
              icon={"person_add"}
              link={"/login"}
              customClasses={windowWidth < 640 ? "w-full" : ""}
            />
          </div>
        )}
      </motion.div>
    </Backdrop>
  );
}

export default AddToCart;
