import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import DropDownList from "../../../../Common/DropDownList/DropDownList";

function SortButton() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [showDropDown, setShowDropDown] = React.useState(false);
  const [clickOutside, setClickOutside] = React.useState(false);

  useEffect(() => {
    const closeDropDown = () => {
      if (showDropDown) {
        setShowDropDown(false);
      }
    };

    if (clickOutside) {
      document.addEventListener("click", closeDropDown);
    } else {
      document.removeEventListener("click", closeDropDown);
    }
    return () => {
      document.removeEventListener("click", closeDropDown);
    };
  }, [clickOutside, showDropDown]);

  return (
    <div
      className={`${
        showDropDown ? "bg-neutral-100" : ""
      } w-full relative flex flex-col items-center justify-center cursor-pointer p-2 rounded-lg hover:bg-neutral-100`}
      onClick={(e) => {
        e.stopPropagation();
        setShowDropDown(!showDropDown);
        setClickOutside(true);
      }}
    >
      <span className="material-symbols-outlined">sort</span>
      <span>Sortowanie</span>
      {showDropDown && (
        <DropDownList
          options={[
            { name: "Domyślne", value: "defualt", icon: "sort" },
            {
              name: "Cena: od najtańszych",
              value: "price_asc",
              icon: "arrow_upward",
            },
            {
              name: "Cena: od najdroższych",
              value: "price_dsc",
              icon: "arrow_downward",
            },
            { name: "Ocena", value: "rating", icon: "trending_up" },
          ]}
          selectedOption={searchParams.get("sort") || "default"}
          onClick={(option) => {
            setSearchParams((prev) => {
              prev.set("sort", option);
              prev.set("page", "1");
              return prev;
            });
            setShowDropDown(false);
          }}
        />
      )}
    </div>
  );
}

export default SortButton;
