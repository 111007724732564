import { motion } from "framer-motion";
import React from "react";

type Props = {
  showDetails: boolean;
  setShowDetails: React.Dispatch<React.SetStateAction<boolean>>;
};

function ShowDetailsButton({ showDetails, setShowDetails }: Props) {
  return (
    <div
      className={`${
        showDetails ? "" : "absolute bottom-0"
      } flex items-center justify-center w-full h-20 bg-gradient-to-t from-neutral-100 to-transparent`}
    >
      <motion.button
        className="group flex justify-center items-center rounded-lg border-primary border-2 backdrop-blur-sm px-2"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => setShowDetails(!showDetails)}
      >
        <span className="text-lg font-bold text-neutral-700">
          {showDetails ? "Ukryj" : "Pokaż"}
        </span>
        <span className="material-symbols-outlined text-3xl text-primary">
          {showDetails ? "expand_less" : "expand_more"}
        </span>
      </motion.button>
    </div>
  );
}

export default ShowDetailsButton;
