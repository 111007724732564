import React, { useEffect } from "react";
import OrderPage from "../../../Common/OrderPage/OrderPage";
import { initializeOrder } from "../../../Store/Slices/Order/Order";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Store/store";

function Summary() {
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(initializeOrder());
  }, []);

  return (
    <div className="relative w-full my-10">
      <h1 className="text-xl sm:text-3xl lg:text-5xl font-bold mb-4 text-center">
        Dziękujemy za złożenie zamówienia!
      </h1>
      <div className="p-5">
        <OrderPage isSummary={true} />
      </div>
    </div>
  );
}

export default Summary;
