import { motion } from "framer-motion";
import React from "react";
import { dropIn } from "../../../../Animations/Animations";
import StageTwo from "./StageTwo/StageTwo";
import StageThree from "./StageThree/StageThree";
import StageOne from "./StageOne/StageOne";

type Props = {
  stage: number;
};

function OrderInfo({ stage }: Props) {
  return (
    <motion.div
      className="flex flex-col w-full min-h-96 items-center bg-neutral-100 rounded-lg shadow-md p-2 space-y-2"
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      {stage === 1 && <StageOne />}
      {stage === 2 && <StageTwo />}
      {stage === 3 && <StageThree />}
    </motion.div>
  );
}

export default OrderInfo;
