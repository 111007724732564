import React, { useRef, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { CategoryModel } from "../../../Models/Category";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";

type Props = {
  category: CategoryModel;
  setCurrentCategoryId: ActionCreatorWithPayload<any, any>,
  admin?: boolean
};

function Category({ category, setCurrentCategoryId, admin }: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const location = useLocation();

  const [, setSearchParams] = useSearchParams({
    category: "-1",
  });

  const [showImage, setShowImage] = useState<boolean>(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const tooltipRef = useRef<HTMLDivElement | null>(null);

  const handleMouseEnter = () => {
    setShowImage(true);
  };

  const handleMouseLeave = () => {
    setShowImage(false);
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    if (tooltipRef.current) {
      const { clientX, clientY } = event;
      const tooltipWidth = tooltipRef.current.clientWidth;
      const tooltipHeight = tooltipRef.current.clientHeight;

      setTooltipPosition({
        x: clientX - tooltipWidth / 2,
        y: clientY - tooltipHeight - 10,
      });
    }
  };

  return (
    <div
      className="relative rounded-lg bg-neutral-100 hover:bg-neutral-200 p-1 flex space-x-1 items-center text-base cursor-pointer"
      onClick={() => {
        console.log('1');
        console.log(admin);
        console.log(location.pathname)
        dispatch(setCurrentCategoryId(category.Id));
        if (location.pathname.startsWith("/search")||(admin&&location.pathname.startsWith('/admin/products'))) {
          console.log('2')
          setSearchParams((prev) => {
            prev.set("category", category.Id.toString());
            return prev;
          });
        }
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      <span>{category.Name}</span>
      {category.Has_children ? (
        <span className="material-symbols-outlined">chevron_right</span>
      ) : (
        <span></span>
      )}
      {showImage && category.Image_url !== null && (
        <div
          ref={tooltipRef}
          style={{
            position: "fixed",
            left: `${tooltipPosition.x}px`,
            top: `${tooltipPosition.y}px`,
            zIndex: 9999,
            backgroundImage: `url(${category.Image_url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
          className="w-40 h-40 shadow-md bg-neutral-100 bg-opacity-40 backdrop-blur-md p-2"
        ></div>
      )}
    </div>
  );
}

export default Category;
