import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import ReactDOM from "react-dom";
import Backdrop from "../Backdrop/Backdrop";

type Props = {
  color: string;
  type?: "local" | "global";
  size?: "small" | "medium" | "large";
};

function LoadingAnimation({ color, type = "local", size = "large" }: Props) {
  if (type === "global") {
    return (
      <div>
        {ReactDOM.createPortal(
          <AnimatePresence mode="wait">
            <Backdrop onClick={() => {}}>
              <span
                className={`${
                  size === "small"
                    ? "text-3xl"
                    : size === "medium"
                    ? "text-5xl"
                    : "text-7xl"
                } material-symbols-outlined animate-spin-slow text-${color}`}
              >
                settings
              </span>
            </Backdrop>
          </AnimatePresence>,
          document.body
        )}
      </div>
    );
  }

  return (
    <motion.div
      className={`absolute z-50 top-0 left-0 w-full h-full flex justify-center items-center backdrop-blur-sm`}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <span
        className={
          size === "small"
            ? "text-3xl"
            : size === "medium"
            ? "text-5xl"
            : "text-7xl" +
              ` material-symbols-outlined animate-spin-slow text-${color}`
        }
      >
        settings
      </span>
    </motion.div>
  );
}

export default LoadingAnimation;
