import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import Confirm from "../../../Common/Modals/Confirm/Confirm";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import { logout } from "../../../Store/Slices/User/User";
import { clearFavouritesLists } from "../../../Store/Slices/wishlist/wishlistSlice";
import ReactDOM from "react-dom";

type Props = {
  bookmarks: {
    id: number;
    name: string;
    icon: string;
    link: string;
  }[];
};

function ProfileControls({ bookmarks }: Props) {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const [showLogoutModal, setShowLogoutModal] = useState(false);

  return (
    <div className="lg:sticky lg:top-28 flex flex-col justify-between overflow-hidden w-full lg:w-1/4 h-fit bg-neutral-100 rounded-lg shadow-md p-2">
      <div className="mb-10">
        {bookmarks.map((bookmark) => {
          return (
            <Link
              key={bookmark.id}
              to={bookmark.link}
              className="w-full hover:bg-neutral-200 flex items-center space-x-2 p-1"
            >
              <span className="text-xl">{bookmark.name}</span>
              <span className="material-symbols-outlined text-2xl">
                {bookmark.icon}
              </span>
            </Link>
          );
        })}
      </div>
      <button
        onClick={() => setShowLogoutModal(true)}
        className="w-full hover:bg-neutral-200 flex items-center space-x-2 p-1"
      >
        <span className="text-xl">Wyloguj się</span>
        <span className="material-symbols-outlined text-2xl">logout</span>
      </button>

      {showLogoutModal &&
        ReactDOM.createPortal(
          <AnimatePresence mode="wait">
            <Confirm
              handleClose={function (): void {
                setShowLogoutModal(false);
              }}
              handleConfirm={function (): void {
                setShowLogoutModal(false);
                dispatch(logout());
                dispatch(clearFavouritesLists());
                navigate("/");
              }}
              text={"Czy na pewno chcesz się wylogować?"}
            />
          </AnimatePresence>,
          document.body
        )}
    </div>
  );
}

export default ProfileControls;
