import React from "react";

type Option = {
  name: string;
  value: string;
  icon: string | null;
};

type Props = {
  options: Option[];
  selectedOption: string;
  onClick: (option: string) => void;
  customClasses?: string;
  whiteSpaceWrap?: boolean;
};

function DropDownList({
  options,
  selectedOption,
  onClick,
  customClasses = "",
  whiteSpaceWrap = false,
}: Props) {
  return (
    <div
      className={`z-50 absolute top-full flex flex-col items-center bg-neutral-100 bg-opacity-80 backdrop-blur-md rounded-lg py-3 px-1 overflow-hidden shadow-md ${customClasses}`}
    >
      {options.map((option) => {
        return (
          <div
            key={option.name}
            className={`${
              option.name === selectedOption ? "bg-neutral-200" : ""
            } py-2 px-4 hover:bg-neutral-200 bg-opacity-60 flex items-center w-full space-x-1 cursor-pointer`}
            onClick={(e) => {
              e.stopPropagation();
              onClick(option.value);
            }}
          >
            {option.icon && (
              <span className="material-symbols-outlined">{option.icon}</span>
            )}
            <span
              className={
                whiteSpaceWrap ? "whitespace-wrap" : "whitespace-nowrap"
              }
            >
              {option.name}
            </span>
          </div>
        );
      })}
    </div>
  );
}

export default DropDownList;
