import React, { useEffect } from "react";
import { OrderType } from "../../../../../Models/Order";
import { convertProductCartToProductIdQuantity } from "../../../../../Models/Product";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../../Store/store";
import {
  createOrderLogged,
  createOrderNotLogged,
  selectChosenDeliveryType,
  selectCreateOrderState,
  selectOrderData,
  selectPersonalInformation,
  setOrderDataStageTwo,
  setPersonalInformation,
} from "../../../../../Store/Slices/Order/Order";
import { selectProducts } from "../../../../../Store/Slices/Cart/Cart";
import LoadingAnimation from "../../../../../Common/LoadingAnimation/LoadingAnimation";
import Products from "./Products/Products";
import PersonalData from "./PersonalData/PersonalData";
import Delivery from "./Delivery/Delivery";
import Address from "./Address/Address";
import Comments from "./Comments/Comments";
import { selectIsLoggedIn } from "../../../../../Store/Slices/User/User";
import ChooseAddress from "./ChooseAddress/ChooseAddress";
import Cookies from "js-cookie";

function StageTwo() {
  const dispatch = useDispatch<AppDispatch>();
  const chosenDeliveryType = useSelector(selectChosenDeliveryType);
  const products = useSelector(selectProducts);
  const orderDataWithAddresses = useSelector(selectOrderData);

  const user = JSON.parse(Cookies.get("user") ?? "{}") as {
    Name: string;
    Surname: string;
    Email: string;
    Phone_number: string;
  };

  const personalInformation = useSelector(selectPersonalInformation);

  const isLoggedIn = useSelector(selectIsLoggedIn);

  useEffect(() => {
    if (
      isLoggedIn &&
      ((personalInformation.name === "" &&
        personalInformation.surname === "" &&
        personalInformation.email === "" &&
        personalInformation.phoneNumber === "") ||
        (personalInformation.name === undefined &&
          personalInformation.surname === undefined &&
          personalInformation.email === undefined &&
          personalInformation.phoneNumber === undefined))
    ) {
      dispatch(
        setPersonalInformation({
          name: user.Name,
          email: user.Email,
          phoneNumber: user.Phone_number,
        })
      );
    }
  }, []);

  const state = useSelector(selectCreateOrderState);
  return (
    <form
      className="relateive flex flex-col w-full space-y-2"
      id="order-form"
      onSubmit={(e) => {
        e.preventDefault();
        const formData = new FormData(e.currentTarget);
        const data = Object.fromEntries(formData);

        if (isLoggedIn) {
          if (!data.Address_id && chosenDeliveryType?.Address_required === 1) {
            return;
          }
          dispatch(
            setOrderDataStageTwo({
              name: data.name.toString(),
              surname: data.surname.toString(),
              email: data.email.toString(),
              phoneNumber: data.phoneNumber.toString(),
              comments: data.comments.toString(),
              delivery_type: parseInt(data.deliveryType.toString()),
              pay_type: -1,
              address: orderDataWithAddresses.address ?? "",
              city: orderDataWithAddresses.city ?? "",
              zipCode: orderDataWithAddresses.zipCode ?? "",
              CompanyName: "",
              CompanyAddress: "",
              CompanyCity: "",
              CompanyPostalCode: "",
              CompanyNIP: "",
            })
          );
        } else {
          dispatch(
            setOrderDataStageTwo({
              name: data.name.toString(),
              surname: data.surname.toString(),
              email: data.email.toString(),
              phoneNumber: data.phoneNumber.toString(),
              comments: data.comments.toString(),
              delivery_type: parseInt(data.deliveryType.toString()),
              address: data.address?.toString() || "",
              city: data.city?.toString() || "",
              zipCode: data.zipCode?.toString() || "",
              products: convertProductCartToProductIdQuantity(products),
              pay_type: -1,
              CompanyName: "",
              CompanyAddress: "",
              CompanyCity: "",
              CompanyPostalCode: "",
              CompanyNIP: "",
            })
          );
        }
        window.scrollTo(0, 0);
      }}
    >
      {state.isLoading && <LoadingAnimation color="primary" type="local" />}
      <Products />
      <PersonalData personalInformation={personalInformation} />
      <Delivery />
      {chosenDeliveryType?.Address_required === 1 && !isLoggedIn && <Address />}
      {chosenDeliveryType?.Address_required === 1 && isLoggedIn && (
        <ChooseAddress />
      )}
      <Comments />
    </form>
  );
}

export default StageTwo;
