import axios from "axios";
import { UserAdminDisplay } from "../../../../Models/User"
import { server } from "../../../../server";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { StateType } from "../../../../Models/State";

export type ExtraProductOffer = {
    Name: string,
    Catalog_name: string,
    Quantity: number,
    Discount: number,
    Price: number,
    Image_url: string,
};

type adminOffersSlice = {
    searchUsers:UserAdminDisplay[],
    chosenUser: number,
    state: StateType,
    extraProducts: ExtraProductOffer[],
    extraProductsState: StateType,
    client:{
        clientName: string,
        clientNIP: string,
        clientAddress: string,
        clientCity: string,
        zamawiajacy: string
    },
    dataZlecenia: string,
    dataWaznosci: string,
    platnosc: string,
    tworca: string,

};

export const getUsers=createAsyncThunk(
    'offers/getUsers',
    async({name}:{name:string})=>{
      try{
        const result = await axios.get<UserAdminDisplay[]>(`${server}/api/Users/admin/find/${name}`, 
        {withCredentials: true});
        return result.data;
      }
      catch(error){
        throw error;
      }
    }
);

export const addNewExtraProductsWithImage=createAsyncThunk(
    'offers/addNewExtraProductsWithImage',
    async({name, catalogName, quantity, discount, price, image}: {name: string, catalogName: string, 
        quantity: number, discount: number, price: number, image: File})=>{
            try{
                if(image){
                    const formData = new FormData();
                    formData.append('file', image);

                    const response = await fetch('http://localhost:7010/api/upload', {
                        method: 'POST',
                        body: formData,
                    });

                    const data = await response.json();

                    return {name, catalogName, quantity, discount, price, image: data.fileName}
                }
            }
            catch(e){
                throw e;
            }
    }
)


const offersSlice = createSlice({
    name: 'offers',
    initialState:{
        searchUsers: [],
        chosenUser: -1,
        state: {
            isLoading: false,
            failedLoading: false,
            hasLoaded: false,
            error: null,
        },
        extraProducts: [],
        extraProductsState: {
            isLoading: false,
            failedLoading: false,
            hasLoaded: false,
            error: null,
        },
        client:{
            clientName: '',
            clientNIP: '',
            clientAddress: '',
            clientCity: '',
            zamawiajacy: ''
        },
        dataZlecenia: '',
        dataWaznosci: '',
        platnosc: '',
        tworca: '',
    } as unknown as adminOffersSlice,
    reducers: {
        setChosenUser: (state, action)=>{
            state.chosenUser = action.payload;
        },
        setClientName: (state, action)=>{
            state.client.clientName = action.payload;
        },
        setClientNIP: (state, action)=>{
            state.client.clientNIP = action.payload;
        },
        setClientAddress: (state, action)=>{
            state.client.clientAddress = action.payload;
        },
        setClientCity: (state, action)=>{
            state.client.clientCity = action.payload;
        },
        setClientZamawiajacy: (state, action)=>{
            state.client.zamawiajacy = action.payload;
        },
        setDataZlecenia: (state, action)=>{
            state.dataZlecenia = action.payload;
        },
        setDataWaznosci: (state, action)=>{
            state.dataWaznosci = action.payload;
        },
        setPlatnosc: (state, action)=>{
            state.platnosc = action.payload;
        },
        setTworca: (state, action)=>{
            state.tworca = action.payload;
        }
    },
    extraReducers:{
        [getUsers.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.searchUsers =  action.payload;
          },
          [getUsers.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [getUsers.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
          [addNewExtraProductsWithImage.fulfilled.type]: (state, action) => {
            state.state.hasLoaded = true;
            state.state.isLoading = false;
            state.state.failedLoading = false;
            state.extraProducts = [...state.extraProducts, {Name: action.payload.name, Catalog_name: action.payload.catalogName,
            Quantity: action.payload.quantity, Discount: action.payload.discount, Price: action.payload.price, 
            Image_url: action.payload.image}];
          },
          [addNewExtraProductsWithImage.pending.type]: (state) => {
            state.state.isLoading = true;
            state.state.failedLoading = false;
            state.state.hasLoaded = false;
          },
          [addNewExtraProductsWithImage.rejected.type]: (state, action) => {
            state.state.isLoading = false;
            state.state.failedLoading = true;
            state.state.hasLoaded = false;
            state.state.error = action.error;
          },
    }
})

export const selectSearchUsers = (state: {
    offers: adminOffersSlice;
}): UserAdminDisplay[]=>{
    return state.offers.searchUsers;
}

export const selectChosenUser = (state: {
    offers: adminOffersSlice;
}): number=>{
    return state.offers.chosenUser;
}

export const selectOfferState = (state: {
    offers: adminOffersSlice;
}): StateType=>{
    return state.offers.state;
}

export const selectExtraProducts = (state: {
    offers: adminOffersSlice;
}): ExtraProductOffer[]=>{
    return state.offers.extraProducts
};

export const selectClientName = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.client.clientName
};
export const selectClientNIP = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.client.clientNIP
};
export const selectClientAddress = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.client.clientAddress
};
export const selectClientCity = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.client.clientCity
};
export const selectClientZamawiajacy = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.client.zamawiajacy
};
export const selectClientPlatnosc = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.platnosc
};
export const selectClientDataWaznosci = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.dataWaznosci
};
export const selectClientDataZlecenia = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.dataZlecenia
};
export const selectClientTworca = (state: {
    offers: adminOffersSlice;
}): string=>{
    return state.offers.tworca
};

export const {setChosenUser, setClientAddress, setClientCity, setClientNIP, setClientName, setClientZamawiajacy,
setDataWaznosci, setDataZlecenia, setPlatnosc, setTworca} = offersSlice.actions;

export default offersSlice.reducer;