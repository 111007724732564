import React, { useEffect } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import CategoriesFiltersButton from "../../CategoriesFilters/CategoriesFiltersButton/CategoriesFiltersButton";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCategoriesBySearch,
  selectCategoriesSearch,
  selectCategoriesSearchState,
} from "../../../Store/Slices/Categories/Categories";
import { AppDispatch } from "../../../Store/store";
import Suggested from "./Suggested/Suggested";
import {
  fetchMostSuitableProducts,
  selectMostSuitableProducts,
  selectMostSuitableProductsState,
} from "../../../Store/Slices/Products/Products";

type Props = {
  type: "mobile" | "desktop";
  admin?: boolean
};

export default function SearchBar({ type, admin }: Props): JSX.Element {
  const dispatch = useDispatch<AppDispatch>();

  const navigate = useNavigate();
  const location = useLocation();

  const suggestedCategories = useSelector(selectCategoriesSearch);
  const suggestedCategoriesState = useSelector(selectCategoriesSearchState);

  const suggestedProducts = useSelector(selectMostSuitableProducts);
  const suggestedProductsState = useSelector(selectMostSuitableProductsState);

  const [, setSearchParams] = useSearchParams({
    searchQuerry: "",
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const searchQuery = formData.get("search") as string;
    if(admin){
      setSearchParams((prev) => {
        prev.set("searchQuery", searchQuery);
        return prev;
      });
    }
    else{
      if (location.pathname.includes("/search")) {
      setSearchParams((prev) => {
        prev.set("searchQuery", searchQuery);
        return prev;
      });
    } else {
      navigate(`/search?searchQuery=${searchQuery}`);
    }
  }
  };

  const [showDropDown, setShowDropDown] = React.useState(false);
  const [clickOutside, setClickOutside] = React.useState(false);

  useEffect(() => {
    const closeDropDown = () => {
      if (showDropDown) {
        setShowDropDown(false);
      }
    };

    if (clickOutside) {
      document.addEventListener("click", closeDropDown);
    } else {
      document.removeEventListener("click", closeDropDown);
    }
    return () => {
      document.removeEventListener("click", closeDropDown);
    };
  }, [clickOutside, showDropDown]);

  const handleSearchQueryChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const searchQuery = e.currentTarget.value;
    if (searchQuery.length > 2) {
      dispatch(fetchCategoriesBySearch(searchQuery));
      dispatch(fetchMostSuitableProducts(searchQuery));
      setShowDropDown(true);
      setClickOutside(true);
    } else {
      setShowDropDown(false);
      setClickOutside(false);
    }
  };

  return (
    <div
      className={`${
        type === "mobile" ? "block md:hidden" : "hidden md:block"
      } w-full md:w-fit`}
    >
      <form onSubmit={handleSubmit} className="w-full">
        <div className="relative w-full md:w-[320px] lg:w-[450px]">
          <input
            type="search"
            autoComplete="off"
            id="search"
            name="search"
            className="relative appearance-none block w-full p-4 text-base border-2 border-neutral-950 active:rounded-lg focus:rounded-lg hover:rounded-lg rounded-lg sm:rounded-lg bg-neutral-50 focus:outline-none focus:ring-1 focus:ring-neutral-950 focus:border-neutral-950 transition-all"
            placeholder="Czego szukasz ?"
            required={true}
            onChange={handleSearchQueryChange}
            onClick={(e) => {
              e.stopPropagation();
              if (
                suggestedCategories.length > 0 &&
                e.currentTarget.value.length > 2
              ) {
                if (!showDropDown) {
                  setShowDropDown(true);
                  setClickOutside(true);
                }
              }
            }}
          />
          <div className="absolute right-2.5 bottom-2.5 flex shadow-md rounded-lg">
            {!admin&&<CategoriesFiltersButton
              customStyle={
                location.pathname.includes("/search") ? "hidden" : ""
              }
            >
              <div className="h-full bg-neutral-50 border-e-0 px-4 py-2 hover:bg-primary hover:text-neutral-50 font-bold text-base rounded-lg rounded-tr-none rounded-br-none flex items-center lg:space-x-2">
                <span className="hidden lg:inline-block">Filtry</span>{" "}
                <span className="material-symbols-outlined">filter_list</span>
              </div>
            </CategoriesFiltersButton>}
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowDropDown(false);
                setClickOutside(false);
              }}
              className={`${
                location.pathname.includes("/search")
                  ? ""
                  : "rounded-tl-none rounded-bl-none"
              } h-full bg-neutral-50 px-4 py-2 hover:bg-primary hover:text-neutral-50 font-bold text-base rounded-lg flex items-center lg:space-x-2`}
              type="submit"
            >
              <span className="hidden lg:inline-block">Szukaj</span>{" "}
              <span className="material-symbols-outlined">search</span>
            </button>
          </div>
          {showDropDown &&
            suggestedCategoriesState.hasLoaded &&
            suggestedCategories.length > 0 &&
            suggestedProductsState.hasLoaded &&
            suggestedProducts?.length > 0 && (
              <Suggested
                suggestedCategories={suggestedCategories}
                categoriesState={suggestedCategoriesState}
                suggestedProducts={suggestedProducts}
                suggestedProductsState={suggestedProductsState}
                setDropDown={setShowDropDown}
              />
            )}
        </div>
      </form>
    </div>
  );
}
