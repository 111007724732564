import { marked } from "marked";
import React, { useEffect } from "react";

function Regulamin() {
  useEffect(() => {
    document.title = "Regulamin - Press Bar Pneumatyka";
  }, []);

  useEffect(() => {
    const updateMarkdownContent = async () => {
      const parsedContent = await marked.parse("#Regulamin");
      document.getElementById("markdown_content")!.innerHTML = parsedContent;
    };

    updateMarkdownContent();
  }, []);

  return (
    <div className="w-full max-w-[1280px] text-center p-2 flex flex-col">
      <h1 className="text-3xl sm:text-4xl md:text-6xl my-10 font-bold">
        Regulamin
      </h1>
      <div
        id="markdown_content"
        className="w-full flex flex-col items-center"
      ></div>
    </div>
  );
}

export default Regulamin;
