import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Parameter } from "../../../Models/Parameter";
import CheckboxFilter from "./CheckboxFilter/CheckboxFilter";

function Filter({ filter, FiltersCounts }: { filter: Parameter, FiltersCounts?: {Choice_id: number, Count: number}[] }) {
  const [isDropdownOpen, setIsDropdownOpen] = React.useState<boolean>(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <div>
      <div
        className="rounded-lg bg-neutral-100 hover:bg-neutral-200 p-1 flex space-x-1 items-center text-base cursor-pointer"
        onClick={() => {
          toggleDropdown();
        }}
      >
        {filter.Name}{" "}
        <span className="material-symbols-outlined">
          {isDropdownOpen ? "expand_less" : "expand_more"}
        </span>
      </div>
      <AnimatePresence>
        {isDropdownOpen && (
          <motion.div
            initial={{ height: 0 }}
            animate={{ height: "auto" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.3, ease: "easeInOut" }}
            className="overflow-hidden"
          >
            <CheckboxFilter options={filter.Predefined_choices} FiltersCounts={FiltersCounts} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default Filter;
