import React from "react";
import SearchOptionsBar from "./SearchOptionsBar/SearchOptionsBar";
import SearchResults from "./SearchResults/SearchResults";
import { motion } from "framer-motion";

function Search() {
  return (
    <motion.div
      className="w-full flex flex-col items-center px-2 sm:px-5"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <SearchOptionsBar />
      <SearchResults  />
    </motion.div>
  );
}

export default Search;
