import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { CategoryModel } from "../../../Models/Category";
import "path-browserify";
import { StateType } from "../../../Models/State";
import { server } from "../../../server";
import { removeAllParameterChoices } from "../Parameters/Parameters";

type CategoriesState = {
  currentCategoryId: string;
  parentCategory: CategoryModel[];
  categories: CategoryModel[];
  categoriesAllMap: Map<number, CategoryModel[]>;
  categoriesAll: CategoryModel[];
  state: StateType;
  categoriesSearchState: StateType;
  categoriesSearch: CategoryModel[];
};

export const fetchCategoriesBySearch = createAsyncThunk(
  "categories/fetchCategoriesBySearch",
  async (searchQuery: string) => {
    try {
      const response = await axios.get<CategoryModel[]>(
        `${server}/api/Categories/search?searchQuery=${searchQuery}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCategories = createAsyncThunk(
  "categories/fetchCategories",
  async (id: string) => {
    try {
      const response = await axios.get<CategoryModel[]>(
        `${server}/api/Categories/${id}`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCategoriesWithChildren = createAsyncThunk(
  "categories/fetchCategoriesWithChildren",
  async ({id, allCategoriesMap, currentCategory}:{id: string, allCategoriesMap: Map<number, CategoryModel[]>, currentCategory: CategoryModel}) => {
    try {
      if(allCategoriesMap.has(parseInt(id))){
        return {categories: [currentCategory].concat(allCategoriesMap.get(parseInt(id))??[]), concat: false}
      }
      const response = await axios.get<CategoryModel[]>(
        `${server}/api/Categories/${id}/children`
      );
      
      return {categories: response.data, concat: true};
    } catch (error) {
      throw error;
    }
  }
);

export const fetchCategoriesHierarchy = createAsyncThunk(
  "categories/fetchCategoiresHierarchy",
  async (id: string) => {
    try {
      const response = await axios.get<CategoryModel[]>(
        `${server}/api/Categories/${id}/hierarchy`,
        { withCredentials: true }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const categoriesSlice = createSlice({
  name: "categories",
  initialState: {
    currentCategoryId: "1",
    parentCategory: [],
    categories: [],
    categoriesAllMap: new Map(),
    categoriesAll: [],
    state: {
      isLoading: false,
      failedLoading: false,
      hasLoaded: false,
      error: null,
    },
    categoriesSearchState: {
      isLoading: false,
      failedLoading: false,
      hasLoaded: false,
      error: null,
    },
    categoriesSearch: [],
  } as CategoriesState,
  reducers: {
    setCurrentCategoryId: (state, action) => {
      state.currentCategoryId = action.payload;
    },
  },
  extraReducers: {
    [fetchCategories.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.categories = action.payload;
    },
    [fetchCategories.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [fetchCategories.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [fetchCategoriesWithChildren.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.parentCategory=[action.payload.categories[0]];
      var category = -1;
      var categories:CategoryModel[] = [];

      if(action.payload.concat){
        state.categoriesAll=state.categoriesAll.concat(action.payload.categories);
        ((action.payload.categories as CategoryModel[]).slice(1)).forEach(element=>{
          if(element.Parent_Category!=category){
            state.categoriesAllMap.set(category, categories);
            categories=[];
            category=element.Parent_Category;
          }
          categories.push(element);
        })
        if(((action.payload.categories as CategoryModel[]).slice(1)).length==0){
          category=state.parentCategory[0].Id;
        }
        state.categoriesAllMap.set(category, categories);
      }
      
      state.categories=state.categoriesAllMap.get((action.payload.categories as CategoryModel[])[0].Id)??[];
      state.currentCategoryId = (action.payload.categories as CategoryModel[])[0].Id.toString();
    },
    [fetchCategoriesWithChildren.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [fetchCategoriesWithChildren.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [fetchCategoriesHierarchy.rejected.type]: (state, action) => {
      state.state.isLoading = false;
      state.state.failedLoading = true;
      state.state.hasLoaded = false;
      state.state.error = action.error;
    },
    [fetchCategoriesHierarchy.fulfilled.type]: (state, action) => {
      state.state.hasLoaded = true;
      state.state.isLoading = false;
      state.state.failedLoading = false;
      state.parentCategory = action.payload;
    },
    [fetchCategoriesHierarchy.pending.type]: (state) => {
      state.state.isLoading = true;
      state.state.failedLoading = false;
      state.state.hasLoaded = false;
    },
    [fetchCategoriesBySearch.fulfilled.type]: (state, action) => {
      state.categoriesSearchState.hasLoaded = true;
      state.categoriesSearchState.isLoading = false;
      state.categoriesSearchState.failedLoading = false;
      state.categoriesSearch = action.payload;
    },
    [fetchCategoriesBySearch.pending.type]: (state) => {
      state.categoriesSearchState.isLoading = true;
      state.categoriesSearchState.failedLoading = false;
      state.categoriesSearchState.hasLoaded = false;
    },
    [fetchCategoriesBySearch.rejected.type]: (state, action) => {
      state.categoriesSearchState.isLoading = false;
      state.categoriesSearchState.failedLoading = true;
      state.categoriesSearchState.hasLoaded = false;
      state.categoriesSearchState.error = action.error;
    },
  },
});

export const selectCategories = (state: {
  categories: CategoriesState;
}): CategoryModel[] => {
  return state.categories.categories;
};

export const selectCategoriesSearch = (state: {
  categories: CategoriesState;
}): CategoryModel[] => {
  return state.categories.categoriesSearch;
};

export const selectCategoriesState = (state: {
  categories: CategoriesState;
}): StateType => {
  return state.categories.state;
};

export const selectCategoriesSearchState = (state: {
  categories: CategoriesState;
}): StateType => {
  return state.categories.categoriesSearchState;
};

export const selectParentCategory = (state: {
  categories: CategoriesState;
}): CategoryModel => {
  return state.categories.parentCategory[0];
};

export const selectCurrentCategoryId = (state: {
  categories: CategoriesState;
}): string => {
  return state.categories.currentCategoryId;
};

export const selectAllCategoriesMap = (state: {
  categories: CategoriesState;
}): Map<number, CategoryModel[]> => {
  return state.categories.categoriesAllMap;
};

export const selectAllCategories = (state: {
  categories: CategoriesState;
}): CategoryModel[] => {
  return state.categories.categoriesAll;
};

export const { setCurrentCategoryId } = categoriesSlice.actions;

export default categoriesSlice.reducer;
