import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const ScrollUp: React.FC = () => {
  const [showButton, setShowButton] = useState(false);
  const [scrollRemaining, setScrollRemaining] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const remaining =
        document.documentElement.scrollHeight -
        (window.scrollY + document.documentElement.clientHeight);
      setScrollRemaining(remaining);

      if (window.scrollY > 200 && scrollRemaining > 100) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [scrollRemaining]);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const location = useLocation();

  return (
    <div
      className={location.pathname.split("/")[1] === "product" ? "hidden" : ""}
    >
      <AnimatePresence mode="wait" initial={false}>
        {showButton && (
          <motion.button
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            whileHover={{ scale: 1.1 }}
            className={`${
              showButton ? "block" : "hidden"
            } fixed bottom-4 right-4 w-16 h-16 z-40 border-2 border-primary rounded-full bg-neutral-100 shadow-md flex items-center justify-center text-2xl text-neutral-600 hover:shadow-lg`}
            onClick={scrollToTop}
          >
            <span className="material-symbols-outlined text-3xl">
              expand_less
            </span>
          </motion.button>
        )}
      </AnimatePresence>
    </div>
  );
};

export default ScrollUp;
