import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

function OrderCancel() {
  const { orderId } = useParams();
  const [timer, setTimer] = useState(10);

  useEffect(() => {
    setTimer(10);

    if (!orderId) {
      console.error("Order ID is undefined");
      return;
    }

    const countdown = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    console.log(timer);
    const timeout = setTimeout(() => {
      window.location.href = `/order/summary/${orderId}`;
    }, 10000);

    return () => {
      clearInterval(countdown);
      clearTimeout(timeout);
    };
  }, [orderId]);

  return (
    <div className="w-full max-w-[1536px] px-2 sm:px-5 flex flex-col items-center justify-center min-h-[50vh] space-y-3">
      <h1 className="text-2xl sm:text-3xl lg:text-6xl font-bold mb-4 text-center">
        Płatność anulowana
      </h1>
      <span className="material-symbols-outlined text-6xl sm:text-9xl">
        cancel
      </span>
      <span className="text-xs sm:text-base">
        Przekierowanie na stronę zamówienia za: {timer} sekund(y)
      </span>
      <motion.button
        className="relative group flex items-center justify-center p-4 font-semibold border-2 border-neutral-950"
        onClick={() => {
          window.location.href = `/order/summary/${orderId}`;
        }}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.95 }}
      >
        <span>Przejdź do zamówienia</span>
      </motion.button>
    </div>
  );
}

export default OrderCancel;
