import { AnimatePresence, motion } from "framer-motion";
import React, { useState } from "react";
import Input from "../../../../Common/Input/Input";
import Confirm from "../../../../Common/Modals/Confirm/Confirm";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import { changeListName, changeSelectedList, deleteList } from "../../../../Store/Slices/wishlist/wishlistSlice";
import EditNameForm from "./EditNameForm";

type Props = {
  isDefault?: boolean;
  name?: string;
  id?: number;
};

function ListItem({ isDefault = false, name, id }: Props) {
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const dispatch = useDispatch<AppDispatch>();

  return (
    <div className="relative w-full p-1 hover:bg-neutral-200 cursor-pointer flex justify-between">
      
      <button onClick={()=>{
        dispatch(changeSelectedList({id:id??-1, name:name??''}))
      }}>
        <div className="flex items-center space-x-1">
          <h2 className="text-lg font-bold">{isDefault ? "Domyślna" : name}</h2>
        </div>
      </button>
      <div className="flex items-center space-x-1">
        <button
          className={`${
            isDefault ? "cursor-not-allowed opacity-40" : ""
          } p-2 rounded-lg hover:bg-neutral-300`}
          onClick={() => {
            if (isDefault) return;
            setShowEdit(true);
          }}
        >
          <span className="material-symbols-outlined text-xl">edit</span>
        </button>
        <button
          className={`${
            isDefault ? "cursor-not-allowed opacity-40" : ""
          } p-2 rounded-lg hover:bg-neutral-300`}
          onClick={() => {
            if (isDefault) return;
            setShowDelete(true);
          }}
        >
          <span className="material-symbols-outlined text-xl">delete</span>
        </button>
        <AnimatePresence mode="wait">
          {showDelete && (
            <Confirm
              handleClose={() => setShowDelete(false)}
              handleConfirm={() => {
                dispatch(deleteList(id??-1));
                setShowDelete(false);
              }}
              text="Czy napewno chcesz usunąć?"
            />
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence mode="wait">
        {showEdit&&<EditNameForm setShowEdit={setShowEdit} name={name??''} id={id??-1}/>}
      </AnimatePresence>
    </div>
  );
}

export default ListItem;
