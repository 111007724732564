import React, { useState } from "react";
import ButtonNeutral50 from "../../../../Common/Buttons/ButtonNeutral50";

function PressureCalculator() {
  const [silaPchajaca, setSilaPchajaca] = useState("");
  const [srednica, setSrednica] = useState("");
  const [result, setResult] = useState("");

  const calculatePressure = () => {
    // Ensure conversion of diameter from mm to meters for calculation, since pressure result is intended to be in bar
    // and force is given in N, using formula: Pressure = (4 * Force) / (pi * diameter^2)
    // Diameter is converted from mm to m for this calculation by dividing by 1000, and then result is in Pascals,
    // converting it to bar by dividing by 100000 (since 1 bar = 100000 Pascals).
    const cisnienie =
      (4 * parseFloat(silaPchajaca)) /
      (Math.PI *
        (parseFloat(srednica) / 1000) *
        (parseFloat(srednica) / 1000)) /
      100000;
    setResult(`Wynik: ${cisnienie.toFixed(4).toString()} bar`);
  };

  return (
    <div className="w-full flex flex-col items-start justify-center space-y-2">
      <h2
        style={{
          textAlign: "center",
          fontWeight: "600",
          fontSize: "25px",
          fontFamily: "system-ui, sans-serif",
        }}
      >
        Ciśnienie
      </h2>
      <div className="mainDivCalculator flex flex-wrap items-center">
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Siła pchająca (N)</span>
          <input
            type="number"
            value={silaPchajaca}
            onChange={(e) => setSilaPchajaca(e.target.value)}
            placeholder="Siła pchająca (N)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
        <div className="divTextWithInput mr-2 flex flex-col items-center justify-center">
          <span>Średnica tłoka(mm)</span>
          <input
            type="number"
            value={srednica}
            onChange={(e) => setSrednica(e.target.value)}
            placeholder="Średnica tłoka (mm)"
            style={{
              textAlign: "center",
              fontSize: "15px",
              padding: "10px",
              borderRadius: "10px",
              border: "3px solid #008391",
            }}
          />
        </div>
      </div>
      <div className="flex justify-center items-center space-x-4">
        <ButtonNeutral50
          text={"Zatwierdź"}
          size={"medium"}
          onClick={calculatePressure}
          icon={"check"}
        />
        <h2 className="text-2xl font-bold text-center whitespace-nowrap">
          {result}
        </h2>
      </div>
    </div>
  );
}

export default PressureCalculator;
