import React from "react";
import ButtonPrimaryToLighter from "../Buttons/ButtonPrimaryToLighter";
import { motion } from "framer-motion";
import { dropDown, dropIn, fadeIn, slideFromBottom } from "../../Animations/Animations";

function CookiesInformational() {
  const [hasAcceptedCookies, setHasAcceptedCookies] = React.useState(
    localStorage.getItem("cookiesAccepted")
  );

  if (hasAcceptedCookies) {
    return null;
  }

  return (
    <motion.div
      className="fixed bottom-4 mx-auto min-w-[60%] max-w-[96%] backdrop-blur-md z-50 flex items-center justify-center flex-col space-y-1 bg-neutral-100 bg-opacity-80 shadow-md drop-shadow-md p-2 sm:p-4"
      variants={slideFromBottom}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <div className="w-full flex justify-between items-center space-x-2">
        <div className="flex space-x-1 items-center">
          <h1 className="text-xl sm:text-3xl font-bold">Pliki Cookies</h1>
          <span className="text-xl sm:text-3xl material-symbols-outlined">
            cookie
          </span>
        </div>
        <motion.button
          className="group flex items-center px-2 py-1 sm:px-5 sm:py-2 font-semibold border-2 border-primary"
          onClick={() => {
            localStorage.setItem("cookiesAccepted", "true");
            setHasAcceptedCookies("true");
          }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          Akceptuje
        </motion.button>
      </div>
      <p className="text-xs sm:text-base text-justify">
        Ta strona korzysta z plików cookies w celu przechowywania informacji na
        temat logowania. Korzystając z tej strony wyrażasz zgodę na używanie
        plików cookies.
      </p>
      <div className="w-full flex flex-col-reverse items-center sm:flex-row justify-between text-xs sm:text-base">
        <p>© 2024 Press Bar Sp. z o. o. Wszelkie prawa zastrzeżone.</p>
        <a href="/polityka-prywatnosci" className="underline">
          Polityka prywatności
        </a>
      </div>
    </motion.div>
  );
}

export default CookiesInformational;
