import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getUnAcceptedUsers, getUserDetails, getUsers, selectAdminUserState, selectAdminUsersState, selectUser, selectUsers, updateUser } from '../../../../Store/Slices/admin/Users/Users';
import { AppDispatch } from '../../../../Store/store';
import { UserAdminDisplay } from '../../../../Models/User';
import { OrderModel, OrderType } from '../../../../Models/Order';
import { ShopAddress } from '../../../../Models/Address';
import { CategoryDisc, ProductDisc, ProductIndPrice } from '../../../../Models/IndividualPrices';

type Props = {}

function EditUser({}: Props) {

  const dispatch = useDispatch<AppDispatch>();
  const { id } = useParams();

  useEffect(() => {
    if(id){
        dispatch(getUserDetails({userId: id}));
    }
  }, [dispatch, id]);

  const user: {user:UserAdminDisplay, orders: OrderModel[], addresses: ShopAddress[],categoriesDiscounts:CategoryDisc[], 
    productsDiscounts: ProductDisc[], productsIndPrices: ProductIndPrice[]} = useSelector(selectUser);
  const { isLoading, failedLoading, hasLoaded, error } =
    useSelector(selectAdminUserState);

  return (
    <div>
      <p>Users</p>
      <p>{JSON.stringify(isLoading)}</p>
      <form onSubmit={(e)=>{
            e.preventDefault();
            const formData = new FormData(e.currentTarget);
            dispatch(updateUser({name: formData.get('name')?.toString()??'', email: formData.get('email')?.toString()??'', 
            phoneNumber: formData.get('phoneNumber')?.toString()??'', userId: id?.toString()??''}))
      }}>
        <p><input defaultValue={user.user.Name} name="name"/></p>
        <p><input defaultValue={user.user.Email} name="email"/></p>
        <p><input defaultValue={user.user.Phone_number} name="phoneNumber"/></p>
        <p><button type='submit'>Zatwierdź</button></p>
      </form>
      <p>Adresy: {user.addresses&&user.addresses.map(element=>{
        return (
        <p>
          {element.City}
        </p>)
      })}</p>
      <p>Zamówienia: {user.orders&&user.orders.map(element=>{
        return (
        <p>
          {element.Price}
        </p>)
      })}</p>
      <p>Znizki na kategorie (%): {user.categoriesDiscounts&&user.categoriesDiscounts.map(element=>{
        return (
        <p>
          {element.Name}-{element.Discount}%
        </p>)
      })}</p>
      <p>Znizki na produkty (%): {user.productsDiscounts&&user.productsDiscounts.map(element=>{
        return (
        <p>
          {element.Name}-{element.Discount}%
        </p>)
      })}</p>
      <p>Ceny ind. na produkty: {user.productsIndPrices&&user.productsIndPrices.map(element=>{
        return (
        <p>
          {element.Name}-{element.Price}
        </p>)
      })}</p>
        
    </div>
    
  )
}

export default EditUser