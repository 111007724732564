import React from "react";

function RegisterInformational() {
  return (
    <div className="w-full lg:w-1/2 lg:sticky lg:top-28">
      <h1 className="text-5xl lg:text-7xl font-black py-5 lg:py-10">
        Dlaczego powinieneś utworzyć konto?
      </h1>
      <div className="flex flex-col space-y-4 lg:space-y-8">
        <div className="flex items-center space-x-2 text-xl md:text-3xl font-bold">
          <span className="material-symbols-outlined text-4xl md:text-5xl text-primary">
            shopping_cart
          </span>
          <span>Zapisz swój koszyk</span>
        </div>
        <div className="flex items-center space-x-2 text-xl md:text-3xl font-bold">
          <span className="material-symbols-outlined text-4xl md:text-5xl text-red-600">
            favorite
          </span>
          <span>Twórz listy produktów</span>
        </div>
        <div className="flex items-center space-x-2 text-xl md:text-3xl font-bold">
          <span className="material-symbols-outlined text-4xl md:text-5xl text-primary">
            history
          </span>
          <span>Sprawdź swoją historię zakupów</span>
        </div>
      </div>
    </div>
  );
}

export default RegisterInformational;
