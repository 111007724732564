import React from "react";
import { ProductDisplay } from "../../Models/Product";
import { Link } from "react-router-dom";
import ProductInfo from "./ProductInfo/ProductInfo";
import ProductControls from "./ProductControls/ProductControls";
import PriceQuantity from "./PriceQuantity/PriceQuantity";
import { DebouncedFunc } from "lodash";

type Props = {
  product: ProductDisplay;
  productQuantity?: number;
  showAddToCart?: boolean;
  showRemoveFromCart?: boolean;
  showCartControls?: boolean;
  showRating?: boolean;
  showAddToList?: boolean;
  showMoreInfo?: boolean;
  showFullNameOnHover?: boolean;
  showPriceQuantity?: boolean;
  showShadow?: boolean;
  showRemoveFromList?: boolean;
  showFullName?: boolean;
  removeFunction?: () => void;
  pictureSize?: "small" | "big";
  flag?: "none" | "new" | "sale";
  updateQuantity?: ({
    newQuantity,
    product,
  }: {
    newQuantity: string;
    product: {
      Id: number;
    };
  }) => void;
};

function ProductList({
  product,
  productQuantity,
  showAddToCart = true,
  showRemoveFromCart = false,
  showCartControls = false,
  showRating = false,
  showAddToList = true,
  showMoreInfo = true,
  showFullNameOnHover = true,
  showPriceQuantity = false,
  showShadow = true,
  pictureSize = "big",
  showFullName = false,
  showRemoveFromList = false,
  removeFunction,
  flag = "none",
  updateQuantity,
}: Props) {
  return (
    <div
      className={`${
        showShadow ? "shadow-md hover:shadow-lg" : ""
      } shrink-0 transition-all flex space-x-1 sm:space-x-2 md:space-x-4 overflow-hidden rounded-lg w-full`}
    >
      <Link
        className={`${
          pictureSize === "small" ? "sm:w-20" : "sm:w-40"
        } h-auto w-12 shrink-0 block bg-white`}
        style={{
          backgroundImage: `url(${product.Image_url})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
        to={`/product/${product.Catalog_name?.replaceAll("/", "*1*")}`}
      ></Link>
      <div className="w-full flex flex-row justify-between items-center">
        <Link
          to={`/product/${product.Catalog_name?.replaceAll("/", "*1*")}`}
          className="w-full flex items-center justify-center"
        >
          <ProductInfo
            product={product}
            showMoreInfo={showMoreInfo}
            showFullNameOnHover={showFullNameOnHover}
            showFullName={showFullName}
          />
        </Link>
        <ProductControls
          product={product}
          productQuantity={productQuantity || 1}
          showCartControls={showCartControls}
          showAddToCart={showAddToCart}
          showRemoveFromCart={showRemoveFromCart}
          showRating={showRating}
          showAddToList={showAddToList}
          showRemoveFromList={showRemoveFromList}
          removeFunction={removeFunction}
          updateQuantity={updateQuantity}
        />
        {showPriceQuantity && productQuantity && (
          <PriceQuantity product={product} productQuantity={productQuantity} />
        )}
      </div>
    </div>
  );
}

export default ProductList;
