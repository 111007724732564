import React, { useEffect, useState } from "react";
import { Option } from "../../../../Models/Option";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../../Store/store";
import { useLocation } from "react-router";
import { ParameterChoice } from "../../../../Models/Parameter";
import { useSearchParams } from "react-router-dom";
import {
  addParameterChoice,
  removeParameterChoice,
  selectChoicesForParameter,
  selectParameterChoices,
} from "../../../../Store/Slices/Parameters/Parameters";

function CheckboxFilter({
  options,
  FiltersCounts,
}: {
  options: Option[];
  FiltersCounts?: { Choice_id: number; Count: number }[];
}) {
  const dispatch = useDispatch<AppDispatch>();
  const location = useLocation();

  const [, setSearchParams] = useSearchParams({
    filters: [],
  });

  const [displayItems, setDisplayItems] = useState(false);
  const parameterId = options[0].PARAMETERID;
  const filterChoicesForParameter = useSelector((state: any) =>
    selectChoicesForParameter(state, parameterId)
  )?.choicesIds;

  useEffect(() => {
    for (const element of options) {
      if (filterChoicesForParameter?.includes(element.ID)) {
        setDisplayItems(true);
        break;
      }
    }
  }, [filterChoicesForParameter]);

  let filterChoices: ParameterChoice[] = useSelector(selectParameterChoices);

  const handleCheckboxChange = (choiceId: number, isChecked: boolean) => {
    let updatedFilterChoices = [...filterChoices]; // Create a new array to avoid mutating state

    const filterChoiceIndex = updatedFilterChoices.findIndex(
      (filterChoice) => filterChoice.parameterId === parameterId
    );

    if (isChecked) {
      dispatch(addParameterChoice({ parameterId, choiceId }));

      if (filterChoiceIndex !== -1) {
        let arr = [];
        updatedFilterChoices[filterChoiceIndex].choicesIds.forEach(
          (element) => {
            arr.push(element);
          }
        );
        arr.push(choiceId);
        updatedFilterChoices[filterChoiceIndex] = {
          parameterId: updatedFilterChoices[filterChoiceIndex].parameterId,
          choicesIds: arr,
        };
      } else {
        updatedFilterChoices.push({
          parameterId,
          choicesIds: [choiceId],
        });
      }
    } else {
      dispatch(removeParameterChoice({ parameterId, choiceId }));

      if (filterChoiceIndex !== -1) {
        const index =
          updatedFilterChoices[filterChoiceIndex].choicesIds.indexOf(choiceId);
        if (index !== -1) {
          const arr = updatedFilterChoices[filterChoiceIndex].choicesIds.filter(
            (element) => element !== choiceId
          );
          updatedFilterChoices[filterChoiceIndex] = {
            parameterId: updatedFilterChoices[filterChoiceIndex].parameterId,
            choicesIds: arr,
          };
        }
      }
    }

    if (location.pathname.startsWith("/search")) {
      setSearchParams((prev) => {
        console.log("prev", prev);
        prev.set("filters", JSON.stringify(updatedFilterChoices));
        return prev;
      });
    }
  };



  return (
    <div className="px-4">
      {options == null && <div>Failed to load options.</div>}
      {options != null &&
        options.length !== 0 &&
        options.map((option, index) => {
          var filters: { Choice_id: number; Count: number }[] = [];
          if (FiltersCounts !== undefined) {
            filters = FiltersCounts?.filter((element) => {
              if (element.Choice_id == option.ID) {
                return true;
              }
              return false;
            });
          }
          return displayItems ||
            filterChoicesForParameter?.includes(option.ID) ||
            (filters.length !== 0 &&
              filters[0]?.Count !== 0 &&
              filters[0]?.Count !== undefined) ? (
            <div className="w-full cursor-pointer flex flex-row items-center" key={index}>
              <input
                type="checkbox"
                name={option.NAME ? option.NAME : "undefined"}
                id={option.ID.toString()}
                className="mr-2"
                checked={
                  filterChoicesForParameter?.includes(option.ID) || false
                }
                onChange={(e) => {
                  const isChecked = e.target.checked;
                  handleCheckboxChange(option.ID, isChecked);
                }}
              />
              <label className="cursor-pointer w-full" htmlFor={option.ID?.toString()}>
                {option.ID}
                {filters.length !== 0 ? "(" + filters[0]?.Count + ")" : ""}
              </label>
            </div>
          ) : (
            <span></span>
          );
        })}
    </div>
  );
}

export default CheckboxFilter;
