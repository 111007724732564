import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { confirmEmail, selectConfirmState } from "../../Store/Slices/User/User";
import { useParams } from "react-router-dom";
import { AppDispatch } from "../../Store/store";
import ButtonPrimaryToLighter from "../../Common/Buttons/ButtonPrimaryToLighter";
import { dropIn } from "../../Animations/Animations";
import LoadingAnimation from "../../Common/LoadingAnimation/LoadingAnimation";
import ErrorMessage from "../../Common/Messages/ErrorMessage/ErrorMessage";

function RegisterConfirm() {
  const confirmEmailState = useSelector(selectConfirmState);

  const dispatch = useDispatch<AppDispatch>();
  const { token } = useParams();

  useEffect(() => {
    if (token && !confirmEmailState.hasLoaded) {
      dispatch(confirmEmail(token));
    }
  }, []);

  return (
    <motion.div
      variants={dropIn}
      initial="hidden"
      animate="visible"
      exit="exit"
      className="w-full max-w-[1536px] px-2 sm:px-5 my-20 flex flex-col items-center justify-center space-y-6"
    >
      {confirmEmailState.isLoading && <div className="w-full h-[50vh]"></div>}
      {confirmEmailState.isLoading && (
        <LoadingAnimation type="global" color="primary" size="large" />
      )}
      {(confirmEmailState.error?.message?.endsWith("409") ||
        confirmEmailState.hasLoaded) && (
        <div className="w-auto max-w-[1024px] space-y-5">
          <h1 className="text-xl sm:text-5xl font-semibold flex items-center space-x-3">
            <span>Konto zweryfikowane</span>
            <span className="material-symbols-outlined text-xl sm:text-4xl">
              check_circle
            </span>
          </h1>
          <ButtonPrimaryToLighter
            text={"Przejdź do logowania"}
            size={"medium"}
            customClasses="w-full"
            icon={"login"}
            link={"/login"}
          />
        </div>
      )}
      {confirmEmailState.failedLoading &&
        !confirmEmailState.error?.message?.endsWith("409") && (
          <ErrorMessage
            error={confirmEmailState.error}
            message="Błąd podczas potwierdzenia konta."
          />
        )}
    </motion.div>
  );
}

export default RegisterConfirm;
