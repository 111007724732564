import React from "react";

function Legend() {
  const [showIndividual, setShowIndividual] = React.useState<boolean>(false);
  const [showProduct, setShowProduct] = React.useState<boolean>(false);
  const [showCategory, setShowCategory] = React.useState<boolean>(false);

  const [windowWidth, setWindowWidth] = React.useState<number>(
    window.innerWidth
  );

  React.useEffect(() => {
    window.addEventListener("resize", () => {
      setWindowWidth(window.innerWidth);
    });
  }, []);

  return (
    <div className="p-2 w-full relative flex flex-col lg:flex-row lg:items-center space-y-2 lg:space-y-0 lg:space-x-2">
      <div className="flex space-x-2 items-center">
        <div
          className="w-4 h-4 rounded-full bg-primary"
          onMouseEnter={() => {
            setShowIndividual(true);
          }}
          onMouseLeave={() => {
            setShowIndividual(false);
          }}
        >
          {windowWidth > 1024 && showIndividual && (
            <div className="absolute bottom-[120%] left-2 text-start rounded-lg shadow-md bg-neutral-100 bg-opacity-40 backdrop-blur-md p-2">
              Cena Indywidualna
            </div>
          )}
        </div>
        <p className="text-sm sm:text-base lg:hidden font-semibold">
          {" "}
          Cena Indywidualna
        </p>
      </div>
      <div className="flex space-x-2 items-center">
        <div
          className="w-4 h-4 rounded-full bg-green-600"
          onMouseEnter={() => {
            setShowProduct(true);
          }}
          onMouseLeave={() => {
            setShowProduct(false);
          }}
        >
          {windowWidth > 1024 && showProduct && (
            <div className="absolute bottom-[120%] left-2 text-start rounded-lg shadow-md bg-neutral-100 bg-opacity-40 backdrop-blur-md p-2">
              Przecena Produktu
            </div>
          )}
        </div>
        <p className="text-sm sm:text-base lg:hidden font-semibold">
          {" "}
          Przecena Produktu
        </p>
      </div>
      <div className="flex space-x-2 items-center">
        <div
          className="w-4 h-4 rounded-full bg-orange-600"
          onMouseEnter={() => {
            setShowCategory(true);
          }}
          onMouseLeave={() => {
            setShowCategory(false);
          }}
        >
          {windowWidth > 1024 && showCategory && (
            <div className="absolute bottom-[120%] left-2 text-start rounded-lg shadow-md bg-neutral-100 bg-opacity-40 backdrop-blur-md p-2">
              Przecena Kategorii
            </div>
          )}
        </div>
        <p className="text-sm sm:text-base lg:hidden font-semibold">
          Przecena Kategorii
        </p>
      </div>
    </div>
  );
}

export default Legend;
