import React, { useState } from 'react'
import { AnimatePresence, motion } from "framer-motion";
import { useDispatch, useSelector } from 'react-redux';
import { addIndividualDiscountToProduct, addToChosenProducts, deleteProductDiscount, getProductsByName, getUserDiscounts, getUsers, getUsersSearchDiscounts, selectChosenProducts, selectChosenUser, selectChosenUserSearchDiscounts, selectProducts, selectUserDiscounts, selectUsers, selectUsersSearchDiscounts, setChosenUser, setChosenUserSearchDiscounts } from '../../../../Store/Slices/admin/Products/IndDiscounts';
import { AppDispatch } from '../../../../Store/store';
import Confirm from '../../../../Common/Modals/Confirm/Confirm';

export default function ProductDiscount() {


  const products = useSelector(selectProducts);
  const chosenProducts = useSelector(selectChosenProducts);
  const foundUsers = useSelector(selectUsers);
  const foundUsersSearchDiscounts = useSelector(selectUsersSearchDiscounts);
  const chosenUser = useSelector(selectChosenUser);
  const chosenUserSearchDiscounts = useSelector(selectChosenUserSearchDiscounts);
  const chosenUserDiscounts = useSelector(selectUserDiscounts);



  const dispatch = useDispatch<AppDispatch>();
  
  const [discount, setDiscount] = useState(0);
  const [clientSearchDiscounts, setClientSearchDiscounts] = useState('');
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [chosenDiscountToDelete, setChosenDiscountToDelete] = useState(-1);
  const [chosenProductToDelete, setChosenProductToDelete] = useState(-1);
  const [client, setClient] = useState('');

  const [search, setSearch] = useState('');

  return (
    <div>
      <p>
        <input value={search} onChange={(e)=>{
          setSearch(e.target.value);
        }} placeholder='Nazwa lub numer katalogowy produktu'/>
        <button onClick={()=>{
          dispatch(getProductsByName({name: search}))
        }}>Szukaj</button>
      </p>
      <p>
        {products&&products.map(element=>{
          return(
            <p>
              {element.Name}
              <button onClick={()=>{
              dispatch(addToChosenProducts(element))
              }} style={{marginLeft: '30px'}}>dodaj</button>
            </p>
          )
        })}
      </p>
      <p>Wybrane:</p>
      <p>
        {chosenProducts&&chosenProducts.map(element=>{
          return(
            <p>
              {element.Name}
            </p>
          )
        })}
      </p>
      <p>Znizka w procentach</p>
      <p><input value={discount} onChange={(e)=>{
        try{
          if(parseInt(e.target.value)<100){
            setDiscount(parseInt(e.target.value));
          }
          else{
            setDiscount(0);
          }
        }
        catch(e){
          setDiscount(0);
        }
      }}/></p>
      <br/>
      <p>
        <input value={client} onChange={(e)=>{
          setClient(e.target.value);
        }} placeholder='nazwa lub NIP klienta'/>
        <button onClick={()=>{
          dispatch(getUsers({name: client}));
        }}>Szukaj</button>
      </p>
      <br/>
      <p>{foundUsers&&foundUsers.map((element)=>{
        return(
          <p><button style={{background: (element.Id===chosenUser?'blue':'red')}} onClick={()=>{
            dispatch(setChosenUser(element.Id))
          }}>
            {element.Name}
          </button></p>
        )
      })}</p>
      <br/><br/>
      <p>
        <button onClick={()=>{
          var productsIds:number[] = chosenProducts.map(element=>{
            return element.Id;
          });
          dispatch(addIndividualDiscountToProduct({discount: discount, userId: chosenUser, products: productsIds}))
        }}>
          Zatwierdź
        </button>
      </p>
      <br/><br/>
      <h2>Rabaty</h2>
      <p>
        <input value={clientSearchDiscounts} onChange={(e)=>{
          setClientSearchDiscounts(e.target.value);
          }} placeholder='nazwa lub NIP klienta'/>
        <button onClick={()=>{
          dispatch(getUsersSearchDiscounts({name: clientSearchDiscounts}));
        }}>Szukaj</button>
      </p>
      <p>{foundUsersSearchDiscounts&&foundUsersSearchDiscounts.map((element)=>{
        return(
          <p><button style={{background: (element.Id===chosenUserSearchDiscounts?'blue':'red')}} onClick={()=>{
            dispatch(setChosenUserSearchDiscounts(element.Id))
          }}>
            {element.Name}
          </button></p>
        )
      })}</p>
      {chosenUserSearchDiscounts!=-1&&<p><button onClick={()=>{
        dispatch(getUserDiscounts({userId: chosenUserSearchDiscounts}))
      }}>Zatwierdź i szukaj rabatów wybranego klienta</button></p>}
      <p>{chosenUserDiscounts&&chosenUserDiscounts.map((element)=>{
        return(
          <p>
            <span>
              {element.Name}-{element.Discount}%
            </span>
            <button style={{marginLeft: '30px'}} onClick={()=>{
              setChosenProductToDelete(element.Id);
              setChosenDiscountToDelete(element.Discount);
              setShowConfirmDelete(true);
            }}>
              Usuń
            </button>
          </p>
        )
      })}</p>
      <AnimatePresence mode="wait">
        {showConfirmDelete && (
          <Confirm
            handleClose={function (): void {
              setShowConfirmDelete(false);
            }}
            handleConfirm={function (): void {
              setShowConfirmDelete(false);
              dispatch(deleteProductDiscount({userId: chosenUserSearchDiscounts, id: chosenProductToDelete, discount: chosenDiscountToDelete}))
            }}
            text={"Czy na pewno chcesz usunać ten rabat?"}
          />
        )}
      </AnimatePresence>
    </div>
  )
}
