import React, { useEffect, useState } from "react";
import { ProductDisplay } from "../../../Models/Product";
import AddToListButton from "../../Modals/AddToList/AddToListButton";
import AddToCartButton from "../../Modals/AddToCart/AddToCartButton";
import StarRating from "../../StarRating/StarRating";
import RemoveFromCartButton from "../../Modals/AddToCart/RemoveFromCartButton";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "../../../Store/store";
import {
  addToCart,
  changeQuantityLocal,
} from "../../../Store/Slices/Cart/Cart";
import { selectIsLoggedIn } from "../../../Store/Slices/User/User";
import HeartBrokenOutlinedIcon from "@mui/icons-material/HeartBrokenOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";

type Props = {
  product: ProductDisplay;
  productQuantity: number;
  showCartControls?: boolean;
  showAddToCart?: boolean;
  showRemoveFromCart?: boolean;
  showRating?: boolean;
  showAddToList?: boolean;
  showRemoveFromList?: boolean;
  removeFunction?: () => void;
  updateQuantity?: ({
    newQuantity,
    product,
  }: {
    newQuantity: string;
    product: {
      Id: number;
    };
  }) => void;
};

function ProductControls({
  product,
  productQuantity,
  showCartControls,
  showAddToCart,
  showRemoveFromCart,
  showRating,
  showAddToList,
  showRemoveFromList,
  removeFunction,
  updateQuantity,
}: Props) {
  const [quantity, setQuantity] = useState(productQuantity.toString());
  const [oldQuantity, setOldQuantity] = useState(productQuantity.toString());
  const inList = false;

  const isLoggedIn = useSelector(selectIsLoggedIn);

  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    //if (productQuantity.toString() !== quantity) {
    if (updateQuantity && isLoggedIn) {
      updateQuantity({ newQuantity: quantity, product });
    }

    //}
  }, [quantity, updateQuantity]);

  return (
    <div
      className="flex items-center  p-2"
      onClick={(e) => e.stopPropagation()}
    >
      {showCartControls && (
        <div className="flex flex-col sm:flex-row items-center justify-center sm:space-x-1 space-y-1 sm:space-y-0">
          <button
            className="rounded-lg p-1 sm:p-2 cursor-pointer hover:bg-neutral-100"
            onClick={(e) => {
              e.stopPropagation();
              if (parseInt(quantity) > 1) {
                dispatch(
                  changeQuantityLocal({
                    productId: product.Id,
                    quantity: parseInt(quantity) - 1,
                  })
                );
                setQuantity((parseInt(quantity) - 1).toString());
                setOldQuantity((parseInt(quantity) - 1).toString());
              }
            }}
          >
            <span className="material-symbols-outlined text-sm sm:text-base lg:text-xl">
              remove
            </span>
          </button>
          <input
            type="text"
            className="text-center w-2 sm:w-6 md:w-10 bg-transparent"
            value={quantity}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => {
              e.stopPropagation();
              const value = parseInt(e.target.value);
              setQuantity(value.toString());
            }}
            onBlur={(e) => {
              e.stopPropagation();
              if (quantity.toString() === "") {
                setQuantity("1");
                dispatch(
                  changeQuantityLocal({
                    productId: product.Id,
                    quantity: 1,
                    oldQuantity: parseInt(oldQuantity),
                  })
                );
              } else {
                dispatch(
                  changeQuantityLocal({
                    productId: product.Id,
                    quantity: parseInt(quantity),
                    oldQuantity: parseInt(oldQuantity),
                  })
                );
              }
              setOldQuantity(quantity);
            }}
          />
          <button
            className="rounded-lg p-1 sm:p-2 cursor-pointer hover:bg-neutral-100"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                changeQuantityLocal({
                  productId: product.Id,
                  quantity: parseInt(quantity) + 1,
                  plus: true,
                })
              );
              setQuantity((parseInt(quantity) + 1).toString());
              setOldQuantity((parseInt(quantity) + 1).toString());
            }}
          >
            <span className="material-symbols-outlined text-sm sm:text-base lg:text-xl">
              add
            </span>
          </button>
        </div>
      )}
      {(showRating || showAddToCart || showAddToList) && (
        <div className="flex flex-col items-center justify-center space-y-2 sm:space-y-0 w-fit">
          <div className="flex items-center flex-row space-x-2 space-y-0">
            {false && showAddToList && (
              <AddToListButton
                children={
                  <div>
                    {inList ? (
                      <span className="text-red-500 text-2xl md:text-3xl material-symbols-outlined">
                        heart_check
                      </span>
                    ) : (
                      <span className="hover:text-red-500 text-2xl md:text-3xl material-symbols-outlined">
                        heart_plus
                      </span>
                    )}
                  </div>
                }
                inList={inList}
                product={product}
              />
            )}

            <div className="flex flex-col sm:flex-row items-center">
              {showAddToCart && (
                <AddToCartButton
                  addToCart={addToCart}
                  children={
                    <AddShoppingCartOutlinedIcon className="hover:text-green-500 text-2xl md:text-3xl material-symbols-outlined" />
                  }
                  products={[
                    {
                      product: product,
                      quantity: 1,
                    },
                  ]}
                />
              )}
              {showRemoveFromList && (
                <button onClick={removeFunction}>
                  <HeartBrokenOutlinedIcon className="hover:text-red-500 text-2xl md:text-3xl material-symbols-outlined" />
                </button>
              )}
              {showRemoveFromCart && (
                <RemoveFromCartButton
                  product={{
                    product: product,
                    quantity: productQuantity,
                  }}
                >
                  <span className="hover:text-green-500 text-2xl md:text-3xl material-symbols-outlined">
                    remove_shopping_cart
                  </span>
                </RemoveFromCartButton>
              )}
            </div>
          </div>
          {showRating && (
            <div className="hidden sm:block">
              <StarRating rating={product.Rating} />
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ProductControls;
