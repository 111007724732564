import React, { useEffect, useRef, useState } from "react";
import { ImagTemp } from "../../../../Models/Product";
import { motion } from "framer-motion";

type Props = {
  images: ImagTemp[];
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  inFullScreen?: boolean;
};

function ImageSlider({ images, setFullScreen, inFullScreen = false }: Props) {
  const [currentImage, setCurrentImage] = useState(0);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const nextImage = () => {
    const newIndex = (currentImage + 1) % images.length;
    scrollToIndex(newIndex);
    setCurrentImage(newIndex);
  };

  const previousImage = () => {
    const newIndex = (currentImage - 1 + images.length) % images.length;
    scrollToIndex(newIndex);
    setCurrentImage(newIndex);
  };

  const handleThumbnailClick = (index: number) => {
    scrollToIndex(index);
    setCurrentImage(index);
  };

  const scrollToIndex = (index: number) => {
    if (scrollContainerRef.current) {
      const imageWidth = scrollContainerRef.current.offsetWidth;
      scrollContainerRef.current.scrollTo({
        left: index * imageWidth,
        behavior: "smooth",
      });
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setFullScreen(false);
    } else if (event.key === "ArrowRight") {
      nextImage();
    } else if (event.key === "ArrowLeft") {
      previousImage();
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const scrollLeft = scrollContainerRef.current.scrollLeft;
        const imageWidth = scrollContainerRef.current.offsetWidth;
        const newIndex = Math.floor(scrollLeft / imageWidth);
        setCurrentImage(newIndex);
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [images]);

  return (
    <div
      className={`${
        inFullScreen ? "bg-transparent" : "bg-white"
      } w-full h-full flex items-center justify-center`}
    >
      <div
        className="h-full aspect-square overflow-x-scroll snap-x snap-mandatory flex no-scrollbar"
        ref={scrollContainerRef}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{ backgroundImage: `url(${image.Image_url})` }}
            className={`${
              inFullScreen ? "bg-transparent" : "bg-white"
            } shrink-0 w-full h-full snap-start bg-no-repeat bg-contain bg-center cursor-pointer`}
            onClick={() => setFullScreen(true)}
          ></div>
        ))}
      </div>

      <motion.button
        onClick={(e) => {
          e.stopPropagation();
          previousImage();
        }}
        className={`${
          inFullScreen ? "flex" : "group-hover:flex hidden"
        } rounded-full h-14 w-14 bg-neutral-100 shadow-md items-center justify-center absolute left-3`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <span className="material-symbols-outlined text-4xl">chevron_left</span>
      </motion.button>
      <motion.button
        onClick={(e) => {
          e.stopPropagation();
          nextImage();
        }}
        className={`${
          inFullScreen ? "flex" : "group-hover:flex hidden"
        } rounded-full h-14 w-14 bg-neutral-100 shadow-md items-center justify-center absolute right-3`}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        <span className="material-symbols-outlined text-4xl">
          chevron_right
        </span>
      </motion.button>
      <div className="flex absolute bottom-2 left-[50%] transform translate-x-[-50%] justify-center py-2">
        {images.map((image, index) => (
          <div
            key={index}
            onClick={() => handleThumbnailClick(index)}
            className={`transition-all hover:cursor-pointer hover:opacity-100 active:opacity-100 opacity-50 w-4 h-4 rounded-full mx-1 ${
              currentImage === index ? "bg-gray-950" : "bg-gray-400"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
}

export default ImageSlider;
