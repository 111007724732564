import React, { useState, useEffect, useRef } from "react";
import { TechnicalDataModel } from "../../../../Models/Product";
import Data from "./Data/Data";
import ShowDetailsButton from "../../../../Common/Buttons/ShowDetailsButton";

type Props = {
  technicalData: TechnicalDataModel[];
};

function TechnicalData({ technicalData }: Props) {
  const [showDetails, setShowDetails] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const tableContainerRef = useRef<HTMLTableElement>(null);

  useEffect(() => {
    const checkHeight = () => {
      if (tableContainerRef.current) {
        const height = tableContainerRef.current.getBoundingClientRect().height;
        setIsButtonVisible(height > 176);
      }
    };

    checkHeight();
    window.addEventListener("resize", checkHeight);

    return () => {
      window.removeEventListener("resize", checkHeight);
    };
  }, []);

  return (
    <div id="TechnicalData" className="w-full">
      <div className="flex items-center space-x-1">
        <h1 className="text-3xl font-bold text-neutral-950">Dane techniczne</h1>
        <span className="material-symbols-outlined text-4xl text-primary">
          engineering
        </span>
      </div>

      <div
        className={`${
          showDetails ? "max-h-fit" : "max-h-44"
        } relative w-full overflow-hidden`}
      >
        <table ref={tableContainerRef} className="w-full table-container">
          <tbody>
            {technicalData.map((object, index) => (
              <tr key={index} className="border-b border-neutral-300">
                <td className="p-2">
                  <Data object={object} />
                </td>
                <td className="p-2">
                  <span className="text-neutral-700 text-start text-base">
                    {Object.values(object)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {isButtonVisible && (
          <ShowDetailsButton
            showDetails={showDetails}
            setShowDetails={setShowDetails}
          />
        )}
      </div>
    </div>
  );
}

export default TechnicalData;
