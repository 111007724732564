import React from "react";

type Props = {
  placeholder: string;
  id: string;
};

function SearchBar({ placeholder, id }: Props) {
  return (
    <div className="w-full flex">
      <input
        type="search"
        id={id}
        className="w-full p-2 border-2 border-primary"
        placeholder={placeholder}
      />
    </div>
  );
}

export default SearchBar;
