import React from "react";
import { Link } from "react-router-dom";

type Props = {
  text: string;
  icon?: string | null;
  link?: string | null;
  onClick?: () => void;
  size: "small" | "medium" | "large";
  isSubmit?: boolean;
  customClasses?: string;
  color?: string;
};

function ButtonTransparentToColor({
  text,
  icon = null,
  link = null,
  onClick,
  size,
  isSubmit = false,
  customClasses = "",
  color = "neutral-950",
}: Props) {
  const colorDependentClasses =
    " text-" + color + " hover:bg-" + color + " border-" + color + " ";

  if (link === null) {
    return (
      <button
        className={
          `
      ${size === "small" && "text-lg p-1"}
      ${size === "medium" && "text-xl p-2"}
      ${size === "large" && "text-2xl p-2"}
      border-2  space-x-2 flex items-center justify-center bg-transparent hover:text-neutral-50 rounded-md font-bold transition-all ` +
          customClasses +
          colorDependentClasses
        }
        onClick={(e) => {
          e.stopPropagation();
          onClick && onClick();
        }}
        type={isSubmit ? "submit" : "button"}
      >
        <span>{text}</span>
        {icon != null && (
          <span
            className={`
        ${size === "small" && "text-lg"}
        ${size === "medium" && "text-xl"}
        ${size === "large" && "text-2xl"}
        material-symbols-outlined`}
          >
            {icon}
          </span>
        )}
      </button>
    );
  }

  return (
    <Link
      to={`/${link}`}
      className={
        `
      ${size === "small" && "text-lg p-1"}
      ${size === "medium" && "text-xl p-2"}
      ${size === "large" && "text-2xl p-2"}
      border-2 space-x-2 flex items-center justify-center bg-transparent hover:text-neutral-50 rounded-md font-bold transition-all ` +
        customClasses +
        colorDependentClasses
      }
    >
      <span>{text}</span>
      {icon != null && (
        <span
          className={`
        ${size === "small" && "text-lg"}
        ${size === "medium" && "text-xl"}
        ${size === "large" && "text-2xl"}
        material-symbols-outlined`}
        >
          {icon}
        </span>
      )}
    </Link>
  );
}

export default ButtonTransparentToColor;
