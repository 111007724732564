import React from "react";
import { Link } from "react-router-dom";

const options = [
  {
    name: "O nas",
    link: "/about",
    icon: "info",
  },
  {
    name: "Usługi",
    link: "/services",
    icon: "build",
  },
  {
    name: "Wiedza",
    link: "/calculators",
    icon: "calculate",
  },
  {
    name: "Do pobrania",
    link: "/downloads",
    icon: "download",
  },
  {
    name: "Kontakt",
    link: "/contact",
    icon: "contact_support",
  },
];

function Links() {
  return (
    <div className="w-full flex items-center justify-start sm:justify-center space-x-2 overflow-x-scroll">
      {options.map((option) => (
        <Link
          to={option.link}
          className="w-fit sm:w-full relative flex flex-col items-center justify-center cursor-pointer p-2 rounded-lg hover:bg-neutral-100"
        >
          <span className="material-symbols-outlined">{option.icon}</span>
          <span className="whitespace-nowrap">{option.name}</span>
        </Link>
      ))}
    </div>
  );
}

export default Links;
