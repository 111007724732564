import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addAddress,
  fetchAddresses,
  selectAddAddressState,
  selectAddresses,
  selectFetchAddressesState,
} from "../../../../../../Store/Slices/User/User";
import { AppDispatch } from "../../../../../../Store/store";
import LoadingAnimation from "../../../../../../Common/LoadingAnimation/LoadingAnimation";
import ErrorMessage from "../../../../../../Common/Messages/ErrorMessage/ErrorMessage";
import InfoMessage from "../../../../../../Common/Messages/InfoMessage/InfoMessage";
import AddressList from "./AddressInput/AddresList/AddressList";
import ButtonPrimaryToLighter from "../../../../../../Common/Buttons/ButtonPrimaryToLighter";
import AddressForm from "../../../../../../Common/Forms/AddressForm/AddressForm";
import AddressInput from "./AddressInput/AddressInput";
import Input from "../../../../../../Common/Input/Input";

function ChooseAddress() {
  const dispatch = useDispatch<AppDispatch>();

  const addresses = useSelector(selectAddresses);
  const state = useSelector(selectFetchAddressesState);
  const addAddressState = useSelector(selectAddAddressState);

  const [addressName, setAddressName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [zipCode, setZipCode] = useState('');
  const [country, setCountry] = useState('Polska');

  useEffect(() => {
    dispatch(fetchAddresses());
  }, [dispatch]);

  useEffect(()=>{
    setShowNewAddress(false);
    setAddressName('');
    setAddress('');
    setCity('');
    setZipCode('');
  }, [addAddressState])

  const [showNewAddress, setShowNewAddress] = React.useState<boolean>(false);

  return (
    <div className="w-full">
      <h1 className="text-xl font-semibold">Wybierz adres dostawy:</h1>
      <div className="relative w-full rounded-lg p-3 border-2 flex flex-col space-y-3 ">
        {state.isLoading && <LoadingAnimation color="primary" type="local" />}
        {state.failedLoading && (
          <ErrorMessage
            message={"Bład pobierania adresów"}
            error={state.error}
          />
        )}
        {state.hasLoaded && addresses.length === 0 && (
          <InfoMessage
            message={"Brak zapisanych adresów. Dodaj nowy adres"}
            icon={"info"}
          />
        )}
        {state.hasLoaded &&
          addresses.length > 0 &&
          addresses.map((address) => (
            <AddressInput address={address} key={address.Id} />
          ))}
        {showNewAddress && <div className="flex flex-col lg:flex-row lg:space-y-0 space-y-3 lg:space-x-3 items-center">
        <Input
          name="Nazwa"
          label="Address_name"
          type={"text"}
          size="sm"
          value={addressName}
          onChange={(e)=>{
            setAddressName(e.target.value);
          }}
          placeholder="Dom"
        />
        <Input
          name="Ulica i numer domu"
          label="Address"
          type={"text"}
          size="sm"
          value={address}
          onChange={(e)=>{
            setAddress(e.target.value);
          }}
          placeholder="ul. Przykładowa 1"
        />
        <Input
          name="Miasto"
          label="City"
          type={"text"}
          size="sm"
          value={city}
          onChange={(e)=>{
            setCity(e.target.value);
          }}
          placeholder="Warszawa"
        />
        <Input
          name="Kod pocztowy"
          label="Zip_code"
          type={"text"}
          size="sm"
          value={zipCode}
          onChange={(e)=>{
            setZipCode(e.target.value);
          }}
          placeholder="00-000"
        />
        <Input
          name="Kraj"
          label="Country"
          type={"text"}
          size="sm"
          value={country}
          onChange={(e)=>{
            setCountry(e.target.value);
          }}
          placeholder="Polska"
        />
      </div>}

        {!showNewAddress ? (
          <ButtonPrimaryToLighter
            text={"Dodaj inny adres"}
            size={"medium"}
            icon={"add"}
            onClick={() => {
              setShowNewAddress(true);
            }}
          />
        ) : (
          <div className="w-full flex space-x-2">
            <ButtonPrimaryToLighter
              text={"Anuluj"}
              size={"medium"}
              icon={"cancel"}
              customClasses="w-full"
              onClick={() => {
                setShowNewAddress(false);
              }}
            />
            <ButtonPrimaryToLighter
              text={"Zapisz"}
              size={"medium"}
              icon={"check"}
              customClasses="w-full"
              isSubmit={true}
              onClick={()=>{
                dispatch(addAddress({
                  Address_name: addressName,
                  Country: country,
                  City: city,
                  Zip_code: zipCode,
                  Address: address
                }));
              }}
              form="address-form"
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default ChooseAddress;
