import React from "react";
import { ProductDisplay } from "../../../../Models/Product";
import { useSelector } from "react-redux";
import { selectIsLoggedIn } from "../../../../Store/Slices/User/User";

type Props = {
  product: ProductDisplay;
};

function PriceInfo({ product }: Props) {
  const isLoggedIn = useSelector(selectIsLoggedIn);

  return (
    <div className="w-full flex items-stretch flex-row-reverse justify-between xl:flex-col xl:justify-normal">
      <div className="flex flex-col justify-between xl:w-full w-fit items-end">
        <div className="flex items-center space-x-2 xl:justify-between xl:w-full">
          {/* <span className="material-symbols-outlined">sell</span> */}
          <span className="font-semibold whitespace-nowrap text-sm sm:text-xl xl:text-2xl">netto:</span>
          <h1 className="block whitespace-nowrap flex flex-col text-base sm:text-xl xl:text-2xl font-bold text-neutral-950">
            <span
              className={`${
                ((product.Price_Discount !== null &&
                  product.Price_Discount !== undefined) ||
                  (product.Price_Individual !== null &&
                    product.Price_Individual !== undefined) ||
                  (product.Category_Discount !== null &&
                    product.Category_Discount !== undefined)) &&
                isLoggedIn
                  ? "line-through text-neutral-500"
                  : ""
              }`}
            >
              {product.Price.toFixed(2) + " "} PLN
            </span>
            {isLoggedIn &&
              product.Price_Individual !== null &&
              product.Price_Individual !== undefined && (
                <span className="underline decoration-primary decoration-2">
                  {product.Price_Individual?.toFixed(2)} PLN
                </span>
              )}
            {isLoggedIn &&
              (product.Price_Individual === null ||
                product.Price_Individual === undefined) &&
              product.Price_Discount !== null &&
              product.Price_Discount !== undefined && (
                <span className="underline decoration-green-600 decoration-2">
                  {(product.Price * (1 - product.Price_Discount / 100)).toFixed(
                    2
                  )}{" "}
                  PLN
                </span>
              )}
            {isLoggedIn &&
              (product.Price_Individual === null ||
                product.Price_Individual === undefined) &&
              (product.Price_Discount === null ||
                product.Price_Discount === undefined) &&
              product.Category_Discount !== null &&
              product.Category_Discount !== undefined && (
                <span className="underline decoration-orange-600 decoration-2">
                  {(
                    product.Price *
                    (1 - product.Category_Discount / 100)
                  ).toFixed(2)}{" "}
                  PLN
                </span>
              )}
          </h1>
        </div>
        <div className="flex items-end space-x-2 xl:justify-between xl:w-full">
          <span className="whitespace-nowrap text-sm sm:text-xl xl:text-2xl">brutto:</span>
          <h1 className="block whitespace-nowrap text-sm sm:text-base xl:text-xl text-neutral-950">
            {isLoggedIn &&
              product.Price_Individual !== null &&
              product.Price_Individual !== undefined && (
                <span className="underline decoration-primary decoration-2">
                  {(product.Price_Individual * 1.23)?.toFixed(2)} PLN
                </span>
              )}
            {isLoggedIn &&
              (product.Price_Individual === null ||
                product.Price_Individual === undefined) &&
              product.Price_Discount !== null &&
              product.Price_Discount !== undefined && (
                <span className="underline decoration-green-600 decoration-2">
                  {(
                    product.Price *
                    (1 - product.Price_Discount / 100) *
                    1.23
                  ).toFixed(2)}{" "}
                  PLN
                </span>
              )}
            {isLoggedIn &&
              (product.Price_Individual === null ||
                product.Price_Individual === undefined) &&
              (product.Price_Discount === null ||
                product.Price_Discount === undefined) &&
              product.Category_Discount !== null &&
              product.Category_Discount !== undefined && (
                <span className="underline decoration-orange-600 decoration-2">
                  {(
                    product.Price *
                    (1 - product.Category_Discount / 100) *
                    1.23
                  ).toFixed(2)}{" "}
                  PLN
                </span>
              )}
            {(!isLoggedIn ||
              ((product.Category_Discount == null ||
                product.Category_Discount === undefined) &&
                (product.Price_Discount == null ||
                  product.Price_Discount === undefined) &&
                (product.Price_Individual == null ||
                  product.Price_Individual === undefined))) && (
              <span className="">{(product.Price * 1.23).toFixed(2)} PLN</span>
            )}
          </h1>
        </div>
      </div>
      <div className="flex flex-col justify-end items-start w-full">
        <div className="flex items-center justify-end xl:w-full">
          {/* <span className="material-symbols-outlined">apartment</span> */}
          <h1 className="block whitespace-nowrap text-xs sm:text-sm xl:text-2xl font-bold text-neutral-700">
            {product.Producer}
          </h1>
        </div>
        <div className="flex items-center justify-end xl:w-full">
          {/* <span className="material-symbols-outlined">barcode</span> */}
          <h1 className="block whitespace-nowrap text-xs sm:text-sm xl:text-2xl font-bold text-neutral-600">
            {product.Catalog_name}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default PriceInfo;
