import React from "react";
import "./PromoCode.css";

type Props = {
  show: boolean;
};

function PromoCode({ show }: Props) {
  if (!show) return null;

  return (
    <div className="w-full bg-orange-600 promo-code-container">
      <span className="text-neutral-50 text-base font-bold news-station-text w-full">
        KOD PROMOCYJNY: SIŁOWNIK10
      </span>
    </div>
  );
}

export default PromoCode;
